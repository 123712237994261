.desktop-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.desktop-view-block {
  display: block;
  width: 100%;
}

.mobile-view,
.mobile-view-block {
  display: none;
}

.visibility-mobile-view {
  visibility: hidden;

  &.activada {
    visibility: visible;
  }
}

/* 1200px */
/*------------------------------------------------*/
@media (min-width: 1441px) {

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1400px;
  }

  .container_small {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1180px;
  }

}

/* 1400px */
/*------------------------------------------------*/
@media (max-width: 1440px) {

  //top
  header#masthead .navbar-brand > a {
    padding: 15px;
  }

  header#masthead .idioma_area .language_switcher, header#masthead .idioma_area ul {
    right: 17px;
  }

  .home.page-template header#masthead #main-nav {
    margin-right: 70px;
  }

  header#masthead .header_phone {
    right: 17px;
    top: 8px;
  }

  header#masthead .idioma_area {
    right: 0;
  }

  header#masthead .navbar-brand {
    margin: 0;
    padding: 0;
  }

  header#masthead #main-nav {
    margin-right: 78px;
  }

  //home
  .collage_area .content_general .ft_images {
    max-height: 500px;
    background-size: cover;
  }

  .container {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 1400px;
  }

  .container_small {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1370px;
  }

  .quienes_somos_area.collage_area .content_general .ft_images {
    max-height: 420px;
    background-size: cover;
  }

  .collage_area .content_general .collage_text {
    padding: 40px !important;
  }

  #quienes_somos_area.collage_area .content_general .collage_text {
    padding: 40px 50px 40px 40px !important;
  }

  #congresos_box_area.collage_area .content_general .collage_text {
    padding: 40px 70px 40px 40px !important;
  }

  #eventos_box_area.collage_area .content_general .collage_text,
  #viajes_box_area.collage_area .content_general .collage_text {
    padding: 40px 40px 40px 60px !important;
  }

}

/* 1200px */
/*------------------------------------------------*/
@media (max-width: 1399px) {

  .container_small {
    width: 100%;
    max-width: 1170px;
  }

  .form_contact_7 {
    padding: 0 0 0 20px;
  }

  //blog_single_area
  .blog_single_area {

    .single_left .blog_area_text {
      padding: 20px 30px 5px !important;
    }

    .the_large_descripcion {

      padding-left: 30px;

    }

  }

  .comment_area_box {
    padding: 20px 30px 20px 30px;
  }

  /* eventos - congresos - viajes */
  .congreso_page_box .tabulador .nav-tabs-title {
    padding-right: 1px;
    padding-left: 30px;
  }

  .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
    padding: 0 0 0 30px;
  }

  //cong -viaj - evnt
  .congreso_page_box .tabulador .nav-tabs-title {
    font-size: 18px;
    line-height: 36px;
  }

}

/* 1200px */
/*------------------------------------------------*/
@media (max-width: 1280px) {

  .collage_area .content_general .ft_images {
    max-height: 525px;
  }

  .collage_area#congresos_box_area .ft_images_expanded {
    max-height: 1070px;
  }

}

/* 1200px */
/*------------------------------------------------*/
@media (max-width: 1199px) {

  .container {
    width: 100%;
    max-width: 1200px;
  }

  .container_small {
    width: 100%;
    max-width: 994px;
  }

  //navbar-toggler
  .buttom_home {
    display: block;
  }

  header#masthead {

    .navbar-toggler {
      background: url(../img/nav/nav_mobile_hover.png) no-repeat;
      width: 42px;
      height: 30px;
      display: inline-block;
      padding: 0;
      margin: 36px 5px 0;
      text-indent: -9999px;
      vertical-align: top;
      overflow: hidden;
      border: 0 none;
      outline: none;
      position: absolute;
      z-index: 987;
      top: 5px;
      right: 60px;

      &:hover {
        background: url(../img/nav/nav_mobile_active.png) no-repeat;
      }
    }

    #main-nav {
      background: rgba(255, 255, 255, .9);
      padding: 15px;
      position: absolute;
      top: 70px;
      right: 0;
      width: 100%;
      z-index: 98754;
      text-align: right;
      max-width: 230px;
      border-radius: 5px;
    }

    .navbar-nav > li > a {
      color: #999999 !important;
    }

  }

  .page-template.page-template-page-eventos header#masthead .navbar-toggler,
  .page-template.page-template-page-congresos header#masthead .navbar-toggler,
  .page-template.page-template-page-viajess header#masthead .navbar-toggler,
  .home.page-template header#masthead .navbar-toggler {
    background: url(../img/nav/nav_mobile.png) no-repeat;

    &:hover {
      background: url(../img/nav/nav_mobile_active.png) no-repeat;
    }
  }

  .page-template.page-template-page-eventos header#masthead.sticky .navbar-toggler,
  .page-template.page-template-page-congresos header#masthead.sticky .navbar-toggler,
  .page-template.page-template-page-viajess header#masthead.sticky .navbar-toggler,
  .home.page-template header#masthead.sticky .navbar-toggler {
    background: url(../img/nav/nav_mobile_hover.png) no-repeat;

    &:hover {
      background: url(../img/nav/nav_mobile_active.png) no-repeat;
    }
  }

  //header
  header#masthead .header_phone .footer_phone a {
    font-size: 15px;

    &:hover {
      color: #ffdb25 !important;
    }
  }

  #menu-nav-primary .menu-item-316,
  #menu-menu-principal .menu-item-45 {
    display: none;
  }

  header#masthead .idioma_area {
    top: 47px;
  }

  //home
  .collage_area#viajes_box_area .content_general h2 a,
  .collage_area#congresos_box_area .content_general h2 a,
  .collage_area#eventos_box_area .content_general h2 a,
  .collage_area#quienes_somos_area .content_general h2 a {
    font-size: 28px;
    line-height: 32px;
  }

  .collage_area#congresos_box_area .content_general .the-paragraph p,
  .collage_area#viajes_box_area .content_general .the-paragraph p,
  .collage_area#eventos_box_area .content_general .the-paragraph p,
  .collage_area#quienes_somos_area .content_general .the-paragraph p {
    font-size: 15px;
    line-height: 18px;
  }

  //slider
  .slider_principal .carousel-control-prev {
    left: 30px;
  }

  .slider_principal .carousel-control-next {
    right: 30px;
  }

  //lateral_barra_mapa
  .lateral_barra_mapa .lateral_barra_mapa_btn {
    padding: 20px 20px 0 0;
    text-align: right;
  }

  //prefoote
  .prefooter_item_area .jumbotron h2 {
    font-size: 36px;
    line-height: 46px;
  }

  //footer
  footer#colophon {

    .container_small {
      padding: 0 15px !important;
    }

  }

  //cong -viaj - evnt
  .congreso_page_box .tabulador .nav-tabs-title {
    font-size: 15px;
    line-height: 36px;
  }

  //data-phone
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-phone {
    padding: 0 30px 30px 30px;
  }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc {
    padding: 30px 30px 0 30px;
  }

  //acerca de
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h5 strong {
    font-size: 60px;
  }

  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h6 strong {
    font-size: 50px;
  }

  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h3 strong {
    font-size: 50px;
  }

  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h1 strong {
    font-size: 50px;
  }

  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h2 strong {
    font-size: 80px;
  }

  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h4 strong {
    font-size: 100px;
  }

}

/* 1023px */
/*------------------------------------------------*/
@media (max-width: 1023px) {

  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1024px;
  }

  .container_small {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 994px;
  }

  //acerca de
  .acerca_de_page_box {
    .brands_box {
      .brands_box_items {
        width: 33%;
      }
    }
  }

  //collage_area
  .collage_area .content_general .collage_text,
  .collage_area#congresos_box_area .content_general .collage_text,
  .collage_area#eventos_box_area .content_general .collage_text,
  .collage_area#viajes_box_area .content_general .collage_text {
    max-width: 100%;
  }

  .collage_area#quienes_somos_area .content_general h2 a {
    font-size: 24px;
    line-height: 28px;
  }

  .collage_area .content_general .collage_text {
    padding: 30px !important;
  }

  #congresos_box_area.collage_area .content_general .collage_text {
    padding: 30px !important;
  }

  #eventos_box_area.collage_area .content_general .collage_text,
  #viajes_box_area.collage_area .content_general .collage_text {
    padding: 30px !important;
  }

  #quienes_somos_area.collage_area .content_general .collage_text {
    padding: 30px !important;
  }

  //congreso
  .collage_area .content_general .ft_images.ft_congresos_imagen_derecha {
    max-height: 370px;
    background-size: cover;
  }

  //footer
  footer {
    .logo_footer_zone {
      text-align: center !important;
    }

    .social_footer_zone {
      text-align: center !important;
    }

    .site_info {
      padding-bottom: 30px;
      text-align: center;
      width: 100%;
    }

    .footer_social_red {
      margin: 0 0 60px 0;
      width: 100%;
      display: block;
      background: none;
      padding: 0;
    }

  }

  //general
  .mobile-view {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mobile-view-block {
    display: block;
    width: 100%;
  }

  .desktop-view,
  .desktop-view-block {
    display: none;
  }

  //slider principal
  .slider_principal .the_slide_content h1 {
    font-size: 26px;
    line-height: 30px;
  }

  .slider_principal .the_slide_content h2 p {
    font-size: 14px;
    line-height: 18px;
  }

  .slider_principal .carousel-control-prev,
  .slider_principal .carousel-control-next,
  .slider_principal .slick-arrow {
    display: none !important;
  }

  //sliders
  .collage_area .content_general .collage_text,
  .collage_area#eventos_box_area .content_general .collage_text,
  .collage_area#congresos_box_area .content_general .collage_text,
  .collage_area#viajes_box_area .content_general .collage_text {
    padding: 40px 40px 80px 40px;
  }

  .collage_area#eventos_box_area .content_general h2,
  .collage_area#congresos_box_area .content_general h2,
  .collage_area#viajes_box_area .content_general h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .collage_area#eventos_box_area .content_general h2 a,
  .collage_area#congresos_box_area .content_general h2 a,
  .collage_area#viajes_box_area .content_general h2 a {
    font-size: 28px;
    line-height: 32px;
  }

  //testimonials_home_slider
  .testimonials_home_slider {

    .row {
      padding: 0;
      margin: 0;
    }

    .text-left {
      text-align: center !important;
    }

    .text-right {
      text-align: center !important;
    }

  }

  .testimonials_home {
    padding: 26px 0 60px;

    .ths_item_photo {
      margin-bottom: 15px;
    }

    .testimonials_home_slider {

      .slick-arrow {
        display: none !important;
      }

      .slick-dots {
        display: block !important;
      }

    }

  }

  //blog
  .blog_area_box h1 a {
    font-size: 24px;
    line-height: 30px;
    padding: 15px 0 5px;
  }

  .blog_area_box .the_descripcion {
    font-size: 14px;
    line-height: 20px;
  }

  //lateral_barra_mapa
  .lateral_barra_mapa .lateral_barra_mapa_btn {
    padding: 20px 0 0 0;
    text-align: center;
  }

  //contacto
  .form_contact_7 {
    padding: 0 30px;
  }

  .box_contact_area .single_left .col-x-2 {
    max-width: 100%;
    margin: 0;
    padding: 0 !important;
  }

  .box_contact_area .single_left .col-x-1 {
    max-width: 100%;
    margin: 0;
  }

  .box_contact_area .single_left .wpcf7-form .dib {
    max-width: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  .box_contact_area .single_left .wpcf7-form .dib.both_btn.text-right {
    text-align: center;
  }

  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
    background-position: 0 2px;
  }

  //nav
  .home.page-template header#masthead #main-nav {
    background: #000 !important;
    margin: 0;
    border-radius: 0;
    border: 0;
    position: fixed;
    top: 0px;
    right: 0;
    z-index: 9867;
    padding-top: 150px;
    height: 100%;
  }

  //footer
  .prefooter_box_area {
    padding: 0;
    margin: 0 auto;
  }

  //congreso
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item, .result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
    margin: 2px 4px 2px 0;
  }

  .box_contact_area .single_left .col-x-1 {
    max-width: 100%;
    margin: 0;
    padding-right: 0 !important;
  }

  .congreso_page_box .tabulador .nav-tabs-title {
    font-size: 15px;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .congreso_page_box .tabulador .nav.nav-tabs .nav-item a {
    font-size: 14px;
  }

}

/* 990px */
/*------------------------------------------------*/
@media (max-width: 990px) {
  .congreso_page_box .tabulador .nav.nav-tabs .nav-item {
    max-width: 100%;
  }

  .congreso_page_box.viaje_page_box .escapate_box {
    overflow: hidden;
    max-height: 400px;
    min-height: 400px;
  }

  .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item {
    border-left: 0;
  }

  .congreso_page_box .tabulador .nav.nav-tabs .nav-item {
    margin: 0;
    border: 0;
    border-top: 1px solid #2b2e2f;
  }

  .congreso_page_box .tabulador .nav.nav-tabs .nav-item.first_item {
    border-left: 0;
    margin-right: 0;
  }

  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item {
    background: #2b2e2f;
    border-top: 1px solid #ffc600;
  }

}

/* 767px */
/*------------------------------------------------*/
@media (max-width: 767px) {

  //alternative_data_hover
  .congreso_page_box .tabulador .solutions_list_item_dinamic h6 {
    display: none !important;
  }

  .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover {
    display: block !important;
    cursor: default;
  }

}

/* 760px */
/*------------------------------------------------*/
@media (max-width: 760px) {

  //congresos
  .search_form_by_especiality_content .col-md-6.text-right,
  .search_form_by_especiality_content #scrolToThis {
    text-align: center !important;
  }

  .sub-container.sub-container-mobile {
    padding: 0;
  }

  .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
    padding: 0 30px;
  }

  //acerca de
  .acerca_de_page_box {
    .brands_box {
      .brands_box_items {
        width: 50%;
      }
    }
  }

}

/* 700px */
/*------------------------------------------------*/
@media (max-width: 700px) {

  //the_etiquetas_area1
  .the_etiquetas_area1 {
    ul {
      display: none !important;
    }
  }

  .the_etiquetas_area2 {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    border-top: 1px solid #484848;

    ul {
      margin: 0 !important;
    }
  }

  .the_etiquetas_area ul {
    display: inline-block;
    vertical-align: top;
    width: auto;
    text-align: center;
    margin: 5px;
    padding: 0;
  }

}

/* 540px */
/*------------------------------------------------*/
@media (max-width: 576px) {

  //head
  header#masthead .idioma_area {
    top: 12px;
  }

  header#masthead .navbar-toggler {
    right: 10px;
  }

  .buttom_home {
    right: 60px;
  }

  header#masthead .header_phone .footer_phone {
    display: none;
  }

  header#masthead .idioma_area ul li {
    display: inline-block !important;
    vertical-align: top;
    border-radius: 100%;
    margin: -4px -2px 0 0;
    border: 4px solid transparent;
  }

  .btn_llamar {
    right: 66px !important;
  }

  header#masthead .idioma_area .language_switcher {
    display: none;
  }

  header#masthead .idioma_area ul li.current-lang {
    border: 4px solid #ffdb25;
  }

  //btn_llamar
  .btn_llamar {
    color: rgba(255, 255, 255, .4);
    background: url(../img/nav/ico_phone_mobile_hover.png) no-repeat;
    padding: 0;
    line-height: 24px;
    margin: 0;
    position: absolute;
    top: 3px;
    right: 47px;
    z-index: 9;
    width: 34px;
    display: block;
    height: 20px;
    text-indent: -99999px;
    overflow: hidden;
    text-align: left;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .home.page-template {

    .btn_llamar {
      background: url(../img/nav/ico_phone_mobile.png) no-repeat;
    }

    .sticky .btn_llamar {
      background: url(../img/nav/ico_phone_mobile_hover.png) no-repeat;
    }

  }

  .sticky .btn_llamar {
    background: url(../img/nav/ico_phone_mobile_hover.png) no-repeat;
  }

  #modal_call {

    .modal-dialog .modal-body {
      background: #f5f6f1;
    }

    .modal-dialog .modal-content {
      max-width: 280px;
      padding: 0;
      margin: 0 auto;
      background: #f5f6f1;
      border-radius: 15px;

      .modal-header .close {
        position: absolute;
        z-index: 987;
        right: 20px;
        top: 20px;
      }

      .modal-title {
        padding: 20px 0;
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        display: block;
        width: 100%;
        text-align: center;
        font-weight: bold;
      }

      .modal-header {
        border-bottom: 1px solid #cfcfcf;
      }

      .modal-footer {
        background: none;
        border: 0 none;
        padding: 0;
        margin: 0;

        button {
          background: none;
          border: 0 none;
          padding: 10px;
          margin: 0;
          max-width: 50%;
          width: 100%;
          text-align: center;
          text-decoration: none;
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          text-transform: none;
          color: #007bff;
          border-right: 1px solid #cfcfcf;

          &:hover {
            text-decoration: none;
            color: #0056b3;
          }
        }

        a {
          background: none;
          border: 0 none;
          padding: 10px;
          margin: 0;
          max-width: 50%;
          width: 100%;
          text-align: center;
          text-decoration: none;
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          text-transform: none;
          color: #007bff;

          &:hover {
            text-decoration: none;
            color: #0056b3;
          }
        }

      }

    }

  }

  //modal-content
  body .modal .modal-dialog .modal-content {
    max-width: 450px;
    padding: 0 15px;
    margin: 0 auto;
  }

  //footer
  .prefooter_box_area {
    max-width: 400px;
    margin: 0 auto;
  }

  //box_contact_area
  .box_contact_area .single_left .wpcf7-form .wpcf7-submit {
    margin: 35px auto 0;
    display: block;
  }

}

/* 517px */
/*------------------------------------------------*/
@media (max-width: 517px) {

  .box_services_area_extra .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip {
    top: -44px;
  }

  .box_services_area_extra .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip::after {
    margin-top: 41px;
  }

}

/* 490px */
/*------------------------------------------------*/
@media (max-width: 490px) {

  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip {
    top: -44px;
  }

  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip::after {
    margin-top: 41px;
  }

}

/* 479px */
/*------------------------------------------------*/
@media (max-width: 479px) {

  //blog
  .blog_area_box h1 a {
    font-size: 22px;
    line-height: 26px;
  }

  .comment_area_box .comments-area .comment-form .comment-form-author, .comment_area_box .comments-area .comment-form .comment-form-email {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0;
  }

  .comment_area_box .comments-area .comment-form .comment-form-author {
    padding-right: 0;
  }

  /* eventos - congresos - viajes */
  .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
    font-size: 28px;
    line-height: 30px;

    p {
      font-size: 18px;
      line-height: 30px;

      strong {
        font-size: 28px;
        line-height: 30px;
      }

    }

  }

  //acerca de
  .acerca_de_page_box {
    .brands_box {
      .brands_box_items {
        width: 100%;
      }
    }
  }

}

/* 375px */
/*------------------------------------------------*/
@media (max-width: 375px) {

  //footer
  .prefooter_box_area {
    max-width: 400px;
    margin: 0 auto;
  }

  .prefooter_item_area .jumbotron h2 {
    font-size: 36px;
    line-height: 42px;
  }

  .blog_single_area .blog_area_box .blog_area_text .the_date {
    display: block;
    overflow: hidden;
    width: 100%;
    margin: 17px 0 10px 0;
  }

  .blog_single_area .pagination-right {
    padding-right: 0;
  }

  .box_contact_area .single_left .wpcf7-form span.wpcf7-not-valid-tip {
    font-size: 10px;
  }

}

/* 360px */
/*------------------------------------------------*/
@media (max-width: 360px) {

  .the_etiquetas_area ul li {
    padding: 0 0 0 5px;
    margin: 0;
  }

  .the_etiquetas_area ul li a {
    padding: 7px 3px;
  }

}

/* IE11 */
/*------------------------------------------------------------------------------------------------------------------*/

_:-ms-fullscreen, :root body .desktop-view-block,
_:-ms-fullscreen, :root body .desktop-view {
  display: block;
}

_:-ms-fullscreen, :root body .collage_area#congresos_box_area .ft_images_expanded {
  height: auto;
}

_:-ms-fullscreen, :root body .especialidades_box .listado_especialidades ul li a .banner {
  padding-top: 85px;
}

@media (max-width: 1023px) {
  _:-ms-fullscreen, :root body .mobile-view,
  _:-ms-fullscreen, :root body .mobile-view-block {
    display: block;
  }
}