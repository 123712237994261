/* General */
/*--------------------------------------------------------------------*/
body {
  background: #f4f7f9;
  font-family: 'Montserrat', sans-serif; }

body.home.page-template {
  background: #ffffff; }

h1 {
  color: #999999;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px; }

p {
  font-size: 16px;
  color: #2b2e2f;
  line-height: 24px;
  font-weight: 400; }

#content.site-content {
  padding-bottom: 0;
  padding-top: 0; }

.padding-50 {
  padding-bottom: 50px !important; }

.padding-20 {
  padding: 20px; }

.padding-30 {
  padding: 30px !important; }

.padding-130 {
  padding: 0 130px !important; }

.bg-gris {
  background-color: #f4f7f9; }

a {
  outline: none !important; }
  a:hover {
    outline: none !important; }

.the_etiquetas_area {
  text-align: right;
  display: block;
  overflow: hidden; }

.the_etiquetas_area2 {
  display: none; }

/* HEADER NAV
/*--------------------------------------------------------------------*/
header#masthead {
  margin-bottom: 0;
  -webkit-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 9876;
  height: auto;
  overflow: visible;
  background: #fff;
  border-bottom: 1px solid #cdd0d3 !important; }
  header#masthead .navbar-brand {
    margin: 0 0 0 130px;
    padding: 0; }
    header#masthead .navbar-brand .logo_normal {
      display: none; }
  header#masthead .navbar {
    padding: 0; }
  header#masthead .navbar-brand > a {
    padding: 15px 0;
    display: block;
    overflow: hidden; }
  header#masthead .idioma_area {
    position: absolute;
    z-index: 9876;
    top: 44px;
    right: 130px;
    width: auto; }
    header#masthead .idioma_area .language_switcher {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 3px 0 0 16px;
      position: relative;
      top: 0;
      z-index: 1;
      right: 0;
      cursor: pointer;
      overflow: visible;
      height: 20px;
      width: 16px;
      border-radius: 100%;
      background: url(../img/flag/nav_arrow_hover.png) 2px 8px no-repeat; }
    header#masthead .idioma_area ul {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      position: relative;
      padding: 0;
      top: 0;
      z-index: 1;
      right: 0;
      height: 20px;
      overflow: visible; }
      header#masthead .idioma_area ul li {
        list-style: none;
        margin: 0 0 5px 0;
        padding: 0;
        display: none;
        cursor: pointer; }
        header#masthead .idioma_area ul li a {
          text-indent: -99999px;
          display: block;
          overflow: hidden;
          width: 20px;
          height: 20px; }
        header#masthead .idioma_area ul li.lang-item-es a {
          background: url(../img/flag/mx.png) no-repeat; }
        header#masthead .idioma_area ul li.lang-item-en a {
          background: url(../img/flag/us.png) no-repeat; }
        header#masthead .idioma_area ul li.current-lang {
          display: block; }
      header#masthead .idioma_area ul.open li {
        display: block; }
  header#masthead .language_switcher {
    background: url(../img/flag/nav_arrow_hover.png) 2px 8px no-repeat; }
  header#masthead .navbar-nav > li > a {
    color: #c8c8c8 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    line-height: 18px;
    padding: 9px 15px !important; }
  header#masthead .navbar-nav > li > a:hover,
  header#masthead .navbar-nav > li.current_page_item > a {
    color: #ffdb25 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    line-height: 18px;
    padding: 9px 15px !important; }
  header#masthead .navbar-nav > li.menu-item-316 > a,
  header#masthead .navbar-nav > li.menu-item-45 > a {
    background: url(../img/nav/ico_inicio_sticky.png) 0 0 no-repeat;
    text-indent: -99999px;
    width: 16px;
    display: inline-block;
    vertical-align: top;
    padding: 0 !important;
    height: 13px;
    margin-top: 10px;
    margin-right: 5px; }
    header#masthead .navbar-nav > li.menu-item-316 > a:hover,
    header#masthead .navbar-nav > li.menu-item-45 > a:hover {
      background: url(../img/nav/ico_inicio_hover.png) no-repeat; }
  header#masthead #main-nav {
    margin-top: 24px;
    background: url(../img/nav/nav_divider_hover.png) 100% 10px no-repeat;
    margin-right: 188px;
    padding-right: 10px; }
  header#masthead .header_phone {
    position: absolute;
    top: 10px;
    right: 130px;
    width: 100%;
    z-index: 9;
    max-width: 50%; }
    header#masthead .header_phone .footer_phone a {
      color: #9fa7ab !important;
      font-size: 16px;
      line-height: 20px; }
      header#masthead .header_phone .footer_phone a:hover {
        color: #ffdd00 !important; }
    header#masthead .header_phone .footer_phone {
      color: #9fa7ab !important;
      background: url(../img/nav/ico_phone.png) no-repeat 0 50%;
      padding: 0 0 0 30px;
      line-height: 24px;
      margin: 0; }
      header#masthead .header_phone .footer_phone .footer_phone_span {
        color: #9fa7ab !important; }
  header#masthead .logo_sticky {
    display: block; }

.page-template.page-template-page-eventos .separator,
.page-template.page-template-page-congresos .separator,
.page-template.page-template-page-viajes .separator,
.home.page-template .separator {
  display: none; }

.page-template.page-template-page-eventos header#masthead,
.page-template.page-template-page-congresos header#masthead,
.page-template.page-template-page-viajes header#masthead,
.home.page-template header#masthead {
  margin-bottom: 0;
  background: url(../img/nav/nav_bg.png) 0 0 repeat;
  padding-bottom: 1px;
  border: 0 none !important; }
  .page-template.page-template-page-eventos header#masthead .navbar-nav > li > a,
  .page-template.page-template-page-congresos header#masthead .navbar-nav > li > a,
  .page-template.page-template-page-viajes header#masthead .navbar-nav > li > a,
  .home.page-template header#masthead .navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.75) !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    line-height: 18px;
    padding: 9px 15px; }
  .page-template.page-template-page-eventos header#masthead .navbar-nav > li > a:hover,
  .page-template.page-template-page-eventos header#masthead .navbar-nav > li.current_page_item > a,
  .page-template.page-template-page-congresos header#masthead .navbar-nav > li > a:hover,
  .page-template.page-template-page-congresos header#masthead .navbar-nav > li.current_page_item > a,
  .page-template.page-template-page-viajes header#masthead .navbar-nav > li > a:hover,
  .page-template.page-template-page-viajes header#masthead .navbar-nav > li.current_page_item > a,
  .home.page-template header#masthead .navbar-nav > li > a:hover,
  .home.page-template header#masthead .navbar-nav > li.current_page_item > a {
    color: #ffdb25 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    line-height: 18px; }
  .page-template.page-template-page-eventos header#masthead .navbar-nav > li.menu-item-316 > a,
  .page-template.page-template-page-eventos header#masthead .navbar-nav > li.menu-item-45 > a,
  .page-template.page-template-page-congresos header#masthead .navbar-nav > li.menu-item-316 > a,
  .page-template.page-template-page-congresos header#masthead .navbar-nav > li.menu-item-45 > a,
  .page-template.page-template-page-viajes header#masthead .navbar-nav > li.menu-item-316 > a,
  .page-template.page-template-page-viajes header#masthead .navbar-nav > li.menu-item-45 > a,
  .home.page-template header#masthead .navbar-nav > li.menu-item-316 > a,
  .home.page-template header#masthead .navbar-nav > li.menu-item-45 > a {
    background: url(../img/nav/ico_inicio.png) 0 0 no-repeat;
    text-indent: -99999px;
    width: 16px;
    display: inline-block;
    vertical-align: top;
    padding: 0 !important;
    height: 13px;
    margin-top: 11px;
    margin-right: 5px; }
    .page-template.page-template-page-eventos header#masthead .navbar-nav > li.menu-item-316 > a:hover,
    .page-template.page-template-page-eventos header#masthead .navbar-nav > li.menu-item-45 > a:hover,
    .page-template.page-template-page-congresos header#masthead .navbar-nav > li.menu-item-316 > a:hover,
    .page-template.page-template-page-congresos header#masthead .navbar-nav > li.menu-item-45 > a:hover,
    .page-template.page-template-page-viajes header#masthead .navbar-nav > li.menu-item-316 > a:hover,
    .page-template.page-template-page-viajes header#masthead .navbar-nav > li.menu-item-45 > a:hover,
    .home.page-template header#masthead .navbar-nav > li.menu-item-316 > a:hover,
    .home.page-template header#masthead .navbar-nav > li.menu-item-45 > a:hover {
      background: url(../img/nav/ico_inicio_hover.png) no-repeat; }
  .page-template.page-template-page-eventos header#masthead .header_phone .footer_phone a,
  .page-template.page-template-page-congresos header#masthead .header_phone .footer_phone a,
  .page-template.page-template-page-viajes header#masthead .header_phone .footer_phone a,
  .home.page-template header#masthead .header_phone .footer_phone a {
    color: rgba(255, 255, 255, 0.75) !important;
    font-size: 16px;
    line-height: 20px; }
    .page-template.page-template-page-eventos header#masthead .header_phone .footer_phone a:hover,
    .page-template.page-template-page-congresos header#masthead .header_phone .footer_phone a:hover,
    .page-template.page-template-page-viajes header#masthead .header_phone .footer_phone a:hover,
    .home.page-template header#masthead .header_phone .footer_phone a:hover {
      color: #ffdd00 !important; }
  .page-template.page-template-page-eventos header#masthead .header_phone .footer_phone,
  .page-template.page-template-page-congresos header#masthead .header_phone .footer_phone,
  .page-template.page-template-page-viajes header#masthead .header_phone .footer_phone,
  .home.page-template header#masthead .header_phone .footer_phone {
    color: rgba(255, 255, 255, 0.75) !important;
    background: url(../img/nav/ico_phone.png) no-repeat 0 50%;
    padding: 0 0 0 30px;
    line-height: 24px;
    margin: 0; }
    .page-template.page-template-page-eventos header#masthead .header_phone .footer_phone .footer_phone_span,
    .page-template.page-template-page-congresos header#masthead .header_phone .footer_phone .footer_phone_span,
    .page-template.page-template-page-viajes header#masthead .header_phone .footer_phone .footer_phone_span,
    .home.page-template header#masthead .header_phone .footer_phone .footer_phone_span {
      color: rgba(255, 255, 255, 0.75) !important; }
  .page-template.page-template-page-eventos header#masthead .logo_normal,
  .page-template.page-template-page-congresos header#masthead .logo_normal,
  .page-template.page-template-page-viajes header#masthead .logo_normal,
  .home.page-template header#masthead .logo_normal {
    display: block; }
  .page-template.page-template-page-eventos header#masthead .logo_sticky,
  .page-template.page-template-page-congresos header#masthead .logo_sticky,
  .page-template.page-template-page-viajes header#masthead .logo_sticky,
  .home.page-template header#masthead .logo_sticky {
    display: none; }
  .page-template.page-template-page-eventos header#masthead.sticky,
  .page-template.page-template-page-congresos header#masthead.sticky,
  .page-template.page-template-page-viajes header#masthead.sticky,
  .home.page-template header#masthead.sticky {
    background: #ffffff !important;
    border-bottom: 1px solid #cdd0d3 !important; }
    .page-template.page-template-page-eventos header#masthead.sticky .logo_normal,
    .page-template.page-template-page-congresos header#masthead.sticky .logo_normal,
    .page-template.page-template-page-viajes header#masthead.sticky .logo_normal,
    .home.page-template header#masthead.sticky .logo_normal {
      display: none; }
    .page-template.page-template-page-eventos header#masthead.sticky .logo_sticky,
    .page-template.page-template-page-congresos header#masthead.sticky .logo_sticky,
    .page-template.page-template-page-viajes header#masthead.sticky .logo_sticky,
    .home.page-template header#masthead.sticky .logo_sticky {
      display: block; }
    .page-template.page-template-page-eventos header#masthead.sticky .header_phone .footer_phone a,
    .page-template.page-template-page-congresos header#masthead.sticky .header_phone .footer_phone a,
    .page-template.page-template-page-viajes header#masthead.sticky .header_phone .footer_phone a,
    .home.page-template header#masthead.sticky .header_phone .footer_phone a {
      color: #9fa7ab !important; }
      .page-template.page-template-page-eventos header#masthead.sticky .header_phone .footer_phone a:hover,
      .page-template.page-template-page-congresos header#masthead.sticky .header_phone .footer_phone a:hover,
      .page-template.page-template-page-viajes header#masthead.sticky .header_phone .footer_phone a:hover,
      .home.page-template header#masthead.sticky .header_phone .footer_phone a:hover {
        color: #ffdb25 !important; }
    .page-template.page-template-page-eventos header#masthead.sticky .header_phone .footer_phone,
    .page-template.page-template-page-congresos header#masthead.sticky .header_phone .footer_phone,
    .page-template.page-template-page-viajes header#masthead.sticky .header_phone .footer_phone,
    .home.page-template header#masthead.sticky .header_phone .footer_phone {
      color: #9fa7ab !important; }
      .page-template.page-template-page-eventos header#masthead.sticky .header_phone .footer_phone .footer_phone_span,
      .page-template.page-template-page-congresos header#masthead.sticky .header_phone .footer_phone .footer_phone_span,
      .page-template.page-template-page-viajes header#masthead.sticky .header_phone .footer_phone .footer_phone_span,
      .home.page-template header#masthead.sticky .header_phone .footer_phone .footer_phone_span {
        color: #9fa7ab !important; }
    .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li > a,
    .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li > a,
    .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li > a,
    .home.page-template header#masthead.sticky .navbar-nav > li > a {
      color: #9fa7ab !important; }
    .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li > a:hover,
    .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li.current_page_item > a,
    .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li > a:hover,
    .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li.current_page_item > a,
    .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li > a:hover,
    .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li.current_page_item > a,
    .home.page-template header#masthead.sticky .navbar-nav > li > a:hover,
    .home.page-template header#masthead.sticky .navbar-nav > li.current_page_item > a {
      color: #ffdb25 !important; }
    .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li.menu-item-316 > a,
    .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li.menu-item-45 > a,
    .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li.menu-item-316 > a,
    .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li.menu-item-45 > a,
    .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li.menu-item-316 > a,
    .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li.menu-item-45 > a,
    .home.page-template header#masthead.sticky .navbar-nav > li.menu-item-316 > a,
    .home.page-template header#masthead.sticky .navbar-nav > li.menu-item-45 > a {
      background: url(../img/nav/ico_inicio_sticky.png) 0 0 no-repeat; }
      .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li.menu-item-316 > a:hover,
      .page-template.page-template-page-eventos header#masthead.sticky .navbar-nav > li.menu-item-45 > a:hover,
      .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li.menu-item-316 > a:hover,
      .page-template.page-template-page-congresos header#masthead.sticky .navbar-nav > li.menu-item-45 > a:hover,
      .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li.menu-item-316 > a:hover,
      .page-template.page-template-page-viajes header#masthead.sticky .navbar-nav > li.menu-item-45 > a:hover,
      .home.page-template header#masthead.sticky .navbar-nav > li.menu-item-316 > a:hover,
      .home.page-template header#masthead.sticky .navbar-nav > li.menu-item-45 > a:hover {
        background: url(../img/nav/ico_inicio_hover.png) no-repeat; }

.page-template.page-template-page-eventos header#masthead .idioma_area .language_switcher,
.page-template.page-template-page-congresos header#masthead .idioma_area .language_switcher,
.page-template.page-template-page-viajes header#masthead .idioma_area .language_switcher,
.home.page-template header#masthead .idioma_area .language_switcher {
  background: url(../img/flag/nav_arrow.png) 2px 8px no-repeat; }

.page-template.page-template-page-eventos .sticky .language_switcher,
.page-template.page-template-page-congresos .sticky .language_switcher,
.page-template.page-template-page-viajes .sticky .language_switcher,
.home.page-template .sticky .language_switcher {
  background: url(../img/flag/nav_arrow_hover.png) 2px 8px no-repeat !important; }

.page-template.page-template-page-eventos .sticky #main-nav,
.page-template.page-template-page-congresos .sticky #main-nav,
.page-template.page-template-page-viajes .sticky #main-nav,
.home.page-template .sticky #main-nav {
  background: url(../img/nav/nav_divider_hover.png) 100% 10px no-repeat !important; }

/* Home */
/*--------------------------------------------------------------------*/
.collage_area_padding {
  padding-bottom: 50px; }

.collage_area {
  padding-bottom: 0; }
  .collage_area .collage_content {
    padding-top: 20px; }
  .collage_area .content_general .collage_text {
    padding: 40px 40px 40px 80px;
    max-width: 530px; }
  .collage_area .content_general .ft_images {
    max-height: 396px;
    background-position: 50% 50%;
    background-repeat: repeat;
    background-size: cover; }
  .collage_area#eventos_box_area .content_general {
    background: url(../img/eventos/bg_eventos.png) #f7a400; }
    .collage_area#eventos_box_area .content_general h1 {
      color: #ffffff;
      font-weight: 400;
      padding: 0;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 32px;
      margin: 0; }
    .collage_area#eventos_box_area .content_general h2 {
      color: #ffffff;
      font-weight: 700;
      padding: 0;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
      .collage_area#eventos_box_area .content_general h2 a {
        color: #ffffff;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 38px;
        text-decoration: none; }
        .collage_area#eventos_box_area .content_general h2 a:hover {
          color: #ffdb25; }
    .collage_area#eventos_box_area .content_general .the-paragraph p {
      color: #ffffff;
      font-weight: 400;
      padding: 0;
      font-size: 15px;
      line-height: 28px;
      margin: 0 0 20px 0; }
    .collage_area#eventos_box_area .content_general a.btn_plus {
      width: 44px;
      height: 44px;
      text-indent: -99999px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-align: left; }
      .collage_area#eventos_box_area .content_general a.btn_plus.btn_plus_yellow {
        background: url(../img/icons/icon_plus_red.png); }
  .collage_area#congresos_box_area .ft_images_expanded {
    max-height: 1070px;
    height: 100%;
    position: relative;
    z-index: 9;
    background-size: cover; }
  .collage_area#congresos_box_area .content_general {
    background: url(../img/congresos/bg_congresos.png) #f7a400; }
    .collage_area#congresos_box_area .content_general .collage_text {
      padding: 40px 15px 40px 30px;
      max-width: 530px; }
    .collage_area#congresos_box_area .content_general h1 {
      color: #ffffff;
      font-weight: 400;
      padding: 0;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 32px;
      margin: 0; }
    .collage_area#congresos_box_area .content_general h2 {
      color: #ffd200;
      font-weight: 700;
      padding: 0;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
      .collage_area#congresos_box_area .content_general h2 a {
        color: #ffd200;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 38px;
        text-decoration: none; }
        .collage_area#congresos_box_area .content_general h2 a:hover {
          color: #ffffff; }
    .collage_area#congresos_box_area .content_general .the-paragraph p {
      color: #ffffff;
      font-weight: 400;
      padding: 0;
      font-size: 15px;
      line-height: 28px;
      margin: 0 0 20px 0; }
    .collage_area#congresos_box_area .content_general a.btn_plus {
      width: 44px;
      height: 44px;
      text-indent: -99999px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-align: left; }
      .collage_area#congresos_box_area .content_general a.btn_plus.btn_plus_yellow {
        background: url(../img/icons/icon_plus_black.png); }
  .collage_area#viajes_box_area .content_general {
    background: url(../img/viajes/bg_viajes.png) #f7a400; }
    .collage_area#viajes_box_area .content_general h1 {
      color: #2b2e2f;
      font-weight: 400;
      padding: 0;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 32px;
      margin: 0; }
    .collage_area#viajes_box_area .content_general h2 {
      color: #2b2e2f;
      font-weight: 700;
      padding: 0;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
      .collage_area#viajes_box_area .content_general h2 a {
        color: #2b2e2f;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 38px;
        text-decoration: none; }
        .collage_area#viajes_box_area .content_general h2 a:hover {
          color: #ffffff; }
    .collage_area#viajes_box_area .content_general .the-paragraph p {
      color: #2b2e2f;
      font-weight: 400;
      padding: 0;
      font-size: 15px;
      line-height: 28px;
      margin: 0 0 20px 0; }
    .collage_area#viajes_box_area .content_general a.btn_plus {
      width: 44px;
      height: 44px;
      text-indent: -99999px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-align: left; }
      .collage_area#viajes_box_area .content_general a.btn_plus.btn_plus_yellow {
        background: url(../img/icons/icon_plus_yellow.png); }

/* Footer */
/*--------------------------------------------------------------------*/
.scrollup {
  width: 28px;
  height: 28px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  text-indent: -9999px;
  background: url(../img/arrow-orange-hi.png) no-repeat transparent;
  opacity: 0.7; }

footer#colophon.footer_box_area {
  background: #2b2e2f !important; }

body.page-template-page-bolsa-de-trabajo .pre_footer.background-position-footer,
body.page-template-page-contacto .pre_footer.background-position-footer,
body.single-post .pre_footer.background-position-footer {
  background: url(../img/prefooter/background-divider-mitad.png) 50% 0 repeat-x #2b2e2f; }

.pre_footer.background-position-footer {
  background: url(../img/prefooter/background-divider-gris.png) 50% 0 repeat-x #2b2e2f; }

body.home.page-template .pre_footer.background-position-footer {
  background: url(../img/prefooter/background-divider.png) 50% 0 repeat-x #2b2e2f; }

.pre-liner {
  padding: 25px 0;
  background: url(../img/prefooter/background-liner.png) repeat-x;
  margin-bottom: 15px; }

.footer_phone {
  color: #636465;
  font-size: 16px;
  font-weight: 600;
  background: url(../img/icons/icon_phone.png) no-repeat 0 50%;
  padding: 0 0 0 40px;
  line-height: 30px;
  display: inline-block;
  vertical-align: top; }
  .footer_phone a {
    color: #636465 !important;
    text-decoration: none; }
  .footer_phone a:hover {
    color: #ffdb25 !important; }

.footer_social_red {
  display: inline-block;
  vertical-align: top;
  color: #636465;
  font-size: 16px;
  font-weight: 600;
  background: url(../img/icons/icon_divider.png) 15px 0 no-repeat;
  padding: 0 0 0 32px;
  margin: 0;
  line-height: 30px; }
  .footer_social_red li {
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    margin: 0 4px; }
  .footer_social_red a {
    background: url(../img/icons/icon_social_media.png) no-repeat;
    width: 30px;
    height: 30px;
    text-indent: -99999px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: left; }
    .footer_social_red a.footer_social_fb {
      background-position: 0 0; }
    .footer_social_red a.footer_social_tw {
      background-position: -40px 0; }
    .footer_social_red a.footer_social_yt {
      background-position: -80px 0; }
    .footer_social_red a.footer_social_li {
      background-position: -120px 0; }
    .footer_social_red a.footer_social_fb:hover {
      background-position: 0 -30px; }
    .footer_social_red a.footer_social_tw:hover {
      background-position: -40px -30px; }
    .footer_social_red a.footer_social_yt:hover {
      background-position: -80px -30px; }
    .footer_social_red a.footer_social_li:hover {
      background-position: -120px -30px; }

.footer_logo {
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden; }

.site_info {
  font-weight: 400;
  color: #636465 !important;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 60px; }
  .site_info .secondary_nav {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top; }
  .site_info .site_info_trademark {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top; }
  .site_info ul {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top; }
    .site_info ul li {
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: top; }
      .site_info ul li a {
        margin: 0;
        padding: 0 0 0 15px;
        display: inline-block;
        vertical-align: top;
        background: url(../img/icons/icon_divider_small.png) 5px 50% no-repeat; }
        .site_info ul li a:hover {
          color: #ffdb25 !important; }

.prefooter_box_area {
  background-color: #000000; }

.prefooter_item_area {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-align: center;
  text-decoration: none; }
  .prefooter_item_area img {
    visibility: hidden; }
  .prefooter_item_area .jumbotron {
    padding: 48px 10px;
    margin: 0;
    background: none;
    width: 100%;
    text-align: center;
    text-decoration: none; }
    .prefooter_item_area .jumbotron h2 {
      font-size: 52px;
      line-height: 60px;
      color: #f4f7f9;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      text-decoration: none; }
    .prefooter_item_area .jumbotron p {
      font-size: 18px;
      line-height: 24px;
      color: #f4f7f9;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      text-decoration: none; }
  .prefooter_item_area:hover {
    text-decoration: none;
    opacity: 0.9; }
    .prefooter_item_area:hover .jumbotron h2 {
      color: #eee; }
    .prefooter_item_area:hover .jumbotron p {
      color: #eee; }

/* TESTIMONIOS
/*--------------------------------------------------------------------*/
.testimonials_home {
  background: #e6e6e6;
  overflow: hidden;
  padding: 60px 0; }
  .testimonials_home h1 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #171514;
    text-transform: none;
    padding: 0;
    margin: 0; }
  .testimonials_home h2 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #171514;
    text-transform: none;
    padding: 0;
    margin: 0; }
  .testimonials_home .ths_item_comment {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #171514;
    text-transform: none;
    text-decoration: none;
    font-style: italic;
    margin: 25px 0;
    padding: 0; }
    .testimonials_home .ths_item_comment p {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #171514;
      text-transform: none;
      text-decoration: none;
      font-style: italic; }
  .testimonials_home .slick-dots {
    margin: 0;
    padding: 0 0;
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 987;
    width: 100%;
    text-align: center; }
    .testimonials_home .slick-dots li {
      background: url(../img/slider/slick_dots.png) 0 0 no-repeat;
      width: 10px;
      height: 10px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-indent: -9999px;
      padding: 0;
      margin: 0 8px;
      cursor: pointer; }
      .testimonials_home .slick-dots li.slick-active {
        background-position: -20px 0; }
  .testimonials_home .slick-dots {
    display: none !important; }
  .testimonials_home .ths_item_photo {
    border: 8px solid #ffd200;
    border-radius: 100%;
    width: 118px;
    height: 118px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top; }
  .testimonials_home .slick-arrow {
    position: absolute;
    z-index: 987;
    top: 50%;
    background: url(../img/slider_arrow.png) 0 0 no-repeat;
    width: 20px;
    height: 34px;
    border: 0 none;
    text-indent: -99999px;
    margin-top: -17px;
    outline: none !important; }
  .testimonials_home .slick-prev {
    background-position: 0 -34px;
    left: 0; }
    .testimonials_home .slick-prev:hover {
      background-position: 0 0; }
  .testimonials_home .slick-next {
    background-position: -20px 0;
    right: 0; }
    .testimonials_home .slick-next:hover {
      background-position: -20px -34px; }

/* BLOG
/*--------------------------------------------------------------------*/
#blog_area .blog_area_intro {
  text-align: center;
  max-width: 60%;
  margin: 0 auto; }
  #blog_area .blog_area_intro h1 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: #282828;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 15px 0; }
  #blog_area .blog_area_intro h2 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #171514;
    text-transform: none;
    margin: 0;
    padding: 0 0 25px 0; }

#blog_area .blog_area_recent {
  text-align: center;
  padding-bottom: 50px; }
  #blog_area .blog_area_recent a {
    display: inline-block;
    vertical-align: top;
    text-decoration: none; }
    #blog_area .blog_area_recent a .blog_imagen {
      padding: 0;
      margin: 0; }
    #blog_area .blog_area_recent a:hover h1 {
      color: #666e76; }
    #blog_area .blog_area_recent a:hover h2 {
      color: #484848; }
  #blog_area .blog_area_recent h1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #282828;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0; }
  #blog_area .blog_area_recent h2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #171514;
    text-transform: none;
    margin: 0;
    padding: 0 0 10px 0; }

/* SERVICES
/*--------------------------------------------------------------------*/
.services_area {
  background: #f5f5f5;
  padding-top: 60px;
  padding-bottom: 60px; }
  .services_area .services_area_intro {
    text-align: center;
    margin: 0 auto; }
    .services_area .services_area_intro h1 {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      color: #282828;
      text-transform: uppercase;
      margin: 0;
      padding: 0 0 15px 0; }
    .services_area .services_area_intro h2 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #171514;
      text-transform: none;
      margin: 0;
      padding: 0 0 25px 0; }
  .services_area .services_area_recent {
    text-align: center; }
    .services_area .services_area_recent a {
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
      background: url(../img/servicios/divider_liner.png) 0 0 repeat-y; }
      .services_area .services_area_recent a.img_type_Eventos {
        background: none; }
      .services_area .services_area_recent a .services_area_imagen {
        padding: 0;
        margin: 0 auto 24px;
        height: 120px;
        display: block;
        overflow: hidden;
        width: 140px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        text-indent: -99999px; }
      .services_area .services_area_recent a:hover h1 {
        color: #666e76; }
      .services_area .services_area_recent a:hover h2 {
        color: #484848; }
      .services_area .services_area_recent a:hover .services_area_imagen {
        background-position: 50% 100%; }
    .services_area .services_area_recent h1 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #282828;
      text-transform: uppercase;
      margin: 0;
      padding: 10px 0; }
    .services_area .services_area_recent h2 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #171514;
      text-transform: none;
      margin: 0;
      padding: 0 0 10px 0; }

/* USEFULLY
/*--------------------------------------------------------------------*/
.no_padding {
  padding: 0 !important; }

.align_left_text {
  text-align: left; }

.align_right_text {
  text-align: right; }

.visible_hidden {
  visibility: hidden !important; }

/* QUIENES_SOMOS */
/*--------------------------------------------------------------------*/
.collage_area_padding {
  padding-bottom: 50px; }

.collage_area {
  padding-bottom: 0; }
  .collage_area .collage_content {
    padding-top: 20px; }
  .collage_area .content_general .collage_text {
    padding: 40px 40px 40px 0;
    max-width: 530px; }
  .collage_area .content_general .align_left_text .collage_text {
    padding: 40px 40px 40px 0; }
  .collage_area .content_general .align_right_text .collage_text {
    padding: 40px 0 40px 40px; }
  .collage_area .content_general .ft_images {
    max-height: 410px;
    background-position: 50% 50%;
    background-repeat: repeat;
    background-size: cover; }
  .collage_area#quienes_somos_area .content_general {
    background: url(../img/quienes-somos/bg_quienes_somos.png) #ffffff; }
    .collage_area#quienes_somos_area .content_general .qs_video_area {
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden; }
    .collage_area#quienes_somos_area .content_general .qs_video_content {
      width: 100%;
      position: absolute;
      z-index: 98;
      text-align: center;
      top: 0;
      left: 0;
      display: block;
      overflow: hidden;
      height: 100%;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    .collage_area#quienes_somos_area .content_general .qs_video_box {
      background: url(../img/quienes-somos/bg_opacity.png);
      width: 100%;
      position: absolute;
      z-index: 987;
      text-align: center;
      top: 0;
      left: 0;
      display: block;
      overflow: hidden;
      height: 100%;
      cursor: pointer; }
      .collage_area#quienes_somos_area .content_general .qs_video_box .btn_play {
        background: url(../img/quienes-somos/btn_play.png);
        width: 80px;
        height: 80px;
        display: block;
        overflow: hidden;
        position: absolute;
        z-index: 987;
        text-align: left;
        top: 50%;
        left: 50%;
        text-indent: -99999px;
        margin: -40px;
        cursor: pointer; }
    .collage_area#quienes_somos_area .content_general h1 {
      color: #000000;
      font-weight: 400;
      padding: 0;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 32px;
      margin: 0; }
    .collage_area#quienes_somos_area .content_general h2 {
      color: #282828;
      font-weight: 700;
      padding: 0;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
      .collage_area#quienes_somos_area .content_general h2 a {
        color: #282828;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 38px;
        text-decoration: none; }
        .collage_area#quienes_somos_area .content_general h2 a:hover {
          color: #ffdb25; }
    .collage_area#quienes_somos_area .content_general .the-paragraph p {
      color: #282828;
      font-weight: 400;
      padding: 0;
      font-size: 15px;
      line-height: 28px;
      margin: 0 0 20px 0; }
    .collage_area#quienes_somos_area .content_general a.btn_plus {
      width: 44px;
      height: 44px;
      text-indent: -99999px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-align: left; }
      .collage_area#quienes_somos_area .content_general a.btn_plus.btn_plus_yellow {
        background: url(../img/icons/icon_plus_white.png); }

/* VIDEO AREA
/*--------------------------------------------------------------------*/
.video-js .vjs-control-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

/* SLIDER
/*--------------------------------------------------------------------*/
.slider-wrapper {
  background: #000000; }

.slick-slide {
  position: relative;
  z-index: 1; }

/* progress bar */
.slider-wrapper .transition-timer-carousel-progress-bar {
  width: 100%;
  height: 6px;
  background: #b10b31;
  border-radius: 0;
  position: absolute;
  z-index: 987;
  bottom: 0;
  left: 0;
  opacity: 1;
  border: 0 none;
  margin: 0; }

.slider_principal .slider-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  height: 100vh; }

.slider_principal .slider-progress {
  width: 100%;
  height: 6px;
  background: #eee;
  border-radius: 0;
  position: absolute;
  z-index: 987;
  bottom: 0;
  left: 0;
  opacity: .5; }

.slider_principal .the_slide_content {
  max-width: 900px;
  margin: 0 auto;
  padding: 0; }
  .slider_principal .the_slide_content .jumbotron {
    padding: 0;
    background: none;
    margin: 0;
    height: 100vh;
    border-radius: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .slider_principal .the_slide_content h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    text-transform: uppercase;
    padding: 0 15px;
    max-width: 900px; }
  .slider_principal .the_slide_content h2 {
    padding: 0;
    margin: 0;
    max-width: 900px; }
    .slider_principal .the_slide_content h2 p {
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      padding: 0 15px; }
  .slider_principal .the_slide_content .btn_video_play_embed,
  .slider_principal .the_slide_content .btn_video_play {
    border: 3px solid #ffdb25;
    border-radius: 25px;
    cursor: pointer;
    background: url(../img/slider/btn_play.png) 98% 50% no-repeat;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 32px;
    color: #ffdb25;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3px 45px 3px 25px;
    text-decoration: none; }

.slider_principal .carousel .carousel-indicators {
  position: absolute;
  z-index: 987;
  bottom: 30px;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  left: 0; }
  .slider_principal .carousel .carousel-indicators li {
    background: url(../img/slider/slider_dot.png) 0 0 no-repeat;
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    margin: 0 7px;
    cursor: pointer; }
    .slider_principal .carousel .carousel-indicators li.active {
      background-position: -19px 0; }

.slider_principal .carousel-control-prev {
  position: absolute;
  z-index: 987;
  top: 50%;
  background: url(../img/slider/slider_arrow_principal.png) 0 0 no-repeat;
  width: 31px;
  height: 55px;
  border: 0 none;
  text-indent: -99999px;
  margin-top: -17px;
  outline: none !important;
  background-position: 0 0;
  left: 130px; }
  .slider_principal .carousel-control-prev .carousel-control-prev-icon {
    display: none; }
  .slider_principal .carousel-control-prev:hover {
    background-position: 0 -55px; }

.slider_principal .carousel-control-next {
  position: absolute;
  z-index: 987;
  top: 50%;
  background: url(../img/slider/slider_arrow_principal.png) 0 0 no-repeat;
  width: 31px;
  height: 55px;
  border: 0 none;
  text-indent: -99999px;
  margin-top: -17px;
  outline: none !important;
  background-position: -31px 0;
  right: 130px; }
  .slider_principal .carousel-control-next .carousel-control-next-icon {
    display: none; }
  .slider_principal .carousel-control-next:hover {
    background-position: -31px -55px; }

.carousel .carousel-inner .carousel-item {
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-attachment: scroll;
  width: 100%; }

/* MODAL
------------------------------------------------- */
body .modal {
  z-index: 987654 !important; }
  body .modal .vjs-default-skin .vjs-big-play-button {
    background: url(../img/quienes-somos/btn_play.png);
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: absolute;
    z-index: 987;
    text-align: left;
    top: 50%;
    left: 50%;
    text-indent: -99999px;
    margin: -40px;
    border: 0 none;
    -webkit-box-shadow: 0 0 0 #000;
            box-shadow: 0 0 0 #000;
    border-radius: 0;
    cursor: pointer; }
  body .modal .vjs-default-skin .vjs-big-play-button::before {
    content: "";
    border: none;
    background: none; }

.modal-open .modal,
body.modal-open {
  padding: 0 !important; }

body.modal-open {
  overflow: visible !important; }

body .modal-backdrop {
  z-index: 98765 !important; }

body .modal .modal-dialog {
  -webkit-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  top: 50% !important;
  margin: 0 auto;
  background: none;
  border: 0 none;
  border-radius: 0;
  max-width: 800px; }
  body .modal .modal-dialog .modal-content {
    background: none;
    border: 0 none;
    border-radius: 0;
    max-width: 800px; }
    body .modal .modal-dialog .modal-content .content_icon_close {
      width: 100%;
      margin-bottom: 5px; }
    body .modal .modal-dialog .modal-content .close_modal {
      background: url(../img/modal/icon_close_modal.png) no-repeat;
      line-height: 24px;
      margin: 0;
      width: 40px;
      height: 40px;
      display: block;
      float: right;
      text-align: left;
      text-indent: -99999px;
      overflow: hidden;
      padding: 0;
      opacity: 1; }
  body .modal .modal-dialog .modal-body {
    background: #000000;
    padding: 0;
    max-width: 800px; }
  body .modal .modal-dialog .qs_video_area video {
    display: block;
    overflow: hidden; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 0;
  height: 0; }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* mobile_slider_slick */
/*----------------------------------------------------------*/
.carousel .carousel-indicators {
  margin: 0;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 987;
  width: 100%;
  text-align: center; }
  .carousel .carousel-indicators li {
    background: url(../img/slider/slick_dots.png) 0 0 no-repeat;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    margin: 0 8px;
    cursor: pointer; }
    .carousel .carousel-indicators li.active {
      background-position: -20px 0; }

.carousel#carousel_blog .carousel-indicators {
  position: relative; }

.blog_slider {
  padding-bottom: 50px; }

.slider_viajes .slick-initialized .slick-slide {
  display: block;
  max-height: 560px; }

/* buttom_home *
 -------------------------------------------*/
.buttom_home {
  background: url(../img/nav/ico_inicio_sticky.png) no-repeat;
  text-indent: -99999px;
  width: 16px;
  padding: 0;
  height: 13px;
  margin-top: 9px;
  margin-right: 5px;
  position: absolute;
  top: 41px;
  right: 110px;
  display: none; }
  .buttom_home:hover {
    background: url(../img/nav/ico_inicio_hover.png) no-repeat; }

.home.page-template .buttom_home {
  background: url(../img/nav/ico_inicio.png) no-repeat; }
  .home.page-template .buttom_home:hover {
    background: url(../img/nav/ico_inicio_hover.png) no-repeat; }

.home.page-template .sticky .buttom_home {
  background: url(../img/nav/ico_inicio_sticky.png) no-repeat; }

.sticky .buttom_home {
  background: url(../img/nav/ico_inicio_sticky.png) no-repeat; }
  .sticky .buttom_home:hover {
    background: url(../img/nav/ico_inicio_hover.png) no-repeat; }

/* titulares_barras */
/*-----------------------------------------------*/
.titulares_barras {
  background: #2b2e2f; }
  .titulares_barras h1 {
    font-size: 18px;
    line-height: 26px;
    color: #f4f7f9;
    text-transform: uppercase;
    padding: 0 0 0 44px;
    margin: 7px 0; }
  .titulares_barras.blog_etiquetas h1 {
    background: url(../img/blog/icon_blog.png) 10px 50% no-repeat; }

.the_etiquetas_area {
  text-align: right; }
  .the_etiquetas_area ul {
    display: inline-block;
    vertical-align: top;
    width: auto;
    text-align: right;
    margin: 0 10px 0 0;
    padding: 0; }
    .the_etiquetas_area ul li {
      display: inline-block;
      vertical-align: top;
      background: url(../img/blog/blog_divider.png) 0 50% no-repeat;
      padding: 0 0 0 8px;
      margin: 0; }
      .the_etiquetas_area ul li:first-child {
        background: none; }
      .the_etiquetas_area ul li a {
        padding: 7px 6px 7px 6px;
        font-size: 11px;
        line-height: 40px;
        text-transform: uppercase;
        color: #787a7c;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-decoration: none; }
        .the_etiquetas_area ul li a:hover {
          color: #ffdb25; }
  .the_etiquetas_area .btn_search {
    background: url(../img/blog/icon_search.png) 0 0 no-repeat;
    width: 40px;
    height: 40px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    cursor: pointer;
    float: right; }
    .the_etiquetas_area .btn_search:hover {
      margin: 0;
      padding: 0; }

/* separator */
.separator {
  padding-top: 86px; }

.btn_llamar {
  display: none; }

/* generic search */
/*-------------------------------------------------*/
.generic_search {
  padding: 35px;
  text-align: center;
  margin: 0;
  display: none;
  background: #ffffff; }
  .generic_search.active {
    display: block; }
  .generic_search .search-simple-box-container .searchformsend #senviar {
    font-size: 36px;
    line-height: 42px;
    color: #dee2e6;
    font-weight: 700;
    outline: 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    border: 0;
    background: url(../img/blog/icon_search_gris.png) 0 50% no-repeat;
    padding: 0 0 0 40px;
    max-width: 208px; }

/* GENERIC */
/*---------------------------------------------------------*/
.the_h1,
.generic_h1_page_result {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 0 15px; }

.the_content {
  padding-right: 20px; }

/* blog_single_area */
/*---------------------------------------------------------*/
.single_right {
  background-color: #ffffff; }

.content-bg-area {
  background: #ffffff url(../img/blog/bg.png) 50% 0 repeat-y; }

.content-bg-area-full {
  background: #f4f7f9; }

.single_right .blog_area_text .the_date {
  margin: 0 14px 0 0 !important; }

.comment_limit_info {
  color: #bfc2c5;
  font-style: italic;
  font-size: 14px;
  line-height: 18px; }
  .comment_limit_info span {
    background: transparent url(../img/icons/icon-edit.png) 0 0 no-repeat;
    padding-left: 24px; }

.blog_single_area .the_large_descripcion {
  margin: 25px auto;
  display: block;
  width: 100%;
  overflow: hidden;
  padding-right: 30px; }

.blog_single_area .pagination-right {
  padding-right: 40px; }

.blog_single_area .blog_area_box .blog_area_text .category_list {
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: top; }

.blog_single_area .blog_area_box .blog_area_text .the_date {
  font-size: 12px;
  color: #c3c5c8;
  font-weight: 400;
  padding: 0 0 0 24px;
  margin: 7px 14px 7px 14px;
  font-style: italic;
  line-height: 16px;
  vertical-align: top;
  background: url(../img/blog/icon_calendar.png) no-repeat;
  display: inline-block; }

.blog_single_area .blog_area_box .blog_area_text .navigation.post-navigation {
  padding-top: 1px;
  display: inline-block;
  vertical-align: top;
  width: auto; }

.blog_single_area .blog_area_box .blog_area_text .go_back {
  background: url(../img/blog/icon_flechas.png) 0 0 no-repeat transparent;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: top;
  margin: 1px 5px;
  cursor: pointer; }
  .blog_single_area .blog_area_box .blog_area_text .go_back.go_back_final {
    text-indent: -999999px;
    text-align: left; }
  .blog_single_area .blog_area_box .blog_area_text .go_back:hover {
    background-position: 0 -25px; }

.blog_single_area .blog_area_box .blog_area_text .nav-links {
  display: inline-block;
  overflow: hidden;
  border: 0 none;
  padding: 0;
  margin: 0;
  float: none;
  vertical-align: top;
  min-height: 26px; }
  .blog_single_area .blog_area_box .blog_area_text .nav-links .nav-previous {
    width: 25px;
    height: 25px;
    display: inline-block;
    overflow: hidden;
    border: 0 none;
    padding: 0;
    margin: 0 10px 0 0;
    float: none;
    vertical-align: top; }
    .blog_single_area .blog_area_box .blog_area_text .nav-links .nav-previous a {
      background: url(../img/blog/icon_flechas.png) -33px 0 no-repeat transparent;
      padding: 0;
      margin: 0;
      display: block;
      overflow: hidden;
      text-indent: -99999px;
      border: 0 none;
      text-align: left;
      border-radius: 0;
      width: 25px;
      height: 25px; }
      .blog_single_area .blog_area_box .blog_area_text .nav-links .nav-previous a:hover {
        text-decoration: none;
        background-position: -33px -25px; }
  .blog_single_area .blog_area_box .blog_area_text .nav-links .nav-next {
    width: 25px;
    height: 25px;
    display: inline-block;
    overflow: hidden;
    border: 0 none;
    padding: 0;
    margin: 0;
    float: none;
    vertical-align: top; }
    .blog_single_area .blog_area_box .blog_area_text .nav-links .nav-next a {
      background: url(../img/blog/icon_flechas.png) -66px 0 no-repeat transparent;
      padding: 0;
      margin: 0;
      display: block;
      overflow: hidden;
      text-indent: -99999px;
      border: 0 none;
      text-align: left;
      border-radius: 0;
      width: 25px;
      height: 25px; }
      .blog_single_area .blog_area_box .blog_area_text .nav-links .nav-next a:hover {
        text-decoration: none;
        background-position: -66px -25px; }

.single_left .blog_area_text {
  padding: 20px 0 5px !important; }

.single_right .blog_area_text {
  padding: 20px 30px 60px !important; }

/* comments-area */
/*---------------------------------------------------------*/
.comment_area_box {
  padding: 20px 30px 20px 0;
  margin: 0;
  display: block;
  width: 100%;
  overflow: hidden; }
  .comment_area_box ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bfc2c5;
    font-style: italic; }
  .comment_area_box ::-moz-placeholder {
    /* Firefox 19+ */
    color: #bfc2c5;
    font-style: italic; }
  .comment_area_box :-ms-input-placeholder {
    /* IE 10+ */
    color: #bfc2c5;
    font-style: italic; }
  .comment_area_box :-moz-placeholder {
    /* Firefox 18- */
    color: #bfc2c5;
    font-style: italic; }
  .comment_area_box .comments-area h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #636465;
    text-transform: uppercase;
    margin: 0 0 10px;
    padding: 0; }
  .comment_area_box .comments-area .titulado h1 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #636465;
    text-transform: uppercase;
    margin: 0 0 5px;
    padding: 0; }
  .comment_area_box .comments-area .titulado h2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #636465;
    text-transform: none;
    margin: 0 0 30px;
    padding: 0; }
  .comment_area_box .comments-area .media-body .card {
    border: 0 none;
    border-radius: 0; }
  .comment_area_box .comments-area .media-body .card-header {
    border-top: 0 none;
    text-align: left;
    padding: 10px 20px;
    background: none;
    border-bottom: 1px solid #eee; }
  .comment_area_box .comments-area .media-body .card-footer {
    border-top: 0 none;
    text-align: right;
    padding: 10px 20px;
    background: none; }
  .comment_area_box .comments-area .media-body h5 {
    font-size: 14px;
    line-height: 18px;
    color: #636465;
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0 0 8px 0; }
    .comment_area_box .comments-area .media-body h5 .fn {
      font-style: normal; }
  .comment_area_box .comments-area .media-body .comment-meta a {
    font-size: 12px;
    color: #c3c5c8;
    font-weight: 400;
    padding: 0 0 0 24px;
    margin: 2px 14px 2px 0;
    font-style: normal;
    line-height: 16px;
    background: url(../img/blog/icon_calendar.png) no-repeat;
    display: inline-block;
    vertical-align: top; }
    .comment_area_box .comments-area .media-body .comment-meta a:hover {
      text-decoration: underline; }
  .comment_area_box .comments-area .media-body .comment-meta .edit-link {
    display: inline-block;
    vertical-align: top; }
    .comment_area_box .comments-area .media-body .comment-meta .edit-link a {
      padding: 0;
      background: 0 0;
      text-transform: uppercase;
      font-style: normal;
      color: #000;
      font-weight: bold;
      letter-spacing: 0.1em; }
  .comment_area_box .comments-area .media-body .comment-content.card-block {
    color: #636465;
    font-size: 14px;
    line-height: 18px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 10px 20px; }
    .comment_area_box .comments-area .media-body .comment-content.card-block p {
      color: #636465;
      font-size: 14px;
      line-height: 18px;
      display: block;
      overflow: hidden; }
  .comment_area_box .comments-area .media-body .comment-reply-link.btn.btn-primary {
    background: #cdd0d3;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    color: #ffffff !important;
    text-transform: uppercase;
    border-radius: 25px;
    border: 0 none;
    padding: 10px 20px; }
    .comment_area_box .comments-area .media-body .comment-reply-link.btn.btn-primary:hover {
      color: #484848 !important; }
  .comment_area_box .comments-area .comment-form {
    padding: 30px 0 30px 2px;
    margin: 20px auto;
    border-top: 1px solid #dcdcdc; }
    .comment_area_box .comments-area .comment-form .comment-form-url {
      display: none; }
    .comment_area_box .comments-area .comment-form .logged-in-as a {
      padding: 0;
      background: 0 0;
      text-transform: uppercase;
      font-style: normal;
      color: #000;
      font-weight: 700;
      letter-spacing: .1em;
      font-size: 14px;
      line-height: 18px; }
      .comment_area_box .comments-area .comment-form .logged-in-as a:hover {
        color: #484848;
        text-decoration: underline; }
    .comment_area_box .comments-area .comment-form .comment-form-author {
      padding-right: 20px; }
    .comment_area_box .comments-area .comment-form .comment-form-email,
    .comment_area_box .comments-area .comment-form .comment-form-author {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      margin: -2px; }
      .comment_area_box .comments-area .comment-form .comment-form-email input,
      .comment_area_box .comments-area .comment-form .comment-form-author input {
        background: #ffffff;
        border: 0 none;
        height: 40px;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        margin-bottom: 20px;
        padding: 5px 18px; }
    .comment_area_box .comments-area .comment-form .field-hint {
      font-size: 14px;
      line-height: 18px;
      color: #636465;
      margin-bottom: 15px;
      display: block;
      width: 100%; }
    .comment_area_box .comments-area .comment-form textarea {
      background: #fff;
      border: 0;
      height: 240px;
      padding: 15px 18px;
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      color: #636465;
      resize: none;
      margin-bottom: 20px;
      border-radius: 0; }
  .comment_area_box .comments-area .form-submit {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: right; }
    .comment_area_box .comments-area .form-submit .submit {
      background: #cdd0d3;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      font-style: normal;
      color: #ffffff;
      text-transform: uppercase;
      border-radius: 25px;
      border: 0 none;
      padding: 10px 20px; }
      .comment_area_box .comments-area .form-submit .submit:hover {
        color: #484848; }
  .comment_area_box .comments-area .comment-respond #reply-title,
  .comment_area_box .comments-area .comment-respond .form-allowed-tags,
  .comment_area_box .comments-area .comment-respond .alert.alert-info {
    display: none; }
  .comment_area_box .comments-area .comment-respond #commentform .comment-notes {
    display: none; }

/* blog_area_box */
/*---------------------------------------------------------*/
.blog_area_box {
  text-align: left;
  outline: none;
  text-decoration: none; }
  .blog_area_box .a_zone {
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
  .blog_area_box .blog_area_img {
    padding: 0;
    position: relative;
    z-index: 1; }
    .blog_area_box .blog_area_img .attachment-full {
      max-width: 100%;
      height: auto;
      width: 100%; }
    .blog_area_box .blog_area_img a {
      background: #cdd0d3 url(../img/loader.gif) 50% 50% no-repeat;
      text-decoration: none;
      display: block;
      overflow: hidden; }
      .blog_area_box .blog_area_img a:hover {
        text-decoration: none; }
  .blog_area_box .blog_area_text {
    padding: 20px 20px 60px 20px;
    display: block;
    overflow: hidden;
    width: 100%; }
  .blog_area_box .category_list {
    padding: 0;
    margin: 0;
    display: block; }
    .blog_area_box .category_list a {
      background: #f7a400;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 5px 10px;
      color: #2b2e2f;
      text-decoration: none; }
      .blog_area_box .category_list a:hover {
        text-decoration: none;
        background: #ffd200; }
  .blog_area_box h1 {
    margin: 0;
    padding: 0; }
    .blog_area_box h1 a {
      font-size: 30px;
      color: #2b2e2f;
      line-height: 34px;
      font-weight: 700;
      padding: 15px 0;
      margin: 0;
      display: block;
      text-decoration: none; }
      .blog_area_box h1 a:hover {
        color: #ffd200;
        text-decoration: none; }
  .blog_area_box .the_descripcion {
    font-size: 16px;
    color: #2b2e2f;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 15px;
    display: block;
    overflow: hidden; }
    .blog_area_box .the_descripcion p {
      margin: 0 0 10px 0;
      padding: 0; }
  .blog_area_box .the_descripcion_limit img {
    display: none; }
  .blog_area_box .the_date_comments {
    position: absolute;
    z-index: 987;
    bottom: 20px;
    left: 40px;
    width: 100%;
    overflow: hidden;
    display: block;
    text-align: left; }
    .blog_area_box .the_date_comments .the_date {
      background: url(../img/blog/icon_calendar.png) no-repeat;
      font-size: 12px;
      color: #c3c5c8;
      font-weight: 400;
      padding: 0 0 0 24px;
      margin: 0 14px 0 0;
      font-style: italic;
      line-height: 16px;
      display: inline-block;
      vertical-align: top; }
    .blog_area_box .the_date_comments .the_comments {
      background: url(../img/blog/icon_comentar.png) no-repeat;
      font-size: 12px;
      color: #c3c5c8;
      font-weight: 400;
      padding: 0 0 0 24px;
      margin: 1px 14px 0 0;
      font-style: italic;
      line-height: 16px;
      display: inline-block;
      vertical-align: top; }
  .blog_area_box .img-fluid {
    max-width: 100%;
    height: auto;
    width: 100%; }
  .blog_area_box .btn_plus {
    background: url(../img/blog/icon_plus_blog.png) no-repeat;
    width: 44px;
    display: inline-block;
    vertical-align: top;
    outline: none;
    height: 44px;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0 0 10px 0;
    padding: 0;
    text-align: left; }
    .blog_area_box .btn_plus:hover {
      background-position: 0 100%; }
  .blog_area_box:hover {
    outline: none;
    text-decoration: none; }

/* navigation */
.wp-pagenavi {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 40px 0; }
  .wp-pagenavi .first {
    background: url(../img/blog/icon_flechas.png) 0 0 no-repeat;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px; }
    .wp-pagenavi .first:hover {
      background-color: #fff;
      background-position: 0 -25px; }
  .wp-pagenavi .last {
    background: url(../img/blog/icon_flechas.png) -99px 0 no-repeat;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px; }
    .wp-pagenavi .last:hover {
      background-color: #fff;
      background-position: -99px -25px; }
  .wp-pagenavi .previouspostslink {
    background: #ffffff url(../img/blog/icon_flechas.png) -33px 0 no-repeat;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px; }
    .wp-pagenavi .previouspostslink:hover {
      background-color: #fff;
      background-position: -33px -25px; }
  .wp-pagenavi .nextpostslink {
    background: url(../img/blog/icon_flechas.png) -66px 0 no-repeat #fff;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px; }
    .wp-pagenavi .nextpostslink:hover {
      background-color: #fff;
      background-position: -66px -25px; }

.wp-pagenavi .pages {
  display: none; }

.wp-pagenavi a, .wp-pagenavi span {
  text-decoration: none;
  border-radius: 100%;
  background: 0 none;
  border: 0;
  min-width: 27px;
  min-height: 25px;
  font-size: 16px;
  line-height: 20px;
  color: #b0b3b6;
  margin: 1px;
  padding: 2px 8px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  text-align: center; }

.wp-pagenavi a:hover, .wp-pagenavi span.current {
  background-color: #ffd200;
  color: #fff; }

.wp-pagenavi span.current {
  background-color: #ffd200;
  color: #fff; }

/* -webkit-input-placeholder */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1; }

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1; }

:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1; }

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1; }

/* no-results not-found */
.no-results.not-found .search-form {
  text-align: center; }
  .no-results.not-found .search-form label {
    width: auto;
    display: inline-block;
    vertical-align: top; }

.no-results.not-found .search-field.form-control {
  background: #fff;
  border: 0;
  padding: 0 18px;
  display: inline-block;
  vertical-align: top;
  width: auto;
  font-size: 14px;
  line-height: 18px;
  color: #636465;
  resize: none;
  margin-bottom: 20px;
  border-radius: 0;
  height: 44px; }

.no-results.not-found .search-submit {
  background: url(../img/blog/icon_search_gris.png) 50% 50% no-repeat #eeeeff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  border: 0;
  padding: 10px 20px;
  margin: 0 0 0 -4px;
  height: 44px;
  text-indent: -999999px;
  width: 50px; }
  .no-results.not-found .search-submit:hover {
    background-color: #eeeeee; }

/* lateral_barra_intro */
/*-----------------------------------------------------*/
.lateral_barra_intro h1 {
  color: #2b2e2f;
  font-size: 22px;
  line-height: 28px;
  display: block;
  width: 100%;
  background: url(../img/contacto/icon_contact.png) 0 50% no-repeat transparent;
  text-align: left;
  overflow: hidden;
  margin: 20px 0 10px;
  padding: 0 0 0 42px;
  text-transform: uppercase;
  font-weight: bold; }

.lateral_barra_intro h2 {
  color: #2b2e2f;
  font-size: 14px;
  line-height: 20px;
  display: block;
  width: 100%;
  text-align: left;
  overflow: hidden;
  margin: 0 0 10px 0;
  padding: 0;
  text-transform: none;
  font-weight: 400; }

.border-separator {
  margin: 0 auto;
  border-top: 1px solid #cdd0d3;
  padding: 0;
  display: block;
  width: 100%; }

/* box_contact_area */
/*----------------------------------------------------------*/
.form_contact_7 h1 {
  font-size: 22px;
  line-height: 28px;
  width: 100%;
  background: none;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #2b2e2f;
  text-align: left;
  overflow: hidden;
  margin: 0 0 10px;
  display: block; }

.form_contact_7 h2 {
  color: #636465;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
  text-transform: none;
  font-weight: 400; }

.knewssubscription_hidden {
  display: none !important; }

.box_contact_area ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bfc2c5;
  font-style: italic; }

.box_contact_area ::-moz-placeholder {
  /* Firefox 19+ */
  color: #bfc2c5;
  font-style: italic; }

.box_contact_area :-ms-input-placeholder {
  /* IE 10+ */
  color: #bfc2c5;
  font-style: italic; }

.box_contact_area :-moz-placeholder {
  /* Firefox 18- */
  color: #bfc2c5;
  font-style: italic; }

.box_contact_area .single_left .col-x-2 {
  padding-right: 20px !important;
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
  width: 100%;
  margin: 0 -2px; }

.box_contact_area .single_left .col-x-1 {
  padding-right: 20px !important;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  width: 100%;
  margin: 0 -2px;
  position: relative;
  z-index: 1; }

.box_contact_area .single_left .wpcf7-form .wpcf7-list-item-label-default {
  color: #babdc0;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  cursor: pointer;
  margin: 4px 0;
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 24px;
  position: relative;
  z-index: 987; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-list-item-label-default a {
    text-decoration: none;
    color: #babdc0;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    cursor: pointer;
    font-weight: 600;
    margin: 0; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-list-item-label-default a:hover {
      text-decoration: underline; }

.box_contact_area .single_left .wpcf7-form span.wpcf7-not-valid-tip {
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  line-height: 18px;
  font-style: italic;
  background: #fff;
  border: 1px solid red;
  padding: 2px 8px;
  overflow: visible;
  margin-top: 5px;
  position: relative;
  z-index: 1; }

.box_contact_area .single_left .wpcf7-form span.wpcf7-not-valid-tip:after {
  content: '';
  position: absolute;
  top: 0;
  left: 29px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: red;
  border-top: 0;
  border-left: 0;
  margin-left: -2.5px;
  margin-top: -5px; }

.box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  z-index: 987;
  top: 0;
  left: 0;
  width: 100%;
  overflow: visible; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    z-index: 987;
    top: -24px;
    left: 0;
    width: auto;
    background: #ffffff;
    border: 1px solid red;
    padding: 2px 8px;
    overflow: visible;
    font-style: italic;
    margin-top: 0; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 9px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: red;
    border-bottom: 0;
    border-left: 0;
    margin-left: -2.5px;
    margin-bottom: -5px;
    margin-top: 23px; }

.box_contact_area .single_left .wpcf7-form div.wpcf7-response-output {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 5px;
  font-style: italic;
  margin-right: 25px; }

.box_contact_area .single_left .wpcf7-form .both_check {
  display: inline-block;
  vertical-align: top;
  max-width: 75%;
  padding: 0;
  width: 100%; }
  .box_contact_area .single_left .wpcf7-form .both_check .wpcf7-form-control-wrap {
    padding: 0;
    margin: 0; }

.box_contact_area .single_left .wpcf7-form .both_btn {
  display: inline-block;
  vertical-align: top;
  max-width: 25%;
  width: 100%; }

.box_contact_area .single_left .wpcf7-form .dib {
  margin: 0 -2px; }

.box_contact_area .single_left .wpcf7-form .aux_input {
  display: none; }

.box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap {
  position: relative;
  margin-bottom: 20px;
  display: block;
  overflow: hidden;
  width: 100%; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.knewssubscription-911 {
    display: block;
    width: 100%;
    padding: 0;
    color: #495057;
    background-clip: padding-box;
    border: 0 none;
    border-radius: 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background: url(../img/contacto/ico_checkbox.png) 0 2px no-repeat transparent; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.knewssubscription-911.clicked {
      background: url(../img/contacto/ico_checkbox_active.png) 0 2px no-repeat transparent; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.knewssubscription-911 input {
      width: auto;
      height: auto;
      padding: 0;
      margin: 0 6px 0 0;
      visibility: hidden;
      display: inline-block;
      vertical-align: top; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.knewssubscription-911 label {
      color: #babdc0;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      margin: 0;
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 22px;
      z-index: 9; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-acceptance,
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox {
    display: block;
    width: 100%;
    padding: 0;
    color: #495057;
    background-clip: padding-box;
    border: 0 none;
    border-radius: 0;
    background: none;
    overflow: hidden;
    cursor: pointer; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-acceptance label,
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox label {
      cursor: pointer; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-acceptance .wpcf7-list-item-label,
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox .wpcf7-list-item-label {
      color: #babdc0;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      cursor: pointer;
      margin: 0; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-acceptance input,
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox input {
      width: auto;
      height: auto;
      padding: 0;
      margin: 0 6px 0 0;
      visibility: hidden; }
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item,
    .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
      display: inline-block;
      margin: 0;
      background: url(../img/contacto/ico_checkbox.png) 0 6px no-repeat transparent; }
      .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item.clicked,
      .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item.clicked {
        background: url(../img/contacto/ico_checkbox_active.png) 0 6px no-repeat transparent; }

.box_contact_area .single_left .wpcf7-form input {
  background: #fff;
  border: 0;
  height: 40px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #636465;
  margin-bottom: 0;
  padding: 5px 18px;
  border-radius: 0; }

.box_contact_area .single_left .wpcf7-form textarea {
  background: #fff;
  border: 0;
  height: 240px;
  padding: 15px 18px;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #636465;
  resize: none;
  margin-bottom: 0;
  border-radius: 0; }

.box_contact_area .single_left .wpcf7-form .wpcf7-submit {
  background: #ffd200;
  font-weight: bold;
  line-height: 24px;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  border-radius: 25px;
  text-transform: uppercase;
  width: auto; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-submit:hover {
    text-decoration: none;
    color: #000000; }

/* lateral_barra_mapa */
/*--------------------------------------------*/
.lateral_barra_mapa .lateral_barra_mapa_img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden; }
  .lateral_barra_mapa .lateral_barra_mapa_img p {
    padding: 0;
    margin: 0; }

.lateral_barra_mapa .lateral_barra_mapa_btn {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  text-align: right; }

.lateral_barra_mapa .btn_ver_mapa {
  background: #cdd0d3;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  border: 0;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  vertical-align: top; }
  .lateral_barra_mapa .btn_ver_mapa:hover {
    color: #484848; }

/* lateral_barra_data */
/*--------------------------------------------*/
.lateral_barra_data ul {
  padding: 0;
  margin: 0; }
  .lateral_barra_data ul li {
    padding: 0;
    margin: 0;
    background: none;
    color: #2b2e2f;
    font-weight: 600;
    padding-left: 34px;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 16px;
    line-height: 24px; }
    .lateral_barra_data ul li a {
      color: #2b2e2f;
      font-weight: 600;
      padding: 0;
      margin: 0;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px; }
      .lateral_barra_data ul li a:hover {
        color: #2b2e2f;
        text-decoration: underline; }
    .lateral_barra_data ul li p {
      margin: 0;
      padding: 0; }
    .lateral_barra_data ul li.ico_email {
      background: url(../img/contacto/icon_email.png) 0 50% no-repeat transparent;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 18px;
      word-wrap: break-word; }
    .lateral_barra_data ul li.ico_tel {
      background: url(../img/contacto/icon_phone.png) 0 50% no-repeat transparent;
      margin-bottom: 5px; }
    .lateral_barra_data ul li.ico_telfax {
      background: url(../img/contacto/icon_telfax.png) 0 50% no-repeat transparent;
      margin-bottom: 18px; }
    .lateral_barra_data ul li.ico_dire {
      background: url(../img/contacto/icon_map.png) 0 2px no-repeat transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px; }

/* sidebar */
/*-------------------------------------------------------*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 987654;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-x: hidden;
  -webkit-transition: .5s;
  transition: .5s;
  padding-top: 86px; }
  .sidenav .footer_social_red {
    color: #636465;
    background: none;
    padding: 0;
    margin: 28px; }
  .sidenav .closebtn {
    text-decoration: none;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: normal;
    padding: 44px 8px 8px 32px;
    color: #636465;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 36px;
    margin-left: 0; }
    .sidenav .closebtn:hover {
      text-decoration: none;
      color: #ffdb25; }
  .sidenav #menu-nav-primary .menu-item-316,
  .sidenav #menu-menu-principal .menu-item-45 {
    display: block; }
  .sidenav #menu-nav-primary,
  .sidenav #menu-menu-principal {
    margin: 0;
    padding: 0; }
    .sidenav #menu-nav-primary li,
    .sidenav #menu-menu-principal li {
      list-style: none;
      margin: 0;
      padding: 0; }
      .sidenav #menu-nav-primary li a,
      .sidenav #menu-menu-principal li a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;
        font-weight: normal;
        padding: 8px 8px 8px 32px;
        color: #636465;
        display: block; }
        .sidenav #menu-nav-primary li a:hover,
        .sidenav #menu-menu-principal li a:hover {
          text-decoration: none;
          color: #ffdb25; }

/* CONGRESOS */
/*--------------------------------------------*/
.congreso_page_box.viaje_page_box {
  /* escapate_box */ }
  .congreso_page_box.viaje_page_box .congreso_page_box_hero {
    background-position: 50% 0;
    background-size: cover; }
    .congreso_page_box.viaje_page_box .congreso_page_box_hero .the_slide_content h1 p strong {
      color: #2b2e2f; }
    .congreso_page_box.viaje_page_box .congreso_page_box_hero .the_slide_content h1 p {
      color: #2b2e2f; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item {
    background: #2b2e2f; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item h1 {
    font-size: 24px;
    line-height: 28px;
    color: #ffd200;
    font-weight: 700; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item h2 p {
    color: #dcdcdc;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item h3 {
    font-size: 18px;
    line-height: 22px;
    color: #95989b;
    margin: 0 0 5px; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item h4 p {
    color: #95989b;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item .btn_comun {
    background: transparent; }
    .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item .btn_comun:hover {
      background: #dcdcdc; }
  .congreso_page_box.viaje_page_box .escapate_beneficios {
    background: #dcdcdc; }
    .congreso_page_box.viaje_page_box .escapate_beneficios .escapate_beneficios_box {
      padding: 30px 15px; }
      .congreso_page_box.viaje_page_box .escapate_beneficios .escapate_beneficios_box ul {
        list-style: none;
        margin: 0 0 40px 0;
        padding: 0; }
        .congreso_page_box.viaje_page_box .escapate_beneficios .escapate_beneficios_box ul li {
          list-style: none;
          margin: 0;
          padding: 0; }
          .congreso_page_box.viaje_page_box .escapate_beneficios .escapate_beneficios_box ul li .imagen_esc_boxes {
            margin: 0;
            padding: 0;
            min-height: 180px;
            max-height: 180px; }
          .congreso_page_box.viaje_page_box .escapate_beneficios .escapate_beneficios_box ul li h1 {
            font-weight: 600;
            color: #2b2e2f;
            font-size: 15px;
            line-height: 24px;
            margin: 0;
            padding: 0 18px;
            display: inline-block;
            vertical-align: top; }
    .congreso_page_box.viaje_page_box .escapate_beneficios .btn_escape {
      border: 0;
      height: 44px;
      margin-bottom: 0;
      padding: 0 28px;
      font-size: 14px;
      line-height: 44px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 25px;
      display: inline-block;
      vertical-align: top;
      background: #ffd200;
      width: auto;
      color: #2b2e2f;
      text-decoration: none;
      outline: none; }
      .congreso_page_box.viaje_page_box .escapate_beneficios .btn_escape:hover {
        color: #636465;
        text-decoration: none; }
  .congreso_page_box.viaje_page_box .escapate_cover {
    min-height: 400px;
    background-position: 50% 50%;
    background-size: cover; }
  .congreso_page_box.viaje_page_box .escapate_box {
    position: relative;
    min-height: 400px;
    z-index: 1;
    width: 100%;
    overflow: hidden; }
    .congreso_page_box.viaje_page_box .escapate_box .escapate_box_text {
      width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 987;
      top: 40px;
      left: 30px;
      max-width: 80%; }
    .congreso_page_box.viaje_page_box .escapate_box .escapate_box_img {
      visibility: hidden; }
  .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item {
    background: #ffc600;
    border-left: 1px solid #ffd200; }
    .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item.nav-item-latitud a.nav-link {
      background: url(../img/viajes/img_latitud.png) 50% 50% no-repeat;
      text-indent: -999999px; }
      .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item.nav-item-latitud a.nav-link:hover {
        background-color: #2b2e2f; }
  .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item a {
    background: #ffc600;
    color: #c39006; }
  .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item a.nav-link.active,
  .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item a:hover {
    background-color: #2b2e2f;
    color: #ffd200; }

.congreso_page_box .congreso_page_box_hero {
  max-height: 700px;
  display: block;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover; }
  .congreso_page_box .congreso_page_box_hero .jumbotron,
  .congreso_page_box .congreso_page_box_hero .mx-auto {
    max-height: 700px; }
  .congreso_page_box .congreso_page_box_hero .the_slide_content_trabajo h1 p {
    text-align: center !important; }
  .congreso_page_box .congreso_page_box_hero .the_slide_content {
    max-width: 100%;
    margin: 0 auto;
    padding: 0; }
    .congreso_page_box .congreso_page_box_hero .the_slide_content .jumbotron {
      padding: 0;
      background: none;
      margin: 0;
      height: 100vh;
      border-radius: 0;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
      color: #fff;
      font-weight: bold;
      font-size: 44px;
      line-height: 48px;
      text-transform: none;
      padding: 0 0 0 170px;
      text-align: left;
      margin: 0; }
      .congreso_page_box .congreso_page_box_hero .the_slide_content h1 p {
        color: #fff;
        font-weight: 400;
        font-size: 26px;
        line-height: 30px;
        text-transform: none;
        padding: 0;
        margin: 0;
        text-align: left; }
        .congreso_page_box .congreso_page_box_hero .the_slide_content h1 p strong {
          color: #fff;
          font-weight: bold;
          font-size: 44px;
          line-height: 48px;
          text-transform: none;
          padding: 0;
          margin: 0;
          text-align: left; }

.congreso_page_box .tabulador .nav-tabs-title {
  font-size: 24px;
  line-height: 36px;
  padding: 12px 0;
  color: #ffd200;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
  display: inline-block;
  vertical-align: top; }

.congreso_page_box .tabulador .nav.nav-tabs {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  border: 0;
  width: 100%; }
  .congreso_page_box .tabulador .nav.nav-tabs .nav-item {
    margin: 0 -2px;
    background: #444647;
    border-radius: 0;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 32%;
    text-align: center;
    border-left: 1px solid #2b2e2f; }
    .congreso_page_box .tabulador .nav.nav-tabs .nav-item.first_item {
      border-left: 0;
      margin-right: -3px; }
    .congreso_page_box .tabulador .nav.nav-tabs .nav-item a {
      color: #95989b;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      border-radius: 0;
      background: #444647;
      border: 0;
      margin: 0;
      padding: 10px 0; }
      .congreso_page_box .tabulador .nav.nav-tabs .nav-item a.nav-link.active {
        background-color: #ffd200;
        color: #2b2e2f; }
      .congreso_page_box .tabulador .nav.nav-tabs .nav-item a:hover {
        background-color: #ffd200;
        color: #2b2e2f; }

.congreso_page_box .tabulador .tabulador_box {
  background: url(../img/congresos/bg_congresos.png) #000; }

.congreso_page_box .tabulador .solutions_list_item_dinamic {
  min-height: 400px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat; }
  .congreso_page_box .tabulador .solutions_list_item_dinamic:hover h6 {
    display: none; }
  .congreso_page_box .tabulador .solutions_list_item_dinamic:hover .alternative_data_hover {
    display: block; }
  .congreso_page_box .tabulador .solutions_list_item_dinamic .solutions_list_padding {
    padding: 30px; }
  .congreso_page_box .tabulador .solutions_list_item_dinamic h6 {
    padding: 30px;
    font-size: 36px;
    line-height: 48px;
    color: #999999;
    font-weight: bold;
    margin: 0 0 10px;
    opacity: 0.75; }
  .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover {
    min-height: 400px;
    display: none;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    position: relative;
    z-index: 98;
    text-align: left;
    width: 100%;
    height: 100%;
    background: rgba(43, 46, 47, 0.85);
    cursor: pointer; }
    .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover h1 {
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      margin: 0 0 10px;
      padding: 0;
      color: #ffd200; }
    .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover .the_desc {
      margin: 0;
      padding: 0; }
      .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover .the_desc p {
        color: #dcdcdc;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 0; }
    .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover h5 {
      font-size: 36px;
      line-height: 48px;
      color: #f4f7f9;
      font-weight: bold;
      margin: 0 0 10px;
      padding: 0;
      opacity: 1; }

.congreso_page_box .tabulador .solutions_list_item {
  min-height: 400px;
  background: #dcdcdc; }
  .congreso_page_box .tabulador .solutions_list_item .btn_comun {
    background: #dcdcdc;
    border: 2px solid #95989b;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    color: #95989b !important;
    text-transform: uppercase;
    border-radius: 25px;
    padding: 10px 20px;
    display: inline-block;
    vertical-align: top; }
    .congreso_page_box .tabulador .solutions_list_item .btn_comun:hover {
      background: #cdd0d3;
      text-decoration: none; }
  .congreso_page_box .tabulador .solutions_list_item h1 {
    font-size: 22px;
    line-height: 28px;
    color: #2b2e2f;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0; }
  .congreso_page_box .tabulador .solutions_list_item h2 {
    margin: 0;
    padding: 0; }
    .congreso_page_box .tabulador .solutions_list_item h2 p {
      color: #636465;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400; }
  .congreso_page_box .tabulador .solutions_list_item h3 {
    font-size: 18px;
    line-height: 24px;
    color: #95989b;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0; }
  .congreso_page_box .tabulador .solutions_list_item h4 {
    margin: 0 0 20px;
    padding: 0; }
    .congreso_page_box .tabulador .solutions_list_item h4 p {
      color: #636465;
      font-size: 13px;
      line-height: 21px;
      margin: 0;
      padding: 0;
      font-weight: 400; }
  .congreso_page_box .tabulador .solutions_list_item .solutions_list_padding {
    padding: 30px; }

/* eventos soluciones */
.congreso_page_box.evento_page_box .tabulador_box {
  background: url(../img/eventos/bg_eventos.png) #cc0935; }

.congreso_page_box.evento_page_box .tabulador .nav-tabs-title {
  color: #ffffff; }

/* viajes soluciones */
.congreso_page_box.viaje_page_box .tabulador_box {
  background: url(../img/viajes/bg_viajes.png) #cc0935; }

.congreso_page_box.viaje_page_box .tabulador .nav-tabs-title {
  color: #2b2e2f; }

/* empalmer_box */
.empalmer_box {
  padding: 66px 0;
  position: relative;
  z-index: 1; }
  .empalmer_box .btn_grey_up {
    background: url(../img/congresos/btn_up.png) transparent;
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: block;
    position: absolute;
    z-index: 987;
    text-align: left;
    top: 30px;
    right: 0;
    cursor: pointer; }

.sub-container {
  width: 100%;
  max-width: 1210px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

/* especialidades_box */
/*------------------------------------------------------*/
.especialidades_box {
  background: #2b2e2f; }
  .especialidades_box .especialidades_box_area {
    padding: 30px; }
  .especialidades_box h1 {
    font-size: 22px;
    line-height: 28px;
    color: #ffd200;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0; }
  .especialidades_box .the_desc_esp p {
    color: #95989b;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
    padding: 0; }
  .especialidades_box .listado_especialidades ul {
    padding: 0;
    margin: 0; }
    .especialidades_box .listado_especialidades ul li {
      list-style: none;
      padding: 0;
      margin: 0; }
      .especialidades_box .listado_especialidades ul li a {
        display: block;
        overflow: hidden;
        min-height: 200px;
        width: 100%;
        margin: 0;
        text-decoration: none;
        padding: 0;
        background-position: 50% 50%;
        text-align: center;
        cursor: pointer;
        /*background-size: cover;*/ }
        .especialidades_box .listado_especialidades ul li a .banner {
          background: rgba(43, 46, 47, 0.75);
          min-height: 200px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          color: rgba(244, 247, 249, 0.4);
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          overflow: hidden;
          padding-left: 5px;
          padding-right: 5px;
          -webkit-hyphens: auto;
              -ms-hyphens: auto;
                  hyphens: auto; }
          .especialidades_box .listado_especialidades ul li a .banner:hover {
            background: url(../img/congresos/opacity-yellow.png) transparent;
            color: #2b2e2f; }

.search_box_area_content_left {
  background: #ffffff; }
  .search_box_area_content_left .search_box_area_content_left_padding {
    padding: 30px; }
  .search_box_area_content_left h1 {
    font-size: 22px;
    line-height: 28px;
    color: #2b2e2f;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0; }
  .search_box_area_content_left .the_desc p {
    color: #636465;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0; }
  .search_box_area_content_left h2 {
    font-size: 18px;
    line-height: 24px;
    color: #95989b;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0; }
  .search_box_area_content_left .the_desc_extra p {
    color: #636465;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0; }
    .search_box_area_content_left .the_desc_extra p a {
      color: #95989b;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
      padding: 0;
      text-decoration: none;
      word-wrap: break-word; }
      .search_box_area_content_left .the_desc_extra p a:hover {
        color: #95989b;
        text-decoration: underline; }
    .search_box_area_content_left .the_desc_extra p strong {
      font-size: 16px;
      line-height: 22px;
      color: #95989b;
      font-weight: 700;
      background: url(../img/congresos/icon_phone.png) 0 50% no-repeat;
      padding: 0 20px;
      margin: 0 5px; }

/* */
/*-----------------------------------------------*/
.search_form_by_especiality ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bfc2c5;
  font-style: italic; }

.search_form_by_especiality ::-moz-placeholder {
  /* Firefox 19+ */
  color: #bfc2c5;
  font-style: italic; }

.search_form_by_especiality :-ms-input-placeholder {
  /* IE 10+ */
  color: #bfc2c5;
  font-style: italic; }

.search_form_by_especiality :-moz-placeholder {
  /* Firefox 18- */
  color: #bfc2c5;
  font-style: italic; }

.search_form_by_especiality .search_form_by_especiality_content {
  padding: 30px; }
  .search_form_by_especiality .search_form_by_especiality_content label {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #95989b;
    padding: 0;
    margin-bottom: 10px; }
  .search_form_by_especiality .search_form_by_especiality_content input {
    background: #fff;
    border: 0;
    height: 40px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #636465;
    margin: 0 0 20px 0;
    padding: 5px 18px;
    border-radius: 0; }
  .search_form_by_especiality .search_form_by_especiality_content .search-submit {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 25px;
    display: inline-block;
    vertical-align: top;
    background: #ffd200;
    width: auto;
    padding: 10px 30px;
    min-height: 44px;
    margin: 15px 0;
    text-decoration: none; }
    .search_form_by_especiality .search_form_by_especiality_content .search-submit.go_back_2 {
      color: #95989b;
      text-decoration: none;
      background: url(../img/congresos/icon_arrow.png) 20px 50% no-repeat #dcdcdc;
      padding: 10px 30px 10px 40px; }
      .search_form_by_especiality .search_form_by_especiality_content .search-submit.go_back_2:hover {
        text-decoration: none; }
    .search_form_by_especiality .search_form_by_especiality_content .search-submit:hover {
      text-decoration: none;
      color: #000; }
  .search_form_by_especiality .search_form_by_especiality_content .select_a_content_box {
    background: url(../img/congresos/icon_select.png) 97% 50% no-repeat #fff;
    min-height: 40px;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px; }
    .search_form_by_especiality .search_form_by_especiality_content .select_a_content_box select {
      width: 110%;
      height: auto;
      border: 0;
      background: 0 0;
      display: block;
      min-height: 40px;
      color: #636465;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      padding: 10px; }

/* result_box_specialty */
/*-----------------------------------------------------*/
.result_box_specialty .box_services_area_extra {
  display: none; }

.result_box_specialty .box_resultado_area.active .box_services_area_extra {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.result_box_specialty .btn_plus {
  background: url(../img/blog/icon_plus_blog.png) no-repeat;
  width: 44px;
  display: inline-block;
  vertical-align: top;
  outline: none;
  height: 44px;
  text-indent: -99999px;
  overflow: hidden;
  margin: 0 0 20px 0;
  padding: 0;
  text-align: left;
  cursor: pointer;
  float: right; }
  .result_box_specialty .btn_plus:hover {
    background-position: 0 100%; }

.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance .wpcf7-list-item-label,
.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox .wpcf7-list-item-label {
  color: #bfc2c5;
  font-size: 16px;
  line-height: 18px;
  font-style: normal;
  cursor: pointer;
  margin: 0;
  font-weight: 600; }

.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance input,
.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox input {
  margin: 0; }

.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item,
.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
  display: inline-block;
  background: #f4f7f9;
  padding: 10px 15px 10px 0;
  margin: 0 15px 0 0; }

.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item.clicked,
.result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item.clicked {
  background: #ffd200; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item.clicked .wpcf7-list-item-label,
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item.clicked .wpcf7-list-item-label {
    color: #484848; }

.result_box_specialty .box_contact_area .single_left {
  padding: 0 0 25px 0;
  background: #ffffff;
  border-bottom: 1px solid #f4f7f9; }
  .result_box_specialty .box_contact_area .single_left h1 {
    font-size: 20px;
    line-height: 24px;
    color: #636465;
    font-weight: bold;
    padding: 0 0 10px 0;
    margin: 0; }
  .result_box_specialty .box_contact_area .single_left h2 {
    font-size: 14px;
    line-height: 18px;
    color: #636465;
    font-weight: normal;
    padding: 0 0 20px 0;
    margin: 0; }
  .result_box_specialty .box_contact_area .single_left .border_line_separator {
    background: #fff;
    border-bottom: 1px solid #f4f7f9;
    width: 100%;
    height: 1px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .result_box_specialty .box_contact_area .single_left .icon_close_x {
    background: url(../img/blog/icon_close.png) no-repeat;
    width: 20px;
    display: inline-block;
    vertical-align: top;
    outline: none;
    height: 20px;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: left;
    cursor: pointer;
    position: absolute;
    z-index: 9;
    top: 30px;
    right: 30px; }
  .result_box_specialty .box_contact_area .single_left .c_caja_30 {
    padding: 30px 30px 10px 30px; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .both_check {
    max-width: 100%; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .both_btn {
    max-width: 100%;
    margin-top: 20px; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form input {
    background: #f4f7f9;
    border: 0;
    height: 40px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #636465;
    margin-bottom: 0;
    padding: 5px 18px;
    border-radius: 0; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form textarea {
    background: #f4f7f9;
    border: 0;
    height: 240px;
    padding: 15px 18px;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #636465;
    resize: none;
    margin-bottom: 0;
    border-radius: 0; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .wpcf7-submit {
    background: #ffd200;
    font-weight: bold;
    line-height: 24px;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    border-radius: 25px;
    text-transform: uppercase;
    width: auto; }
    .result_box_specialty .box_contact_area .single_left .wpcf7-form .wpcf7-submit:hover {
      text-decoration: none;
      color: #000000; }

.result_box_specialty .result_box_specialty_text {
  background: #2b2e2f;
  padding: 30px;
  height: 100%; }
  .result_box_specialty .result_box_specialty_text h1 {
    color: #ffd200;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0 0 10px 0; }
  .result_box_specialty .result_box_specialty_text h2 {
    color: #dcdcdc;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0 0 10px 0; }

.result_box_specialty .result_box_specialty_image {
  background: #2b2e2f;
  padding: 0;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover; }

.result_box_specialty .result_box_specialty_data {
  padding: 30px;
  height: 100%;
  background: #ffffff;
  border-bottom: 1px solid #f4f7f9; }
  .result_box_specialty .result_box_specialty_data h1 {
    color: #2b2e2f;
    font-weight: 700;
    padding: 0;
    text-transform: none;
    font-size: 28px;
    line-height: 34px;
    text-decoration: none;
    margin: 0 0 20px 0; }
  .result_box_specialty .result_box_specialty_data h2 {
    color: #2b2e2f;
    font-weight: 600;
    padding: 0;
    text-transform: none;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    margin: 0 0 15px 0; }
    .result_box_specialty .result_box_specialty_data h2 span {
      font-size: 18px;
      line-height: 24px;
      font-weight: normal; }
    .result_box_specialty .result_box_specialty_data h2 a {
      color: #f7a400;
      font-weight: 600;
      text-decoration: none;
      font-size: 18px;
      line-height: 24px; }
      .result_box_specialty .result_box_specialty_data h2 a:hover {
        color: #f7a400;
        text-decoration: underline; }

.error_404 {
  font-size: 220px;
  line-height: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  padding: 50px 0;
  letter-spacing: 15px;
  color: #999999; }

.square_box {
  background: #ffd200;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
  color: #2b2e2f;
  text-decoration: none;
  display: inline-block;
  vertical-align: top; }

/* aviso-title
-------------------------------------------------------------------------------*/
.titulares_barras h1.aviso-title {
  background: url(../img/icons/icon_hand.png) 10px 50% no-repeat; }

/* bolsa_de_trabajo_page_box
-------------------------------------------------------------------------------*/
.congreso_page_box.bolsa_de_trabajo_page_box {
  background: url(../img/blog/bg.png) 50% 0 repeat-y #fff; }
  .congreso_page_box.bolsa_de_trabajo_page_box .tabulador .nav-tabs-title {
    color: #ffffff; }
  .congreso_page_box.bolsa_de_trabajo_page_box .trabajo_bolsa_box_image {
    background: 50% 50% no-repeat #2b2e2f;
    background-image: none;
    padding: 0;
    height: auto;
    background-size: cover; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .bg-white {
    background: #ffffff; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .border-bot-2 {
    border-bottom: 1px solid #f4f7f9; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .trabajo_estado_box {
    background: #f7a400;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 10px;
    color: #2b2e2f;
    text-decoration: none;
    display: inline-block;
    vertical-align: top; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content h1 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    color: #2b2e2f; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content p {
    font-size: 14px;
    color: #2a2d2e;
    line-height: 18px;
    font-weight: 400;
    display: inline-block;
    vertical-align: top; }
    .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content p strong {
      font-weight: 700;
      text-align: left;
      display: inline-block;
      vertical-align: top; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc {
    padding-top: 30px;
    padding-bottom: 0;
    padding-right: 30px;
    padding-left: 0; }
    .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc p {
      color: #636465;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-align: left; }
      .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc p a {
        text-decoration: none;
        word-break: break-all; }
        .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc p a strong {
          text-decoration: none;
          word-break: break-all; }
        .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc p a:hover {
          text-decoration: underline; }
          .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc p a:hover strong {
            text-decoration: underline; }
      .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc p strong {
        color: #636465;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: left; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-phone {
    padding-top: 0;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 0; }
    .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-phone p {
      color: #636465;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      text-align: left;
      padding: 0;
      margin: 0 0 5px 0;
      width: 100%; }
      .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-phone p strong {
        background: url(../img/icons/icons_phone_2.png) 0 0 no-repeat;
        padding: 0 0 0 30px;
        color: #636465;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        display: inline-block;
        vertical-align: top; }

/* ACERCA DE */
/*------------------------------------------------------------*/
.congreso_page_box.acerca_de_page_box .congreso_page_box_hero .the_slide_content h1 p,
.congreso_page_box.acerca_de_page_box .congreso_page_box_hero .the_slide_content h1 p strong {
  color: #2b2e2f; }

.congreso_page_box.acerca_de_page_box .tabulador_box {
  background: url(../img/eventos/bg_eventos.png) #cc0935; }

.congreso_page_box.acerca_de_page_box .tabulador .nav-tabs-title {
  color: #fff; }

.congreso_page_box.acerca_de_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover {
  background: rgba(255, 210, 47, 0.85); }

.acerca_de_page_box {
  background: #ffffff; }
  .acerca_de_page_box .acercade_titles {
    font-size: 24px;
    line-height: 34px;
    color: #2b2e2f;
    display: block;
    width: 100%;
    overflow: hidden;
    text-align: center;
    padding: 0 30px;
    margin: 40px 0; }
  .acerca_de_page_box .our_philosophy_box {
    background: #e1e6e9;
    margin: 0;
    padding: 0;
    width: 100%; }
  .acerca_de_page_box .brands_box .jumbotron {
    padding: 0;
    background: none; }
  .acerca_de_page_box .brands_box .logo_acercade_box {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .acerca_de_page_box .brands_box .brands_box_items {
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 20px; }
  .acerca_de_page_box .our_philosophy {
    background: #e1e6e9;
    margin: 0;
    padding: 0;
    width: 100%; }
    .acerca_de_page_box .our_philosophy li {
      list-style: none;
      margin: 0;
      text-align: center;
      padding: 0 35px; }
    .acerca_de_page_box .our_philosophy h1 {
      font-size: 24px;
      line-height: 34px;
      font-weight: bold;
      color: #2b2e2f;
      text-transform: none;
      margin: 0 0 15px 0;
      padding: 0; }
    .acerca_de_page_box .our_philosophy h2 {
      font-size: 18px;
      color: #2b2e2f;
      line-height: 24px;
      font-weight: 300; }
      .acerca_de_page_box .our_philosophy h2 p {
        font-size: 18px;
        color: #2b2e2f;
        line-height: 24px;
        font-weight: 300; }
    .acerca_de_page_box .our_philosophy .img_rounded {
      border-radius: 100%;
      max-width: 330px;
      margin: 0 auto 35px;
      max-height: 330px;
      overflow: hidden;
      display: block; }
  .acerca_de_page_box .the_desc_extra p {
    color: #636465;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0; }
    .acerca_de_page_box .the_desc_extra p a {
      color: #95989b;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
      padding: 0;
      text-decoration: none;
      word-wrap: break-word; }
      .acerca_de_page_box .the_desc_extra p a:hover {
        color: #95989b;
        text-decoration: underline; }
    .acerca_de_page_box .the_desc_extra p strong {
      font-size: 16px;
      line-height: 22px;
      color: #95989b;
      font-weight: 700;
      background: url(../img/congresos/icon_phone.png) 0 50% no-repeat;
      padding: 0 20px;
      margin: 0 5px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box {
    text-align: center;
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h1 {
      color: #2b2e2f;
      font-size: 36px;
      font-weight: 400;
      line-height: 40px;
      margin: 0;
      text-transform: uppercase;
      padding: 0;
      letter-spacing: -0.05em; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h1 strong {
        font-weight: bold;
        font-size: 70px;
        line-height: 70px;
        color: #2b2e2f; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h1 span {
        color: #2b2e2f;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h2 {
      color: #2b2e2f;
      font-size: 28px;
      font-weight: 400;
      line-height: 34px;
      margin: 0;
      text-transform: uppercase;
      padding: 0;
      letter-spacing: -0.05em; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h2 strong {
        font-weight: bold;
        font-size: 100px;
        line-height: 110px;
        color: #2b2e2f; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h3 {
      color: #2b2e2f;
      font-size: 26px;
      font-weight: 400;
      line-height: 34px;
      margin: 0;
      text-transform: uppercase;
      padding: 0; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h3 strong {
        font-weight: bold;
        font-size: 70px;
        line-height: 70px;
        color: #2b2e2f; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h3 em {
        color: #2b2e2f;
        font-size: 20px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: 300; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h4 {
      font-size: 22px;
      line-height: 28px;
      margin: 0;
      text-transform: uppercase;
      padding: 0;
      font-weight: 300;
      color: #2b2e2f; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h4 strong {
        font-weight: bold;
        color: #2b2e2f;
        font-size: 120px;
        line-height: 100px;
        margin: 0;
        padding: 0; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h5 {
      color: #2b2e2f;
      font-size: 22px;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
      text-transform: uppercase;
      padding: 0; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h5 strong {
        font-weight: 600;
        font-size: 80px;
        line-height: 90px;
        color: #2b2e2f; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h5 em {
        color: #2b2e2f;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        font-style: normal; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h6 {
      font-size: 19px;
      line-height: 28px;
      margin: 0;
      text-transform: uppercase;
      padding: 0;
      font-weight: 300;
      opacity: 1;
      color: #2b2e2f;
      display: block !important; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h6 strong {
        font-weight: bold;
        color: #2b2e2f;
        font-size: 70px;
        line-height: 70px;
        margin: 0;
        padding: 0; }
    .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .jumbotron {
      padding: 0;
      background: none;
      margin: 0;
      border-radius: 0;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .jumbotron img {
        margin-bottom: 30px; }

/* nav-links */
.nav-links .nav-next a,
.nav-links .nav-previous a {
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 25px auto;
  color: #ffd200;
  text-decoration: none; }
  .nav-links .nav-next a:hover,
  .nav-links .nav-previous a:hover {
    text-decoration: underline; }

.desktop-view {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.desktop-view-block {
  display: block;
  width: 100%; }

.mobile-view,
.mobile-view-block {
  display: none; }

.visibility-mobile-view {
  visibility: hidden; }
  .visibility-mobile-view.activada {
    visibility: visible; }

/* 1200px */
/*------------------------------------------------*/
@media (min-width: 1441px) {
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1400px; }
  .container_small {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1180px; } }

/* 1400px */
/*------------------------------------------------*/
@media (max-width: 1440px) {
  header#masthead .navbar-brand > a {
    padding: 15px; }
  header#masthead .idioma_area .language_switcher, header#masthead .idioma_area ul {
    right: 17px; }
  .home.page-template header#masthead #main-nav {
    margin-right: 70px; }
  header#masthead .header_phone {
    right: 17px;
    top: 8px; }
  header#masthead .idioma_area {
    right: 0; }
  header#masthead .navbar-brand {
    margin: 0;
    padding: 0; }
  header#masthead #main-nav {
    margin-right: 78px; }
  .collage_area .content_general .ft_images {
    max-height: 500px;
    background-size: cover; }
  .container {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 1400px; }
  .container_small {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1370px; }
  .quienes_somos_area.collage_area .content_general .ft_images {
    max-height: 420px;
    background-size: cover; }
  .collage_area .content_general .collage_text {
    padding: 40px !important; }
  #quienes_somos_area.collage_area .content_general .collage_text {
    padding: 40px 50px 40px 40px !important; }
  #congresos_box_area.collage_area .content_general .collage_text {
    padding: 40px 70px 40px 40px !important; }
  #eventos_box_area.collage_area .content_general .collage_text,
  #viajes_box_area.collage_area .content_general .collage_text {
    padding: 40px 40px 40px 60px !important; } }

/* 1200px */
/*------------------------------------------------*/
@media (max-width: 1399px) {
  .container_small {
    width: 100%;
    max-width: 1170px; }
  .form_contact_7 {
    padding: 0 0 0 20px; }
  .blog_single_area .single_left .blog_area_text {
    padding: 20px 30px 5px !important; }
  .blog_single_area .the_large_descripcion {
    padding-left: 30px; }
  .comment_area_box {
    padding: 20px 30px 20px 30px; }
  /* eventos - congresos - viajes */
  .congreso_page_box .tabulador .nav-tabs-title {
    padding-right: 1px;
    padding-left: 30px; }
  .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
    padding: 0 0 0 30px; }
  .congreso_page_box .tabulador .nav-tabs-title {
    font-size: 18px;
    line-height: 36px; } }

/* 1200px */
/*------------------------------------------------*/
@media (max-width: 1280px) {
  .collage_area .content_general .ft_images {
    max-height: 525px; }
  .collage_area#congresos_box_area .ft_images_expanded {
    max-height: 1070px; } }

/* 1200px */
/*------------------------------------------------*/
@media (max-width: 1199px) {
  .container {
    width: 100%;
    max-width: 1200px; }
  .container_small {
    width: 100%;
    max-width: 994px; }
  .buttom_home {
    display: block; }
  header#masthead .navbar-toggler {
    background: url(../img/nav/nav_mobile_hover.png) no-repeat;
    width: 42px;
    height: 30px;
    display: inline-block;
    padding: 0;
    margin: 36px 5px 0;
    text-indent: -9999px;
    vertical-align: top;
    overflow: hidden;
    border: 0 none;
    outline: none;
    position: absolute;
    z-index: 987;
    top: 5px;
    right: 60px; }
    header#masthead .navbar-toggler:hover {
      background: url(../img/nav/nav_mobile_active.png) no-repeat; }
  header#masthead #main-nav {
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    z-index: 98754;
    text-align: right;
    max-width: 230px;
    border-radius: 5px; }
  header#masthead .navbar-nav > li > a {
    color: #999999 !important; }
  .page-template.page-template-page-eventos header#masthead .navbar-toggler,
  .page-template.page-template-page-congresos header#masthead .navbar-toggler,
  .page-template.page-template-page-viajess header#masthead .navbar-toggler,
  .home.page-template header#masthead .navbar-toggler {
    background: url(../img/nav/nav_mobile.png) no-repeat; }
    .page-template.page-template-page-eventos header#masthead .navbar-toggler:hover,
    .page-template.page-template-page-congresos header#masthead .navbar-toggler:hover,
    .page-template.page-template-page-viajess header#masthead .navbar-toggler:hover,
    .home.page-template header#masthead .navbar-toggler:hover {
      background: url(../img/nav/nav_mobile_active.png) no-repeat; }
  .page-template.page-template-page-eventos header#masthead.sticky .navbar-toggler,
  .page-template.page-template-page-congresos header#masthead.sticky .navbar-toggler,
  .page-template.page-template-page-viajess header#masthead.sticky .navbar-toggler,
  .home.page-template header#masthead.sticky .navbar-toggler {
    background: url(../img/nav/nav_mobile_hover.png) no-repeat; }
    .page-template.page-template-page-eventos header#masthead.sticky .navbar-toggler:hover,
    .page-template.page-template-page-congresos header#masthead.sticky .navbar-toggler:hover,
    .page-template.page-template-page-viajess header#masthead.sticky .navbar-toggler:hover,
    .home.page-template header#masthead.sticky .navbar-toggler:hover {
      background: url(../img/nav/nav_mobile_active.png) no-repeat; }
  header#masthead .header_phone .footer_phone a {
    font-size: 15px; }
    header#masthead .header_phone .footer_phone a:hover {
      color: #ffdb25 !important; }
  #menu-nav-primary .menu-item-316,
  #menu-menu-principal .menu-item-45 {
    display: none; }
  header#masthead .idioma_area {
    top: 47px; }
  .collage_area#viajes_box_area .content_general h2 a,
  .collage_area#congresos_box_area .content_general h2 a,
  .collage_area#eventos_box_area .content_general h2 a,
  .collage_area#quienes_somos_area .content_general h2 a {
    font-size: 28px;
    line-height: 32px; }
  .collage_area#congresos_box_area .content_general .the-paragraph p,
  .collage_area#viajes_box_area .content_general .the-paragraph p,
  .collage_area#eventos_box_area .content_general .the-paragraph p,
  .collage_area#quienes_somos_area .content_general .the-paragraph p {
    font-size: 15px;
    line-height: 18px; }
  .slider_principal .carousel-control-prev {
    left: 30px; }
  .slider_principal .carousel-control-next {
    right: 30px; }
  .lateral_barra_mapa .lateral_barra_mapa_btn {
    padding: 20px 20px 0 0;
    text-align: right; }
  .prefooter_item_area .jumbotron h2 {
    font-size: 36px;
    line-height: 46px; }
  footer#colophon .container_small {
    padding: 0 15px !important; }
  .congreso_page_box .tabulador .nav-tabs-title {
    font-size: 15px;
    line-height: 36px; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-phone {
    padding: 0 30px 30px 30px; }
  .congreso_page_box.bolsa_de_trabajo_page_box .bolsa_de_trabajo_page_box_content .data-desc {
    padding: 30px 30px 0 30px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h5 strong {
    font-size: 60px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h6 strong {
    font-size: 50px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h3 strong {
    font-size: 50px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h1 strong {
    font-size: 50px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h2 strong {
    font-size: 80px; }
  .acerca_de_page_box .alternative_data_hover .solutions_list_padding.acercade_numeros_box .the_desc h4 strong {
    font-size: 100px; } }

/* 1023px */
/*------------------------------------------------*/
@media (max-width: 1023px) {
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1024px; }
  .container_small {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 994px; }
  .acerca_de_page_box .brands_box .brands_box_items {
    width: 33%; }
  .collage_area .content_general .collage_text,
  .collage_area#congresos_box_area .content_general .collage_text,
  .collage_area#eventos_box_area .content_general .collage_text,
  .collage_area#viajes_box_area .content_general .collage_text {
    max-width: 100%; }
  .collage_area#quienes_somos_area .content_general h2 a {
    font-size: 24px;
    line-height: 28px; }
  .collage_area .content_general .collage_text {
    padding: 30px !important; }
  #congresos_box_area.collage_area .content_general .collage_text {
    padding: 30px !important; }
  #eventos_box_area.collage_area .content_general .collage_text,
  #viajes_box_area.collage_area .content_general .collage_text {
    padding: 30px !important; }
  #quienes_somos_area.collage_area .content_general .collage_text {
    padding: 30px !important; }
  .collage_area .content_general .ft_images.ft_congresos_imagen_derecha {
    max-height: 370px;
    background-size: cover; }
  footer .logo_footer_zone {
    text-align: center !important; }
  footer .social_footer_zone {
    text-align: center !important; }
  footer .site_info {
    padding-bottom: 30px;
    text-align: center;
    width: 100%; }
  footer .footer_social_red {
    margin: 0 0 60px 0;
    width: 100%;
    display: block;
    background: none;
    padding: 0; }
  .mobile-view {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .mobile-view-block {
    display: block;
    width: 100%; }
  .desktop-view,
  .desktop-view-block {
    display: none; }
  .slider_principal .the_slide_content h1 {
    font-size: 26px;
    line-height: 30px; }
  .slider_principal .the_slide_content h2 p {
    font-size: 14px;
    line-height: 18px; }
  .slider_principal .carousel-control-prev,
  .slider_principal .carousel-control-next,
  .slider_principal .slick-arrow {
    display: none !important; }
  .collage_area .content_general .collage_text,
  .collage_area#eventos_box_area .content_general .collage_text,
  .collage_area#congresos_box_area .content_general .collage_text,
  .collage_area#viajes_box_area .content_general .collage_text {
    padding: 40px 40px 80px 40px; }
  .collage_area#eventos_box_area .content_general h2,
  .collage_area#congresos_box_area .content_general h2,
  .collage_area#viajes_box_area .content_general h2 {
    font-size: 28px;
    line-height: 32px; }
  .collage_area#eventos_box_area .content_general h2 a,
  .collage_area#congresos_box_area .content_general h2 a,
  .collage_area#viajes_box_area .content_general h2 a {
    font-size: 28px;
    line-height: 32px; }
  .testimonials_home_slider .row {
    padding: 0;
    margin: 0; }
  .testimonials_home_slider .text-left {
    text-align: center !important; }
  .testimonials_home_slider .text-right {
    text-align: center !important; }
  .testimonials_home {
    padding: 26px 0 60px; }
    .testimonials_home .ths_item_photo {
      margin-bottom: 15px; }
    .testimonials_home .testimonials_home_slider .slick-arrow {
      display: none !important; }
    .testimonials_home .testimonials_home_slider .slick-dots {
      display: block !important; }
  .blog_area_box h1 a {
    font-size: 24px;
    line-height: 30px;
    padding: 15px 0 5px; }
  .blog_area_box .the_descripcion {
    font-size: 14px;
    line-height: 20px; }
  .lateral_barra_mapa .lateral_barra_mapa_btn {
    padding: 20px 0 0 0;
    text-align: center; }
  .form_contact_7 {
    padding: 0 30px; }
  .box_contact_area .single_left .col-x-2 {
    max-width: 100%;
    margin: 0;
    padding: 0 !important; }
  .box_contact_area .single_left .col-x-1 {
    max-width: 100%;
    margin: 0; }
  .box_contact_area .single_left .wpcf7-form .dib {
    max-width: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important; }
  .box_contact_area .single_left .wpcf7-form .dib.both_btn.text-right {
    text-align: center; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
    background-position: 0 2px; }
  .home.page-template header#masthead #main-nav {
    background: #000 !important;
    margin: 0;
    border-radius: 0;
    border: 0;
    position: fixed;
    top: 0px;
    right: 0;
    z-index: 9867;
    padding-top: 150px;
    height: 100%; }
  .prefooter_box_area {
    padding: 0;
    margin: 0 auto; }
  .result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item, .result_box_specialty .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
    margin: 2px 4px 2px 0; }
  .box_contact_area .single_left .col-x-1 {
    max-width: 100%;
    margin: 0;
    padding-right: 0 !important; }
  .congreso_page_box .tabulador .nav-tabs-title {
    font-size: 15px;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0; }
  .congreso_page_box .tabulador .nav.nav-tabs .nav-item a {
    font-size: 14px; } }

/* 990px */
/*------------------------------------------------*/
@media (max-width: 990px) {
  .congreso_page_box .tabulador .nav.nav-tabs .nav-item {
    max-width: 100%; }
  .congreso_page_box.viaje_page_box .escapate_box {
    overflow: hidden;
    max-height: 400px;
    min-height: 400px; }
  .congreso_page_box.viaje_page_box .tabulador .nav.nav-tabs .nav-item {
    border-left: 0; }
  .congreso_page_box .tabulador .nav.nav-tabs .nav-item {
    margin: 0;
    border: 0;
    border-top: 1px solid #2b2e2f; }
  .congreso_page_box .tabulador .nav.nav-tabs .nav-item.first_item {
    border-left: 0;
    margin-right: 0; }
  .congreso_page_box.viaje_page_box .solutions_list_item_escapate.solutions_list_item {
    background: #2b2e2f;
    border-top: 1px solid #ffc600; } }

/* 767px */
/*------------------------------------------------*/
@media (max-width: 767px) {
  .congreso_page_box .tabulador .solutions_list_item_dinamic h6 {
    display: none !important; }
  .congreso_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover {
    display: block !important;
    cursor: default; } }

/* 760px */
/*------------------------------------------------*/
@media (max-width: 760px) {
  .search_form_by_especiality_content .col-md-6.text-right,
  .search_form_by_especiality_content #scrolToThis {
    text-align: center !important; }
  .sub-container.sub-container-mobile {
    padding: 0; }
  .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
    padding: 0 30px; }
  .acerca_de_page_box .brands_box .brands_box_items {
    width: 50%; } }

/* 700px */
/*------------------------------------------------*/
@media (max-width: 700px) {
  .the_etiquetas_area1 ul {
    display: none !important; }
  .the_etiquetas_area2 {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    border-top: 1px solid #484848; }
    .the_etiquetas_area2 ul {
      margin: 0 !important; }
  .the_etiquetas_area ul {
    display: inline-block;
    vertical-align: top;
    width: auto;
    text-align: center;
    margin: 5px;
    padding: 0; } }

/* 540px */
/*------------------------------------------------*/
@media (max-width: 576px) {
  header#masthead .idioma_area {
    top: 12px; }
  header#masthead .navbar-toggler {
    right: 10px; }
  .buttom_home {
    right: 60px; }
  header#masthead .header_phone .footer_phone {
    display: none; }
  header#masthead .idioma_area ul li {
    display: inline-block !important;
    vertical-align: top;
    border-radius: 100%;
    margin: -4px -2px 0 0;
    border: 4px solid transparent; }
  .btn_llamar {
    right: 66px !important; }
  header#masthead .idioma_area .language_switcher {
    display: none; }
  header#masthead .idioma_area ul li.current-lang {
    border: 4px solid #ffdb25; }
  .btn_llamar {
    color: rgba(255, 255, 255, 0.4);
    background: url(../img/nav/ico_phone_mobile_hover.png) no-repeat;
    padding: 0;
    line-height: 24px;
    margin: 0;
    position: absolute;
    top: 3px;
    right: 47px;
    z-index: 9;
    width: 34px;
    display: block;
    height: 20px;
    text-indent: -99999px;
    overflow: hidden;
    text-align: left;
    cursor: pointer; }
    .btn_llamar:hover {
      text-decoration: none; }
  .home.page-template .btn_llamar {
    background: url(../img/nav/ico_phone_mobile.png) no-repeat; }
  .home.page-template .sticky .btn_llamar {
    background: url(../img/nav/ico_phone_mobile_hover.png) no-repeat; }
  .sticky .btn_llamar {
    background: url(../img/nav/ico_phone_mobile_hover.png) no-repeat; }
  #modal_call .modal-dialog .modal-body {
    background: #f5f6f1; }
  #modal_call .modal-dialog .modal-content {
    max-width: 280px;
    padding: 0;
    margin: 0 auto;
    background: #f5f6f1;
    border-radius: 15px; }
    #modal_call .modal-dialog .modal-content .modal-header .close {
      position: absolute;
      z-index: 987;
      right: 20px;
      top: 20px; }
    #modal_call .modal-dialog .modal-content .modal-title {
      padding: 20px 0;
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      display: block;
      width: 100%;
      text-align: center;
      font-weight: bold; }
    #modal_call .modal-dialog .modal-content .modal-header {
      border-bottom: 1px solid #cfcfcf; }
    #modal_call .modal-dialog .modal-content .modal-footer {
      background: none;
      border: 0 none;
      padding: 0;
      margin: 0; }
      #modal_call .modal-dialog .modal-content .modal-footer button {
        background: none;
        border: 0 none;
        padding: 10px;
        margin: 0;
        max-width: 50%;
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        text-transform: none;
        color: #007bff;
        border-right: 1px solid #cfcfcf; }
        #modal_call .modal-dialog .modal-content .modal-footer button:hover {
          text-decoration: none;
          color: #0056b3; }
      #modal_call .modal-dialog .modal-content .modal-footer a {
        background: none;
        border: 0 none;
        padding: 10px;
        margin: 0;
        max-width: 50%;
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        text-transform: none;
        color: #007bff; }
        #modal_call .modal-dialog .modal-content .modal-footer a:hover {
          text-decoration: none;
          color: #0056b3; }
  body .modal .modal-dialog .modal-content {
    max-width: 450px;
    padding: 0 15px;
    margin: 0 auto; }
  .prefooter_box_area {
    max-width: 400px;
    margin: 0 auto; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-submit {
    margin: 35px auto 0;
    display: block; } }

/* 517px */
/*------------------------------------------------*/
@media (max-width: 517px) {
  .box_services_area_extra .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip {
    top: -44px; }
  .box_services_area_extra .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip::after {
    margin-top: 41px; } }

/* 490px */
/*------------------------------------------------*/
@media (max-width: 490px) {
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip {
    top: -44px; }
  .box_contact_area .single_left .wpcf7-form .wpcf7-form-control-wrap.your-consent .wpcf7-not-valid-tip::after {
    margin-top: 41px; } }

/* 479px */
/*------------------------------------------------*/
@media (max-width: 479px) {
  .blog_area_box h1 a {
    font-size: 22px;
    line-height: 26px; }
  .comment_area_box .comments-area .comment-form .comment-form-author, .comment_area_box .comments-area .comment-form .comment-form-email {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0; }
  .comment_area_box .comments-area .comment-form .comment-form-author {
    padding-right: 0; }
  /* eventos - congresos - viajes */
  .congreso_page_box .congreso_page_box_hero .the_slide_content h1 {
    font-size: 28px;
    line-height: 30px; }
    .congreso_page_box .congreso_page_box_hero .the_slide_content h1 p {
      font-size: 18px;
      line-height: 30px; }
      .congreso_page_box .congreso_page_box_hero .the_slide_content h1 p strong {
        font-size: 28px;
        line-height: 30px; }
  .acerca_de_page_box .brands_box .brands_box_items {
    width: 100%; } }

/* 375px */
/*------------------------------------------------*/
@media (max-width: 375px) {
  .prefooter_box_area {
    max-width: 400px;
    margin: 0 auto; }
  .prefooter_item_area .jumbotron h2 {
    font-size: 36px;
    line-height: 42px; }
  .blog_single_area .blog_area_box .blog_area_text .the_date {
    display: block;
    overflow: hidden;
    width: 100%;
    margin: 17px 0 10px 0; }
  .blog_single_area .pagination-right {
    padding-right: 0; }
  .box_contact_area .single_left .wpcf7-form span.wpcf7-not-valid-tip {
    font-size: 10px; } }

/* 360px */
/*------------------------------------------------*/
@media (max-width: 360px) {
  .the_etiquetas_area ul li {
    padding: 0 0 0 5px;
    margin: 0; }
  .the_etiquetas_area ul li a {
    padding: 7px 3px; } }

/* IE11 */
/*------------------------------------------------------------------------------------------------------------------*/
_:-ms-fullscreen, :root body .desktop-view-block,
_:-ms-fullscreen, :root body .desktop-view {
  display: block; }

_:-ms-fullscreen, :root body .collage_area#congresos_box_area .ft_images_expanded {
  height: auto; }

_:-ms-fullscreen, :root body .especialidades_box .listado_especialidades ul li a .banner {
  padding-top: 85px; }

@media (max-width: 1023px) {
  _:-ms-fullscreen, :root body .mobile-view,
  _:-ms-fullscreen, :root body .mobile-view-block {
    display: block; } }
