/* General */
/*--------------------------------------------------------------------*/
body {
  background: #f4f7f9;
  font-family: 'Montserrat', sans-serif;
}

body.home.page-template {
  background: #ffffff;
}

h1 {
  color: #999999;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
}

p {
  font-size: 16px;
  color: #2b2e2f;
  line-height: 24px;
  font-weight: 400;
}

#content.site-content {
  padding-bottom: 0;
  padding-top: 0;
}

.padding-50 {
  padding-bottom: 50px !important;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px !important;
}

.padding-130 {
  padding: 0 130px !important;
}

.bg-gris {
  background-color: #f4f7f9;
}

a {
  outline: none !important;

  &:hover {
    outline: none !important;
  }

}

.the_etiquetas_area {
  text-align: right;
  display: block;
  overflow: hidden;
}

.the_etiquetas_area2 {
  display: none;
}

/* HEADER NAV
/*--------------------------------------------------------------------*/
header#masthead {
  margin-bottom: 0;
  -webkit-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 9876;
  height: auto;
  overflow: visible;
  background: #fff;
  border-bottom: 1px solid #cdd0d3 !important;

  .navbar-brand {
    margin: 0 0 0 130px;
    padding: 0;

    .logo_normal {
      display: none;
    }
  }

  .navbar {
    padding: 0;
  }

  .navbar-brand > a {
    padding: 15px 0;
    display: block;
    overflow: hidden;
  }

  .idioma_area {
    position: absolute;
    z-index: 9876;
    top: 44px;
    right: 130px;
    width: auto;

    .language_switcher {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 3px 0 0 16px;
      position: relative;
      top: 0;
      z-index: 1;
      right: 0;
      cursor: pointer;
      overflow: visible;
      height: 20px;
      width: 16px;
      border-radius: 100%;
      background: url(../img/flag/nav_arrow_hover.png) 2px 8px no-repeat;
    }

    ul {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      position: relative;
      padding: 0;
      top: 0;
      z-index: 1;
      right: 0;
      height: 20px;
      overflow: visible;

      li {
        list-style: none;
        margin: 0 0 5px 0;
        padding: 0;
        display: none;
        cursor: pointer;

        a {
          text-indent: -99999px;
          display: block;
          overflow: hidden;
          width: 20px;
          height: 20px;
        }

        &.lang-item-es a {
          background: url(../img/flag/mx.png) no-repeat;
        }

        &.lang-item-en a {
          background: url(../img/flag/us.png) no-repeat;
        }

        &.current-lang {
          display: block;
        }

      }

      &.open li {
        display: block;
      }
    }
  }

  .language_switcher {
    background: url(../img/flag/nav_arrow_hover.png) 2px 8px no-repeat;
  }

  .navbar-nav > li > a {
    color: #c8c8c8 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    line-height: 18px;
    padding: 9px 15px !important;
  }

  .navbar-nav > li > a:hover,
  .navbar-nav > li.current_page_item > a {
    color: #ffdb25 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    line-height: 18px;
    padding: 9px 15px !important;
  }

  .navbar-nav > li.menu-item-316 > a,
  .navbar-nav > li.menu-item-45 > a {
    background: url(../img/nav/ico_inicio_sticky.png) 0 0 no-repeat;
    text-indent: -99999px;
    width: 16px;
    display: inline-block;
    vertical-align: top;
    padding: 0 !important;
    height: 13px;
    margin-top: 10px;
    margin-right: 5px;

    &:hover {
      background: url(../img/nav/ico_inicio_hover.png) no-repeat;
    }
  }

  #main-nav {
    margin-top: 24px;
    background: url(../img/nav/nav_divider_hover.png) 100% 10px no-repeat;
    margin-right: 188px;
    padding-right: 10px;
  }

  .header_phone {
    position: absolute;
    top: 10px;
    right: 130px;
    width: 100%;
    z-index: 9;
    max-width: 50%;

    .footer_phone a {
      color: #9fa7ab !important;
      font-size: 16px;
      line-height: 20px;

      &:hover {
        color: #ffdd00 !important;
      }
    }

    .footer_phone {
      color: #9fa7ab !important;
      background: url(../img/nav/ico_phone.png) no-repeat 0 50%;
      padding: 0 0 0 30px;
      line-height: 24px;
      margin: 0;

      .footer_phone_span {
        color: #9fa7ab !important;
      }
    }

  }

  .logo_sticky {
    display: block;
  }

}

.page-template.page-template-page-eventos,
.page-template.page-template-page-congresos,
.page-template.page-template-page-viajes,
.home.page-template {

  .separator {
    display: none;
  }

  header#masthead {
    margin-bottom: 0;
    background: url(../img/nav/nav_bg.png) 0 0 repeat;
    padding-bottom: 1px;
    border: 0 none !important;

    .navbar-nav > li > a {
      color: rgba(255, 255, 255, 0.75) !important;
      font-weight: 700 !important;
      text-transform: uppercase;
      font-size: 15px !important;
      line-height: 18px;
      padding: 9px 15px;
    }

    .navbar-nav > li > a:hover,
    .navbar-nav > li.current_page_item > a {
      color: #ffdb25 !important;
      font-weight: 700 !important;
      text-transform: uppercase;
      font-size: 15px !important;
      line-height: 18px;
    }

    .navbar-nav > li.menu-item-316 > a,
    .navbar-nav > li.menu-item-45 > a {
      background: url(../img/nav/ico_inicio.png) 0 0 no-repeat;
      text-indent: -99999px;
      width: 16px;
      display: inline-block;
      vertical-align: top;
      padding: 0 !important;
      height: 13px;
      margin-top: 11px;
      margin-right: 5px;

      &:hover {
        background: url(../img/nav/ico_inicio_hover.png) no-repeat;
      }
    }

    .header_phone {

      .footer_phone a {
        color: rgba(255, 255, 255, .75) !important;
        font-size: 16px;
        line-height: 20px;

        &:hover {
          color: #ffdd00 !important;
        }
      }

      .footer_phone {
        color: rgba(255, 255, 255, .75) !important;
        background: url(../img/nav/ico_phone.png) no-repeat 0 50%;
        padding: 0 0 0 30px;
        line-height: 24px;
        margin: 0;

        .footer_phone_span {
          color: rgba(255, 255, 255, .75) !important;
        }
      }

    }

    .logo_normal {
      display: block;
    }

    .logo_sticky {
      display: none;
    }

    &.sticky {
      background: #ffffff !important;
      border-bottom: 1px solid #cdd0d3 !important;

      .logo_normal {
        display: none;
      }

      .logo_sticky {
        display: block;
      }

      //header_phone fix
      .header_phone {

        .footer_phone a {
          color: #9fa7ab !important;

          &:hover {
            color: #ffdb25 !important;
          }
        }

        .footer_phone {
          color: #9fa7ab !important;

          .footer_phone_span {
            color: #9fa7ab !important;
          }
        }

      }

      //
      .navbar-nav > li > a {
        color: #9fa7ab !important;
      }

      .navbar-nav > li > a:hover,
      .navbar-nav > li.current_page_item > a {
        color: #ffdb25 !important;
      }

      .navbar-nav > li.menu-item-316 > a,
      .navbar-nav > li.menu-item-45 > a {
        background: url(../img/nav/ico_inicio_sticky.png) 0 0 no-repeat;

        &:hover {
          background: url(../img/nav/ico_inicio_hover.png) no-repeat;
        }
      }

    }

  }

  header#masthead .idioma_area .language_switcher {
    background: url(../img/flag/nav_arrow.png) 2px 8px no-repeat;
  }

  .sticky {
    .language_switcher {
      background: url(../img/flag/nav_arrow_hover.png) 2px 8px no-repeat !important;
    }

    #main-nav {
      background: url(../img/nav/nav_divider_hover.png) 100% 10px no-repeat !important;
    }

  }

}

/* Home */
/*--------------------------------------------------------------------*/

.collage_area_padding {
  padding-bottom: 50px;
}

.collage_area {
  padding-bottom: 0;

  .collage_content {
    padding-top: 20px;
  }

  .content_general {

    .collage_text {
      padding: 40px 40px 40px 80px;
      max-width: 530px;
    }

    .ft_images {
      max-height: 396px;
      background-position: 50% 50%;
      background-repeat: repeat;
      background-size: cover;
    }

  }

  &#eventos_box_area {

    .content_general {
      background: url(../img/eventos/bg_eventos.png) #f7a400;

      h1 {
        color: #ffffff;
        font-weight: 400;
        padding: 0;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 32px;
        margin: 0;
      }

      h2 {
        color: #ffffff;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        margin: 0 0 10px 0;

        a {
          color: #ffffff;
          font-weight: 700;
          padding: 0;
          text-transform: uppercase;
          font-size: 32px;
          line-height: 38px;
          text-decoration: none;

          &:hover {
            color: #ffdb25;
          }

        }
      }

      .the-paragraph {

        p {
          color: #ffffff;
          font-weight: 400;
          padding: 0;
          font-size: 15px;
          line-height: 28px;
          margin: 0 0 20px 0;
        }

      }

      a.btn_plus {
        width: 44px;
        height: 44px;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-align: left;

        &.btn_plus_yellow {
          background: url(../img/icons/icon_plus_red.png);
        }

      }

    }

  }

  &#congresos_box_area {

    .ft_images_expanded {
      max-height: 1070px;
      height: 100%;
      position: relative;
      z-index: 9;
      background-size: cover;
    }

    .content_general {
      background: url(../img/congresos/bg_congresos.png) #f7a400;

      .collage_text {
        padding: 40px 15px 40px 30px;
        max-width: 530px;
      }

      h1 {
        color: #ffffff;
        font-weight: 400;
        padding: 0;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 32px;
        margin: 0;
      }

      h2 {
        color: #ffd200;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        margin: 0 0 10px 0;

        a {
          color: #ffd200;
          font-weight: 700;
          padding: 0;
          text-transform: uppercase;
          font-size: 32px;
          line-height: 38px;
          text-decoration: none;

          &:hover {
            color: #ffffff;
          }

        }
      }

      .the-paragraph {

        p {
          color: #ffffff;
          font-weight: 400;
          padding: 0;
          font-size: 15px;
          line-height: 28px;
          margin: 0 0 20px 0;
        }

      }

      a.btn_plus {
        width: 44px;
        height: 44px;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-align: left;

        &.btn_plus_yellow {
          background: url(../img/icons/icon_plus_black.png);
        }

      }

    }

  }

  &#viajes_box_area {

    .content_general {
      background: url(../img/viajes/bg_viajes.png) #f7a400;

      h1 {
        color: #2b2e2f;
        font-weight: 400;
        padding: 0;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 32px;
        margin: 0;
      }

      h2 {
        color: #2b2e2f;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        margin: 0 0 10px 0;

        a {
          color: #2b2e2f;
          font-weight: 700;
          padding: 0;
          text-transform: uppercase;
          font-size: 32px;
          line-height: 38px;
          text-decoration: none;

          &:hover {
            color: #ffffff;
          }

        }

      }

      .the-paragraph {

        p {
          color: #2b2e2f;
          font-weight: 400;
          padding: 0;
          font-size: 15px;
          line-height: 28px;
          margin: 0 0 20px 0;
        }

      }

      a.btn_plus {
        width: 44px;
        height: 44px;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-align: left;

        &.btn_plus_yellow {
          background: url(../img/icons/icon_plus_yellow.png);
        }

      }

    }

  }

}

/* Footer */
/*--------------------------------------------------------------------*/
.scrollup {
  width: 28px;
  height: 28px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  text-indent: -9999px;
  background: url(../img/arrow-orange-hi.png) no-repeat transparent;
  opacity: 0.7;
}

footer#colophon.footer_box_area {
  background: #2b2e2f !important;
}

body.page-template-page-bolsa-de-trabajo .pre_footer.background-position-footer,
body.page-template-page-contacto .pre_footer.background-position-footer,
body.single-post .pre_footer.background-position-footer {
  background: url(../img/prefooter/background-divider-mitad.png) 50% 0 repeat-x #2b2e2f;
}

.pre_footer.background-position-footer {
  background: url(../img/prefooter/background-divider-gris.png) 50% 0 repeat-x #2b2e2f;
}

body.home.page-template .pre_footer.background-position-footer {
  background: url(../img/prefooter/background-divider.png) 50% 0 repeat-x #2b2e2f;
}

.pre-liner {
  padding: 25px 0;
  background: url(../img/prefooter/background-liner.png) repeat-x;
  margin-bottom: 15px;
}

.footer_phone {
  color: #636465;
  font-size: 16px;
  font-weight: 600;
  background: url(../img/icons/icon_phone.png) no-repeat 0 50%;
  padding: 0 0 0 40px;
  line-height: 30px;
  display: inline-block;
  vertical-align: top;

  a {
    color: #636465 !important;
    text-decoration: none;
  }

  a:hover {
    color: #ffdb25 !important;
  }

}

.footer_social_red {
  display: inline-block;
  vertical-align: top;
  color: #636465;
  font-size: 16px;
  font-weight: 600;
  background: url(../img/icons/icon_divider.png) 15px 0 no-repeat;
  padding: 0 0 0 32px;
  margin: 0;
  line-height: 30px;

  li {
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    margin: 0 4px;
  }

  a {
    background: url(../img/icons/icon_social_media.png) no-repeat;
    width: 30px;
    height: 30px;
    text-indent: -99999px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: left;

    &.footer_social_fb {
      background-position: 0 0;
    }

    &.footer_social_tw {
      background-position: -40px 0;
    }

    &.footer_social_yt {
      background-position: -80px 0;
    }

    &.footer_social_li {
      background-position: -120px 0;
    }

    &.footer_social_fb:hover {
      background-position: 0 -30px;
    }

    &.footer_social_tw:hover {
      background-position: -40px -30px;
    }

    &.footer_social_yt:hover {
      background-position: -80px -30px;
    }

    &.footer_social_li:hover {
      background-position: -120px -30px;
    }

  }
}

.footer_logo {
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.site_info {
  font-weight: 400;
  color: #636465 !important;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 60px;

  .secondary_nav {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
  }

  .site_info_trademark {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top;
  }

  ul {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: top;

    li {
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: top;

      a {
        margin: 0;
        padding: 0 0 0 15px;
        display: inline-block;
        vertical-align: top;
        background: url(../img/icons/icon_divider_small.png) 5px 50% no-repeat;

        &:hover {
          color: #ffdb25 !important;
        }
      }

    }

  }
}

.prefooter_box_area {
  background-color: #000000;
}

.prefooter_item_area {

  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-align: center;
  text-decoration: none;

  img {
    visibility: hidden;
  }

  .jumbotron {
    padding: 48px 10px;
    margin: 0;
    background: none;
    width: 100%;
    text-align: center;
    text-decoration: none;

    h2 {
      font-size: 52px;
      line-height: 60px;
      color: #f4f7f9;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      text-decoration: none;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      color: #f4f7f9;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      text-decoration: none;
    }

  }

  &:hover {
    text-decoration: none;
    opacity: 0.9;

    .jumbotron {

      h2 {
        color: #eee;
      }

      p {
        color: #eee;
      }

    }

  }

}

/* TESTIMONIOS
/*--------------------------------------------------------------------*/
.testimonials_home {
  background: #e6e6e6;
  overflow: hidden;
  padding: 60px 0;

  h1 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #171514;
    text-transform: none;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #171514;
    text-transform: none;
    padding: 0;
    margin: 0;

  }

  .ths_item_comment {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #171514;
    text-transform: none;
    text-decoration: none;
    font-style: italic;
    margin: 25px 0;
    padding: 0;

    p {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #171514;
      text-transform: none;
      text-decoration: none;
      font-style: italic;
    }
  }

  .slick-dots {
    margin: 0;
    padding: 0 0;
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 987;
    width: 100%;
    text-align: center;

    li {
      background: url(../img/slider/slick_dots.png) 0 0 no-repeat;
      width: 10px;
      height: 10px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-indent: -9999px;
      padding: 0;
      margin: 0 8px;
      cursor: pointer;

      &.slick-active {
        background-position: -20px 0;
      }
    }
  }

  .slick-dots {
    display: none !important;
  }

  .ths_item_photo {
    border: 8px solid #ffd200;
    border-radius: 100%;
    width: 118px;
    height: 118px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
  }

  .slick-arrow {
    position: absolute;
    z-index: 987;
    top: 50%;
    background: url(../img/slider_arrow.png) 0 0 no-repeat;
    width: 20px;
    height: 34px;
    border: 0 none;
    text-indent: -99999px;
    margin-top: -17px;
    outline: none !important;
  }

  .slick-prev {
    background-position: 0 -34px;
    left: 0;

    &:hover {
      background-position: 0 0;
    }
  }

  .slick-next {
    background-position: -20px 0;
    right: 0;

    &:hover {
      background-position: -20px -34px;
    }
  }

}

/* BLOG
/*--------------------------------------------------------------------*/
#blog_area {

  .blog_area_intro {
    text-align: center;
    max-width: 60%;
    margin: 0 auto;

    h1 {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      color: #282828;
      text-transform: uppercase;
      margin: 0;
      padding: 0 0 15px 0;
    }

    h2 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #171514;
      text-transform: none;
      margin: 0;
      padding: 0 0 25px 0;
    }

  }

  .blog_area_recent {
    text-align: center;
    padding-bottom: 50px;

    a {
      display: inline-block;
      vertical-align: top;
      text-decoration: none;

      .blog_imagen {
        padding: 0;
        margin: 0;
      }

      &:hover {

        h1 {
          color: #666e76;
        }

        h2 {
          color: #484848;
        }

      }

    }

    h1 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #282828;
      text-transform: uppercase;
      margin: 0;
      padding: 10px 0;
    }

    h2 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #171514;
      text-transform: none;
      margin: 0;
      padding: 0 0 10px 0;
    }

  }

}

/* SERVICES
/*--------------------------------------------------------------------*/
.services_area {
  background: #f5f5f5;
  padding-top: 60px;
  padding-bottom: 60px;

  .services_area_intro {
    text-align: center;
    margin: 0 auto;

    h1 {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      color: #282828;
      text-transform: uppercase;
      margin: 0;
      padding: 0 0 15px 0;
    }

    h2 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #171514;
      text-transform: none;
      margin: 0;
      padding: 0 0 25px 0;
    }

  }

  .services_area_recent {
    text-align: center;

    a {
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
      background: url(../img/servicios/divider_liner.png) 0 0 repeat-y;

      &.img_type_Eventos {
        background: none;
      }

      .services_area_imagen {
        padding: 0;
        margin: 0 auto 24px;
        height: 120px;
        display: block;
        overflow: hidden;
        width: 140px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        text-indent: -99999px;

      }

      &:hover {

        h1 {
          color: #666e76;
        }

        h2 {
          color: #484848;
        }

        .services_area_imagen {
          background-position: 50% 100%;
        }

      }

    }

    h1 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #282828;
      text-transform: uppercase;
      margin: 0;
      padding: 10px 0;
    }

    h2 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #171514;
      text-transform: none;
      margin: 0;
      padding: 0 0 10px 0;
    }

  }

}

/* USEFULLY
/*--------------------------------------------------------------------*/
.no_padding {
  padding: 0 !important;
}

.align_left_text {
  text-align: left;
}

.align_right_text {
  text-align: right;
}

.visible_hidden {
  visibility: hidden !important;
}

/* QUIENES_SOMOS */
/*--------------------------------------------------------------------*/

.collage_area_padding {
  padding-bottom: 50px;
}

.collage_area {
  padding-bottom: 0;

  .collage_content {
    padding-top: 20px;
  }

  .content_general {

    .collage_text {
      padding: 40px 40px 40px 0;
      max-width: 530px;
    }

    .align_left_text .collage_text {
      padding: 40px 40px 40px 0;
    }

    .align_right_text .collage_text {
      padding: 40px 0 40px 40px;
    }

    .ft_images {
      max-height: 410px;
      background-position: 50% 50%;
      background-repeat: repeat;
      background-size: cover;
    }

  }

  &#quienes_somos_area {

    .content_general {
      background: url(../img/quienes-somos/bg_quienes_somos.png) #ffffff;

      .qs_video_area {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
      }

      .qs_video_content {
        width: 100%;
        position: absolute;
        z-index: 98;
        text-align: center;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        height: 100%;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
      }

      .qs_video_box {
        background: url(../img/quienes-somos/bg_opacity.png);
        width: 100%;
        position: absolute;
        z-index: 987;
        text-align: center;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        height: 100%;
        cursor: pointer;

        .btn_play {
          background: url(../img/quienes-somos/btn_play.png);
          width: 80px;
          height: 80px;
          display: block;
          overflow: hidden;
          position: absolute;
          z-index: 987;
          text-align: left;
          top: 50%;
          left: 50%;
          text-indent: -99999px;
          margin: -40px;
          cursor: pointer;
        }

      }

      h1 {
        color: #000000;
        font-weight: 400;
        padding: 0;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 32px;
        margin: 0;
      }

      h2 {
        color: #282828;
        font-weight: 700;
        padding: 0;
        text-transform: uppercase;
        margin: 0 0 10px 0;

        a {
          color: #282828;
          font-weight: 700;
          padding: 0;
          text-transform: uppercase;
          font-size: 32px;
          line-height: 38px;
          text-decoration: none;

          &:hover {
            color: #ffdb25;
          }

        }
      }

      .the-paragraph {

        p {
          color: #282828;
          font-weight: 400;
          padding: 0;
          font-size: 15px;
          line-height: 28px;
          margin: 0 0 20px 0;
        }

      }

      a.btn_plus {
        width: 44px;
        height: 44px;
        text-indent: -99999px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-align: left;

        &.btn_plus_yellow {
          background: url(../img/icons/icon_plus_white.png);
        }

      }

    }

  }

}

/* VIDEO AREA
/*--------------------------------------------------------------------*/

.video-js .vjs-control-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/* SLIDER
/*--------------------------------------------------------------------*/

.slider-wrapper {
  background: #000000;
}

.slick-slide {
  position: relative;
  z-index: 1;
}

/* progress bar */
.slider-wrapper .transition-timer-carousel-progress-bar {
  width: 100%;
  height: 6px;
  background: #b10b31;
  border-radius: 0;
  position: absolute;
  z-index: 987;
  bottom: 0;
  left: 0;
  opacity: 1;
  border: 0 none;
  margin: 0;
}

.slider_principal {

  .slider-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    height: 100vh;
  }

}

.slider_principal .slider-progress {
  width: 100%;
  height: 6px;
  background: #eee;
  border-radius: 0;
  position: absolute;
  z-index: 987;
  bottom: 0;
  left: 0;
  opacity: .5;
}

.slider_principal {

  .the_slide_content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0;

    .jumbotron {
      padding: 0;
      background: none;
      margin: 0;
      height: 100vh;
      border-radius: 0;
      text-align: center;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    h1 {
      color: #ffffff;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;
      text-transform: uppercase;
      padding: 0 15px;
      max-width: 900px;
    }

    h2 {
      padding: 0;
      margin: 0;
      max-width: 900px;

      p {
        color: #ffffff;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        padding: 0 15px;
      }
    }

    .btn_video_play_embed,
    .btn_video_play {
      border: 3px solid #ffdb25;
      border-radius: 25px;
      cursor: pointer;
      background: url(../img/slider/btn_play.png) 98% 50% no-repeat;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      line-height: 32px;
      color: #ffdb25;
      font-weight: 700;
      text-transform: uppercase;
      padding: 3px 45px 3px 25px;
      text-decoration: none;
    }

  }

  .carousel {

    .carousel-indicators {
      position: absolute;
      z-index: 987;
      bottom: 30px;
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;
      left: 0;

      li {
        background: url(../img/slider/slider_dot.png) 0 0 no-repeat;
        width: 14px;
        height: 14px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-indent: -9999px;
        padding: 0;
        margin: 0 7px;
        cursor: pointer;

        &.active {
          background-position: -19px 0;
        }
      }

    }
  }

  .carousel-control-prev {
    position: absolute;
    z-index: 987;
    top: 50%;
    background: url(../img/slider/slider_arrow_principal.png) 0 0 no-repeat;
    width: 31px;
    height: 55px;
    border: 0 none;
    text-indent: -99999px;
    margin-top: -17px;
    outline: none !important;
    background-position: 0 0;
    left: 130px;

    .carousel-control-prev-icon {
      display: none;
    }

    &:hover {
      background-position: 0 -55px;
    }
  }

  .carousel-control-next {
    position: absolute;
    z-index: 987;
    top: 50%;
    background: url(../img/slider/slider_arrow_principal.png) 0 0 no-repeat;
    width: 31px;
    height: 55px;
    border: 0 none;
    text-indent: -99999px;
    margin-top: -17px;
    outline: none !important;
    background-position: -31px 0;
    right: 130px;

    .carousel-control-next-icon {
      display: none;
    }

    &:hover {
      background-position: -31px -55px;
    }
  }
}

.carousel {

  .carousel-inner {

    .carousel-item {

      background-size: cover;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-attachment: scroll;
      width: 100%;

    }

  }

}

/* MODAL
------------------------------------------------- */
body .modal {
  z-index: 987654 !important;

  .vjs-default-skin .vjs-big-play-button {
    background: url(../img/quienes-somos/btn_play.png);
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: absolute;
    z-index: 987;
    text-align: left;
    top: 50%;
    left: 50%;
    text-indent: -99999px;
    margin: -40px;
    border: 0 none;
    box-shadow: 0 0 0 #000;
    border-radius: 0;
    cursor: pointer;
  }

  .vjs-default-skin .vjs-big-play-button::before {
    content: "";
    border: none;
    background: none;
  }

}

.modal-open .modal,
body.modal-open {
  padding: 0 !important;
}

body.modal-open {
  overflow: visible !important;
}

body .modal-backdrop {
  z-index: 98765 !important;
}

body .modal .modal-dialog {
  -webkit-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  top: 50% !important;
  margin: 0 auto;
  background: none;
  border: 0 none;
  border-radius: 0;
  max-width: 800px;

  .modal-content {
    background: none;
    border: 0 none;
    border-radius: 0;
    max-width: 800px;

    .content_icon_close {
      width: 100%;
      margin-bottom: 5px;
    }

    .close_modal {
      background: url(../img/modal/icon_close_modal.png) no-repeat;
      line-height: 24px;
      margin: 0;
      width: 40px;
      height: 40px;
      display: block;
      float: right;
      text-align: left;
      text-indent: -99999px;
      overflow: hidden;
      padding: 0;
      opacity: 1;
    }
  }

  .modal-body {
    background: #000000;
    padding: 0;
    max-width: 800px;
  }

  .qs_video_area {
    video {
      display: block;
      overflow: hidden;
    }
  }

}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* mobile_slider_slick */
/*----------------------------------------------------------*/

.carousel {

  .carousel-indicators {
    margin: 0;
    padding: 15px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 987;
    width: 100%;
    text-align: center;

    li {
      background: url(../img/slider/slick_dots.png) 0 0 no-repeat;
      width: 10px;
      height: 10px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      text-indent: -9999px;
      padding: 0;
      margin: 0 8px;
      cursor: pointer;

      &.active {
        background-position: -20px 0;
      }
    }

  }

  &#carousel_blog {
    .carousel-indicators {
      position: relative;
    }
  }

}

.blog_slider {
  padding-bottom: 50px;
}

.slider_viajes {

  .slick-initialized .slick-slide {
    display: block;
    max-height: 560px;
  }

}

/* buttom_home *
 -------------------------------------------*/
.buttom_home {
  background: url(../img/nav/ico_inicio_sticky.png) no-repeat;
  text-indent: -99999px;
  width: 16px;
  padding: 0;
  height: 13px;
  margin-top: 9px;
  margin-right: 5px;
  position: absolute;
  top: 41px;
  right: 110px;
  display: none;

  &:hover {
    background: url(../img/nav/ico_inicio_hover.png) no-repeat;
  }
}

.home.page-template {

  .buttom_home {
    background: url(../img/nav/ico_inicio.png) no-repeat;

    &:hover {
      background: url(../img/nav/ico_inicio_hover.png) no-repeat;
    }
  }

  .sticky .buttom_home {
    background: url(../img/nav/ico_inicio_sticky.png) no-repeat;
  }
}

.sticky .buttom_home {
  background: url(../img/nav/ico_inicio_sticky.png) no-repeat;

  &:hover {
    background: url(../img/nav/ico_inicio_hover.png) no-repeat;
  }
}

/* titulares_barras */
/*-----------------------------------------------*/

.titulares_barras {
  background: #2b2e2f;

  h1 {
    font-size: 18px;
    line-height: 26px;
    color: #f4f7f9;
    text-transform: uppercase;
    padding: 0 0 0 44px;
    margin: 7px 0;
  }

  &.blog_etiquetas {

    h1 {
      background: url(../img/blog/icon_blog.png) 10px 50% no-repeat;
    }

  }

}

.the_etiquetas_area {
  text-align: right;

  ul {
    display: inline-block;
    vertical-align: top;
    width: auto;
    text-align: right;
    margin: 0 10px 0 0;
    padding: 0;

    li {

      display: inline-block;
      vertical-align: top;
      background: url(../img/blog/blog_divider.png) 0 50% no-repeat;
      padding: 0 0 0 8px;
      margin: 0;

      &:first-child {
        background: none;
      }

      a {
        padding: 7px 6px 7px 6px;
        font-size: 11px;
        line-height: 40px;
        text-transform: uppercase;
        color: #787a7c;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-decoration: none;

        &:hover {
          color: #ffdb25;
        }

      }

    }

  }

  .btn_search {
    background: url(../img/blog/icon_search.png) 0 0 no-repeat;
    width: 40px;
    height: 40px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    cursor: pointer;
    float: right;

    &:hover {
      margin: 0;
      padding: 0;
    }
  }

}

/* separator */
.separator {
  padding-top: 86px;
}

.btn_llamar {
  display: none;
}

/* generic search */
/*-------------------------------------------------*/
.generic_search {
  padding: 35px;
  text-align: center;
  margin: 0;
  display: none;
  background: #ffffff;

  &.active {
    display: block;
  }

  .search-simple-box-container {

    .searchformsend {

      #senviar {
        font-size: 36px;
        line-height: 42px;
        color: #dee2e6;
        font-weight: 700;
        outline: 0;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        border: 0;
        background: url(../img/blog/icon_search_gris.png) 0 50% no-repeat;
        padding: 0 0 0 40px;
        max-width: 208px;
      }

    }

  }
}

/* GENERIC */
/*---------------------------------------------------------*/
.the_h1,
.generic_h1_page_result {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 0 15px;
}

.the_content {
  padding-right: 20px;
}

/* blog_single_area */
/*---------------------------------------------------------*/
.single_right {
  background-color: #ffffff;
}

.content-bg-area {
  background: #ffffff url(../img/blog/bg.png) 50% 0 repeat-y;
}

.content-bg-area-full {
  background: #f4f7f9;
}

.single_right .blog_area_text .the_date {
  margin: 0 14px 0 0 !important;
}

.comment_limit_info {
  color: #bfc2c5;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;

  span {
    background: transparent url(../img/icons/icon-edit.png) 0 0 no-repeat;
    padding-left: 24px;
  }
}

.blog_single_area {

  .the_large_descripcion {
    margin: 25px auto;
    display: block;
    width: 100%;
    overflow: hidden;
    padding-right: 30px;
  }

  .pagination-right {
    padding-right: 40px;
  }

  .blog_area_box .blog_area_text {

    .category_list {
      padding: 0;
      margin: 0;
      display: inline-block;
      vertical-align: top;
    }

    .the_date {
      font-size: 12px;
      color: #c3c5c8;
      font-weight: 400;
      padding: 0 0 0 24px;
      margin: 7px 14px 7px 14px;
      font-style: italic;
      line-height: 16px;
      vertical-align: top;
      background: url(../img/blog/icon_calendar.png) no-repeat;
      display: inline-block;
    }

    .navigation.post-navigation {
      padding-top: 1px;
      display: inline-block;
      vertical-align: top;
      width: auto;
    }

    .go_back {
      background: url(../img/blog/icon_flechas.png) 0 0 no-repeat transparent;
      display: inline-block;
      width: 25px;
      height: 25px;
      vertical-align: top;
      margin: 1px 5px;
      cursor: pointer;

      &.go_back_final {
        text-indent: -999999px;
        text-align: left;
      }

      &:hover {
        background-position: 0 -25px;
      }
    }

    .nav-links {
      display: inline-block;
      overflow: hidden;
      border: 0 none;
      padding: 0;
      margin: 0;
      float: none;
      vertical-align: top;
      min-height: 26px;

      .nav-previous {
        width: 25px;
        height: 25px;
        display: inline-block;
        overflow: hidden;
        border: 0 none;
        padding: 0;
        margin: 0 10px 0 0;
        float: none;
        vertical-align: top;

        a {
          background: url(../img/blog/icon_flechas.png) -33px 0 no-repeat transparent;
          padding: 0;
          margin: 0;
          display: block;
          overflow: hidden;
          text-indent: -99999px;
          border: 0 none;
          text-align: left;
          border-radius: 0;
          width: 25px;
          height: 25px;

          &:hover {
            text-decoration: none;
            background-position: -33px -25px;
          }
        }
      }

      .nav-next {
        width: 25px;
        height: 25px;
        display: inline-block;
        overflow: hidden;
        border: 0 none;
        padding: 0;
        margin: 0;
        float: none;
        vertical-align: top;

        a {
          background: url(../img/blog/icon_flechas.png) -66px 0 no-repeat transparent;
          padding: 0;
          margin: 0;
          display: block;
          overflow: hidden;
          text-indent: -99999px;
          border: 0 none;
          text-align: left;
          border-radius: 0;
          width: 25px;
          height: 25px;

          &:hover {
            text-decoration: none;
            background-position: -66px -25px;
          }
        }
      }

    }

  }

}

.single_left .blog_area_text {
  padding: 20px 0 5px !important;
}

.single_right .blog_area_text {
  padding: 20px 30px 60px !important;
}

/* comments-area */
/*---------------------------------------------------------*/
.comment_area_box {
  padding: 20px 30px 20px 0;
  margin: 0;
  display: block;
  width: 100%;
  overflow: hidden;

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #bfc2c5;
    font-style: italic;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #bfc2c5;
    font-style: italic;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #bfc2c5;
    font-style: italic;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #bfc2c5;
    font-style: italic;
  }

  .comments-area {

    h2 {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: #636465;
      text-transform: uppercase;
      margin: 0 0 10px;
      padding: 0;
    }

    .titulado {

      h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        color: #636465;
        text-transform: uppercase;
        margin: 0 0 5px;
        padding: 0;
      }

      h2 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #636465;
        text-transform: none;
        margin: 0 0 30px;
        padding: 0;
      }
    }

    //RESPUESTA
    .media-body {

      .card {
        border: 0 none;
        border-radius: 0;
      }

      .card-header {
        border-top: 0 none;
        text-align: left;
        padding: 10px 20px;
        background: none;
        border-bottom: 1px solid #eee;
      }

      .card-footer {
        border-top: 0 none;
        text-align: right;
        padding: 10px 20px;
        background: none;
      }

      h5 {
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        display: block;
        width: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0 0 8px 0;

        .fn {
          font-style: normal;
        }

      }

      .comment-meta {
        a {
          font-size: 12px;
          color: #c3c5c8;
          font-weight: 400;
          padding: 0 0 0 24px;
          margin: 2px 14px 2px 0;
          font-style: normal;
          line-height: 16px;
          background: url(../img/blog/icon_calendar.png) no-repeat;
          display: inline-block;
          vertical-align: top;

          &:hover {
            text-decoration: underline;
          }
        }

        .edit-link {
          display: inline-block;
          vertical-align: top;

          a {
            padding: 0;
            background: 0 0;
            text-transform: uppercase;
            font-style: normal;
            color: #000;
            font-weight: bold;
            letter-spacing: 0.1em;
          }

        }
      }

      .comment-content.card-block {
        color: #636465;
        font-size: 14px;
        line-height: 18px;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 10px 20px;

        p {
          color: #636465;
          font-size: 14px;
          line-height: 18px;
          display: block;
          overflow: hidden;
        }
      }

      .comment-reply-link.btn.btn-primary {
        background: #cdd0d3;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        font-style: normal;
        color: #ffffff !important;
        text-transform: uppercase;
        border-radius: 25px;
        border: 0 none;
        padding: 10px 20px;

        &:hover {
          color: #484848 !important;
        }
      }

    }

    //COMENTARIO
    .comment-form {
      padding: 30px 0 30px 2px;
      margin: 20px auto;
      border-top: 1px solid #dcdcdc;

      .comment-form-url {
        display: none;
      }

      .logged-in-as {

        a {
          padding: 0;
          background: 0 0;
          text-transform: uppercase;
          font-style: normal;
          color: #000;
          font-weight: 700;
          letter-spacing: .1em;
          font-size: 14px;
          line-height: 18px;

          &:hover {
            color: #484848;
            text-decoration: underline;
          }

        }

      }

      .comment-form-author {
        padding-right: 20px;
      }

      .comment-form-email,
      .comment-form-author {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        margin: -2px;

        input {
          background: #ffffff;
          border: 0 none;
          height: 40px;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #636465;
          margin-bottom: 20px;
          padding: 5px 18px;
        }
      }

      .field-hint {
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        margin-bottom: 15px;
        display: block;
        width: 100%;
      }

      textarea {
        background: #fff;
        border: 0;
        height: 240px;
        padding: 15px 18px;
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        resize: none;
        margin-bottom: 20px;
        border-radius: 0;
      }

    }

    .form-submit {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      text-align: right;

      .submit {
        background: #cdd0d3;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        font-style: normal;
        color: #ffffff;
        text-transform: uppercase;
        border-radius: 25px;
        border: 0 none;
        padding: 10px 20px;

        &:hover {
          color: #484848;
        }
      }

    }

    .comment-respond {
      #reply-title,
      .form-allowed-tags,
      .alert.alert-info {
        display: none;
      }

      #commentform {
        .comment-notes {
          display: none;
        }
      }

    }

  }

}

/* blog_area_box */
/*---------------------------------------------------------*/
.blog_area_box {

  .a_zone {
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }

  text-align: left;
  outline: none;
  text-decoration: none;

  .blog_area_img {
    padding: 0;
    position: relative;
    z-index: 1;

    .attachment-full {
      max-width: 100%;
      height: auto;
      width: 100%;
    }

    a {
      background: #cdd0d3 url(../img/loader.gif) 50% 50% no-repeat;
      text-decoration: none;
      display: block;
      overflow: hidden;

      &:hover {
        text-decoration: none;
      }
    }

  }

  .blog_area_text {
    padding: 20px 20px 60px 20px;
    display: block;
    overflow: hidden;
    width: 100%;
  }

  .category_list {
    padding: 0;
    margin: 0;
    display: block;

    a {
      background: #f7a400;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 5px 10px;
      color: #2b2e2f;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        background: #ffd200;
      }
    }
  }

  h1 {
    margin: 0;
    padding: 0;

    a {
      font-size: 30px;
      color: #2b2e2f;
      line-height: 34px;
      font-weight: 700;
      padding: 15px 0;
      margin: 0;
      display: block;
      text-decoration: none;

      &:hover {
        color: #ffd200;
        text-decoration: none;
      }
    }
  }

  .the_descripcion {
    font-size: 16px;
    color: #2b2e2f;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 15px;
    display: block;
    overflow: hidden;

    p {
      margin: 0 0 10px 0;
      padding: 0;
    }
  }

  .the_descripcion_limit {
    img {
      display: none;
    }
  }

  .the_date_comments {
    position: absolute;
    z-index: 987;
    bottom: 20px;
    left: 40px;
    width: 100%;
    overflow: hidden;
    display: block;
    text-align: left;

    .the_date {
      background: url(../img/blog/icon_calendar.png) no-repeat;
      font-size: 12px;
      color: #c3c5c8;
      font-weight: 400;
      padding: 0 0 0 24px;
      margin: 0 14px 0 0;
      font-style: italic;
      line-height: 16px;
      display: inline-block;
      vertical-align: top;
    }

    .the_comments {
      background: url(../img/blog/icon_comentar.png) no-repeat;
      font-size: 12px;
      color: #c3c5c8;
      font-weight: 400;
      padding: 0 0 0 24px;
      margin: 1px 14px 0 0;
      font-style: italic;
      line-height: 16px;
      display: inline-block;
      vertical-align: top;
    }

  }

  .img-fluid {
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  .btn_plus {
    background: url(../img/blog/icon_plus_blog.png) no-repeat;
    width: 44px;
    display: inline-block;
    vertical-align: top;
    outline: none;
    height: 44px;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0 0 10px 0;
    padding: 0;
    text-align: left;

    &:hover {
      background-position: 0 100%;
    }
  }

  &:hover {
    outline: none;
    text-decoration: none;
  }

}

/* navigation */
.wp-pagenavi {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 40px 0;

  .first {
    background: url(../img/blog/icon_flechas.png) 0 0 no-repeat;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px;

    &:hover {
      background-color: #fff;
      background-position: 0 -25px;
    }
  }

  .last {
    background: url(../img/blog/icon_flechas.png) -99px 0 no-repeat;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px;

    &:hover {
      background-color: #fff;
      background-position: -99px -25px;
    }
  }

  .previouspostslink {
    background: #ffffff url(../img/blog/icon_flechas.png) -33px 0 no-repeat;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px;

    &:hover {
      background-color: #fff;
      background-position: -33px -25px;
    }
  }

  .nextpostslink {
    background: url(../img/blog/icon_flechas.png) -66px 0 no-repeat #fff;
    width: 18px;
    height: 18px;
    text-indent: -999999px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0 3px;

    &:hover {
      background-color: #fff;
      background-position: -66px -25px;
    }

  }

}

.wp-pagenavi .pages {
  display: none;
}

.wp-pagenavi a, .wp-pagenavi span {
  text-decoration: none;
  border-radius: 100%;
  background: 0 none;
  border: 0;
  min-width: 27px;
  min-height: 25px;
  font-size: 16px;
  line-height: 20px;
  color: #b0b3b6;
  margin: 1px;
  padding: 2px 8px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
  background-color: #ffd200;
  color: #fff;
}

.wp-pagenavi span.current {
  background-color: #ffd200;
  color: #fff;
}

/* -webkit-input-placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity: 1;
}

::-moz-placeholder { /* Firefox 19+ */
  opacity: 1;
}

:-ms-input-placeholder { /* IE 10+ */
  opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
  opacity: 1;
}

/* no-results not-found */

.no-results.not-found {

  .search-form {
    text-align: center;

    label {
      width: auto;
      display: inline-block;
      vertical-align: top;
    }

  }

  .search-field.form-control {
    background: #fff;
    border: 0;
    padding: 0 18px;
    display: inline-block;
    vertical-align: top;
    width: auto;
    font-size: 14px;
    line-height: 18px;
    color: #636465;
    resize: none;
    margin-bottom: 20px;
    border-radius: 0;
    height: 44px;
  }

  .search-submit {
    background: url(../img/blog/icon_search_gris.png) 50% 50% no-repeat #eeeeff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0;
    border: 0;
    padding: 10px 20px;
    margin: 0 0 0 -4px;
    height: 44px;
    text-indent: -999999px;
    width: 50px;

    &:hover {
      background-color: #eeeeee;
    }
  }

}

/* lateral_barra_intro */
/*-----------------------------------------------------*/
.lateral_barra_intro {

  h1 {
    color: #2b2e2f;
    font-size: 22px;
    line-height: 28px;
    display: block;
    width: 100%;
    background: url(../img/contacto/icon_contact.png) 0 50% no-repeat transparent;
    text-align: left;
    overflow: hidden;
    margin: 20px 0 10px;
    padding: 0 0 0 42px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h2 {
    color: #2b2e2f;
    font-size: 14px;
    line-height: 20px;
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
    margin: 0 0 10px 0;
    padding: 0;
    text-transform: none;
    font-weight: 400;
  }

}

.border-separator {
  margin: 0 auto;
  border-top: 1px solid #cdd0d3;
  padding: 0;
  display: block;
  width: 100%;
}

/* box_contact_area */
/*----------------------------------------------------------*/

.form_contact_7 {

  h1 {
    font-size: 22px;
    line-height: 28px;
    width: 100%;
    background: none;
    padding: 0;
    text-transform: uppercase;
    font-weight: 700;
    color: #2b2e2f;
    text-align: left;
    overflow: hidden;
    margin: 0 0 10px;
    display: block;
  }

  h2 {
    color: #636465;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 10px;
    text-transform: none;
    font-weight: 400;
  }

}

.knewssubscription_hidden {
  display: none !important;
}

.box_contact_area {

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #bfc2c5;
    font-style: italic;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #bfc2c5;
    font-style: italic;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #bfc2c5;
    font-style: italic;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #bfc2c5;
    font-style: italic;
  }

  .single_left {

    .col-x-2 {
      padding-right: 20px !important;
      display: inline-block;
      vertical-align: top;
      max-width: 50%;
      width: 100%;
      margin: 0 -2px;
    }

    .col-x-1 {
      padding-right: 20px !important;
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
      width: 100%;
      margin: 0 -2px;
      position: relative;
      z-index: 1;
    }

    .wpcf7-form {

      .wpcf7-list-item-label-default {
        color: #babdc0;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        cursor: pointer;
        margin: 4px 0;
        display: inline-block;
        vertical-align: top;
        padding: 0 0 0 24px;
        position: relative;
        z-index: 987;

        a {
          text-decoration: none;
          color: #babdc0;
          font-size: 14px;
          line-height: 18px;
          font-style: italic;
          cursor: pointer;
          font-weight: 600;
          margin: 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      span.wpcf7-not-valid-tip {
        font-size: 12px;
        font-weight: normal;
        display: inline-block;
        vertical-align: top;
        line-height: 18px;
        font-style: italic;
        background: #fff;
        border: 1px solid red;
        padding: 2px 8px;
        overflow: visible;
        margin-top: 5px;
        position: relative;
        z-index: 1;
      }

      span.wpcf7-not-valid-tip:after {
        content: '';
        position: absolute;
        top: 0;
        left: 29px;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-bottom-color: red;
        border-top: 0;
        border-left: 0;
        margin-left: -2.5px;
        margin-top: -5px;
      }

      .wpcf7-form-control-wrap.your-consent {
        display: inline-block;
        vertical-align: top;
        position: absolute;
        z-index: 987;
        top: 0;
        left: 0;
        width: 100%;
        overflow: visible;

        .wpcf7-not-valid-tip {
          display: inline-block;
          vertical-align: top;
          position: absolute;
          z-index: 987;
          top: -24px;
          left: 0;
          width: auto;
          background: #ffffff;
          border: 1px solid red;
          padding: 2px 8px;
          overflow: visible;
          font-style: italic;
          margin-top: 0;
        }

        .wpcf7-not-valid-tip:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 9px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top-color: red;
          border-bottom: 0;
          border-left: 0;
          margin-left: -2.5px;
          margin-bottom: -5px;
          margin-top: 23px;
        }

      }

      div.wpcf7-response-output {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding-top: 5px;
        font-style: italic;
        margin-right: 25px;
      }

      .both_check {
        display: inline-block;
        vertical-align: top;
        max-width: 75%;
        padding: 0;
        width: 100%;

        .wpcf7-form-control-wrap {
          padding: 0;
          margin: 0;
        }

      }

      .both_btn {
        display: inline-block;
        vertical-align: top;
        max-width: 25%;
        width: 100%;
      }

      .dib {
        margin: 0 -2px;
      }

      .aux_input {
        display: none;
      }

      .wpcf7-form-control-wrap {
        position: relative;
        margin-bottom: 20px;
        display: block;
        overflow: hidden;
        width: 100%;

        &.knewssubscription-911 {
          display: block;
          width: 100%;
          padding: 0;
          color: #495057;
          background-clip: padding-box;
          border: 0 none;
          border-radius: 0;
          overflow: hidden;
          cursor: pointer;
          position: relative;
          z-index: 1;
          background: url(../img/contacto/ico_checkbox.png) 0 2px no-repeat transparent;

          &.clicked {
            background: url(../img/contacto/ico_checkbox_active.png) 0 2px no-repeat transparent;
          }

          input {
            width: auto;
            height: auto;
            padding: 0;
            margin: 0 6px 0 0;
            visibility: hidden;
            display: inline-block;
            vertical-align: top;
          }

          label {
            color: #babdc0;
            font-size: 14px;
            line-height: 18px;
            font-style: italic;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            margin: 0;
            position: absolute;
            left: 0;
            top: 0;
            padding-left: 22px;
            z-index: 9;
          }

        }

        .wpcf7-acceptance,
        .wpcf7-checkbox {
          display: block;
          width: 100%;
          padding: 0;
          color: #495057;
          background-clip: padding-box;
          border: 0 none;
          border-radius: 0;
          background: none;
          overflow: hidden;
          cursor: pointer;

          label {
            cursor: pointer;
          }

          .wpcf7-list-item-label {
            color: #babdc0;
            font-size: 14px;
            line-height: 18px;
            font-style: italic;
            cursor: pointer;
            margin: 0;
          }

          input {
            width: auto;
            height: auto;
            padding: 0;
            margin: 0 6px 0 0;
            visibility: hidden;
          }

          span.wpcf7-list-item {
            display: inline-block;
            margin: 0;
            background: url(../img/contacto/ico_checkbox.png) 0 6px no-repeat transparent;

            &.clicked {
              background: url(../img/contacto/ico_checkbox_active.png) 0 6px no-repeat transparent;
            }

          }

        }

      }

      input {
        background: #fff;
        border: 0;
        height: 40px;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        margin-bottom: 0;
        padding: 5px 18px;
        border-radius: 0;
      }

      textarea {
        background: #fff;
        border: 0;
        height: 240px;
        padding: 15px 18px;
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        resize: none;
        margin-bottom: 0;
        border-radius: 0;
      }

      .wpcf7-submit {
        background: #ffd200;
        font-weight: bold;
        line-height: 24px;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        border-radius: 25px;
        text-transform: uppercase;
        width: auto;

        &:hover {
          text-decoration: none;
          color: #000000;
        }

      }

    }

  }

}

/* lateral_barra_mapa */
/*--------------------------------------------*/
.lateral_barra_mapa {

  .lateral_barra_mapa_img {

    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    overflow: hidden;

    p {
      padding: 0;
      margin: 0;
    }

  }

  .lateral_barra_mapa_btn {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
    text-align: right;
  }

  .btn_ver_mapa {
    background: #cdd0d3;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    text-transform: uppercase;
    border-radius: 25px;
    border: 0;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;

    &:hover {
      color: #484848;
    }
  }

}

/* lateral_barra_data */
/*--------------------------------------------*/
.lateral_barra_data {

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      background: none;
      color: #2b2e2f;
      font-weight: 600;
      padding-left: 34px;
      list-style: none;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      font-size: 16px;
      line-height: 24px;

      a {
        color: #2b2e2f;
        font-weight: 600;
        padding: 0;
        margin: 0;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          color: #2b2e2f;
          text-decoration: underline;
        }
      }

      p {
        margin: 0;
        padding: 0;
      }

      &.ico_email {
        background: url(../img/contacto/icon_email.png) 0 50% no-repeat transparent;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 18px;
        word-wrap: break-word;
      }

      &.ico_tel {
        background: url(../img/contacto/icon_phone.png) 0 50% no-repeat transparent;
        margin-bottom: 5px;
      }

      &.ico_telfax {
        background: url(../img/contacto/icon_telfax.png) 0 50% no-repeat transparent;
        margin-bottom: 18px;
      }

      &.ico_dire {
        background: url(../img/contacto/icon_map.png) 0 2px no-repeat transparent;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
      }

    }

  }
}

/* sidebar */
/*-------------------------------------------------------*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 987654;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-x: hidden;
  -webkit-transition: .5s;
  transition: .5s;
  padding-top: 86px;

  .footer_social_red {
    color: #636465;
    background: none;
    padding: 0;
    margin: 28px;
  }

  .closebtn {
    text-decoration: none;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: normal;
    padding: 44px 8px 8px 32px;
    color: #636465;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 36px;
    margin-left: 0;

    &:hover {
      text-decoration: none;
      color: #ffdb25;
    }
  }

  #menu-nav-primary .menu-item-316,
  #menu-menu-principal .menu-item-45 {
    display: block;
  }

  #menu-nav-primary,
  #menu-menu-principal {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;
        font-weight: normal;
        padding: 8px 8px 8px 32px;
        color: #636465;
        display: block;

        &:hover {
          text-decoration: none;
          color: #ffdb25;
        }

      }
    }

  }

}

/* CONGRESOS */
/*--------------------------------------------*/
.congreso_page_box.viaje_page_box {

  .congreso_page_box_hero {
    background-position: 50% 0;
    background-size: cover;

    .the_slide_content h1 p strong {
      color: #2b2e2f;
    }

    .the_slide_content h1 p {
      color: #2b2e2f;
    }

  }

  /* escapate_box */
  .solutions_list_item_escapate {

    &.solutions_list_item {
      background: #2b2e2f;
    }

    &.solutions_list_item h1 {
      font-size: 24px;
      line-height: 28px;
      color: #ffd200;
      font-weight: 700;
    }

    &.solutions_list_item h2 p {
      color: #dcdcdc;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    &.solutions_list_item h3 {
      font-size: 18px;
      line-height: 22px;
      color: #95989b;
      margin: 0 0 5px;
    }

    &.solutions_list_item h4 p {
      color: #95989b;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
    }

    &.solutions_list_item .btn_comun {
      background: transparent;

      &:hover {
        background: #dcdcdc;
      }
    }

  }

  .escapate_beneficios {
    background: #dcdcdc;

    .escapate_beneficios_box {
      padding: 30px 15px;

      ul {
        list-style: none;
        margin: 0 0 40px 0;
        padding: 0;

        li {
          list-style: none;
          margin: 0;
          padding: 0;

          .imagen_esc_boxes {
            margin: 0;
            padding: 0;
            min-height: 180px;
            max-height: 180px;

          }

          h1 {
            font-weight: 600;
            color: #2b2e2f;
            font-size: 15px;
            line-height: 24px;
            margin: 0;
            padding: 0 18px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }

    .btn_escape {
      border: 0;
      height: 44px;
      margin-bottom: 0;
      padding: 0 28px;
      font-size: 14px;
      line-height: 44px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 25px;
      display: inline-block;
      vertical-align: top;
      background: #ffd200;
      width: auto;
      color: #2b2e2f;
      text-decoration: none;
      outline: none;

      &:hover {
        color: #636465;
        text-decoration: none;
      }
    }
  }

  .escapate_cover {
    min-height: 400px;
    background-position: 50% 50%;
    background-size: cover;
  }

  .escapate_box {
    position: relative;
    min-height: 400px;
    z-index: 1;
    width: 100%;
    overflow: hidden;

    .escapate_box_text {
      width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 987;
      top: 40px;
      left: 30px;
      max-width: 80%;
    }

    .escapate_box_img {
      visibility: hidden;
    }

  }

  .tabulador .nav.nav-tabs .nav-item {
    background: #ffc600;
    border-left: 1px solid #ffd200;

    &.nav-item-latitud {

      a.nav-link {
        background: url(../img/viajes/img_latitud.png) 50% 50% no-repeat;
        text-indent: -999999px;

        &:hover {
          background-color: #2b2e2f;
        }

      }

    }

  }

  .tabulador .nav.nav-tabs .nav-item a {
    background: #ffc600;
    color: #c39006;
  }

  .tabulador .nav.nav-tabs .nav-item a.nav-link.active,
  .tabulador .nav.nav-tabs .nav-item a:hover {
    background-color: #2b2e2f;
    color: #ffd200;
  }

}

.congreso_page_box {

  .congreso_page_box_hero {
    max-height: 700px;
    display: block;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;

    .jumbotron,
    .mx-auto {
      max-height: 700px;
    }

    .the_slide_content_trabajo {
      h1 {
        p {
          text-align: center !important;
        }
      }
    }

    .the_slide_content {
      max-width: 100%;
      margin: 0 auto;
      padding: 0;

      .jumbotron {
        padding: 0;
        background: none;
        margin: 0;
        height: 100vh;
        border-radius: 0;
        text-align: center;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      h1 {
        color: #fff;
        font-weight: bold;
        font-size: 44px;
        line-height: 48px;
        text-transform: none;
        padding: 0 0 0 170px;
        text-align: left;
        margin: 0;

        p {
          color: #fff;
          font-weight: 400;
          font-size: 26px;
          line-height: 30px;
          text-transform: none;
          padding: 0;
          margin: 0;
          text-align: left;

          strong {
            color: #fff;
            font-weight: bold;
            font-size: 44px;
            line-height: 48px;
            text-transform: none;
            padding: 0;
            margin: 0;
            text-align: left;
          }
        }

      }

    }

  }

  .tabulador {

    .nav-tabs-title {
      font-size: 24px;
      line-height: 36px;
      padding: 12px 0;
      color: #ffd200;
      text-decoration: none;
      text-transform: uppercase;
      margin: 0;
      font-weight: 700;
      display: inline-block;
      vertical-align: top;
    }

    .nav.nav-tabs {
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      border: 0;
      width: 100%;

      .nav-item {
        margin: 0 -2px;
        background: #444647;
        border-radius: 0;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        max-width: 32%;
        text-align: center;
        border-left: 1px solid #2b2e2f;

        &.first_item {
          border-left: 0;
          margin-right: -3px;
        }

        a {
          color: #95989b;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          border-radius: 0;
          background: #444647;
          border: 0;
          margin: 0;
          padding: 10px 0;

          &.nav-link.active {
            background-color: #ffd200;
            color: #2b2e2f;
          }

          &:hover {
            background-color: #ffd200;
            color: #2b2e2f;
          }
        }

      }

    }

    .tabulador_box {
      background: url(../img/congresos/bg_congresos.png) #000;
    }

    .solutions_list_item_dinamic {
      min-height: 400px;
      position: relative;
      z-index: 1;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      &:hover {
        h6 {
          display: none;
        }

        .alternative_data_hover {
          display: block;
        }
      }

      .solutions_list_padding {
        padding: 30px;
      }

      h6 {
        padding: 30px;
        font-size: 36px;
        line-height: 48px;
        color: #999999;
        font-weight: bold;
        margin: 0 0 10px;
        opacity: 0.75;
      }

      .alternative_data_hover {
        min-height: 400px;
        display: none;
        -webkit-transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out;
        position: relative;
        z-index: 98;
        text-align: left;
        width: 100%;
        height: 100%;
        background: rgba(43, 46, 47, 0.85);
        cursor: pointer;

        h1 {
          font-size: 22px;
          line-height: 26px;
          font-weight: 700;
          margin: 0 0 10px;
          padding: 0;
          color: #ffd200;
        }

        .the_desc {
          margin: 0;
          padding: 0;

          p {
            color: #dcdcdc;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin: 0;
            padding: 0;
          }
        }

        h5 {
          font-size: 36px;
          line-height: 48px;
          color: #f4f7f9;
          font-weight: bold;
          margin: 0 0 10px;
          padding: 0;
          opacity: 1;
        }

      }

    }

    .solutions_list_item {
      min-height: 400px;
      background: #dcdcdc;

      .btn_comun {
        background: #dcdcdc;
        border: 2px solid #95989b;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        font-style: normal;
        color: #95989b !important;
        text-transform: uppercase;
        border-radius: 25px;
        padding: 10px 20px;
        display: inline-block;
        vertical-align: top;

        &:hover {
          background: #cdd0d3;
          text-decoration: none;
        }
      }

      h1 {
        font-size: 22px;
        line-height: 28px;
        color: #2b2e2f;
        font-weight: 700;
        margin: 0 0 10px;
        padding: 0;
      }

      h2 {
        margin: 0;
        padding: 0;

        p {
          color: #636465;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
        }
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
        color: #95989b;
        font-weight: 700;
        margin: 0 0 10px;
        padding: 0;
      }

      h4 {
        margin: 0 0 20px;
        padding: 0;

        p {
          color: #636465;
          font-size: 13px;
          line-height: 21px;
          margin: 0;
          padding: 0;
          font-weight: 400;
        }

      }

      .solutions_list_padding {
        padding: 30px;
      }

    }

  }

}

/* eventos soluciones */
.congreso_page_box.evento_page_box {

  .tabulador_box {

    background: url(../img/eventos/bg_eventos.png) #cc0935;

  }

  .tabulador .nav-tabs-title {
    color: #ffffff;
  }

}

/* viajes soluciones */
.congreso_page_box.viaje_page_box {

  .tabulador_box {

    background: url(../img/viajes/bg_viajes.png) #cc0935;

  }

  .tabulador .nav-tabs-title {
    color: #2b2e2f;
  }

}

/* empalmer_box */
.empalmer_box {
  padding: 66px 0;
  position: relative;
  z-index: 1;

  .btn_grey_up {
    background: url(../img/congresos/btn_up.png) transparent;
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: block;
    position: absolute;
    z-index: 987;
    text-align: left;
    top: 30px;
    right: 0;
    cursor: pointer;
  }

}

.sub-container {
  width: 100%;
  max-width: 1210px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* especialidades_box */
/*------------------------------------------------------*/
.especialidades_box {
  background: #2b2e2f;

  .especialidades_box_area {
    padding: 30px;
  }

  h1 {
    font-size: 22px;
    line-height: 28px;
    color: #ffd200;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
  }

  .the_desc_esp {

    p {
      color: #95989b;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }

  }

  .listado_especialidades {

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        padding: 0;
        margin: 0;

        a {
          display: block;
          overflow: hidden;
          min-height: 200px;
          width: 100%;
          margin: 0;
          text-decoration: none;
          padding: 0;
          background-position: 50% 50%;
          text-align: center;
          cursor: pointer;
          /*background-size: cover;*/

          .banner {
            background: rgba(43, 46, 47, .75);
            min-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(244, 247, 249, .40);
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            overflow: hidden;
            padding-left: 5px;
            padding-right: 5px;
            hyphens: auto;

            &:hover {
              background: url(../img/congresos/opacity-yellow.png) transparent;
              color: #2b2e2f;
            }

          }

        }

      }

    }

  }

}

.search_box_area_content_left {
  background: #ffffff;

  .search_box_area_content_left_padding {
    padding: 30px;
  }

  h1 {
    font-size: 22px;
    line-height: 28px;
    color: #2b2e2f;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
  }

  .the_desc {
    p {
      color: #636465;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    color: #95989b;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
  }

  .the_desc_extra {
    p {
      color: #636465;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
      padding: 0;

      a {
        color: #95989b;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-decoration: none;
        word-wrap: break-word;

        &:hover {
          color: #95989b;
          text-decoration: underline;
        }

      }

      strong {
        font-size: 16px;
        line-height: 22px;
        color: #95989b;
        font-weight: 700;
        background: url(../img/congresos/icon_phone.png) 0 50% no-repeat;
        padding: 0 20px;
        margin: 0 5px;
      }

    }

  }

}

/* */
/*-----------------------------------------------*/

.search_form_by_especiality {

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #bfc2c5;
    font-style: italic;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #bfc2c5;
    font-style: italic;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #bfc2c5;
    font-style: italic;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #bfc2c5;
    font-style: italic;
  }

  .search_form_by_especiality_content {
    padding: 30px;

    label {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #95989b;
      padding: 0;
      margin-bottom: 10px;
    }

    input {
      background: #fff;
      border: 0;
      height: 40px;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      color: #636465;
      margin: 0 0 20px 0;
      padding: 5px 18px;
      border-radius: 0;
    }

    .search-submit {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 25px;
      display: inline-block;
      vertical-align: top;
      background: #ffd200;
      width: auto;
      padding: 10px 30px;
      min-height: 44px;
      margin: 15px 0;
      text-decoration: none;

      &.go_back_2 {
        color: #95989b;
        text-decoration: none;
        background: url(../img/congresos/icon_arrow.png) 20px 50% no-repeat #dcdcdc;
        padding: 10px 30px 10px 40px;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: none;
        color: #000;
      }
    }

    .select_a_content_box {
      background: url(../img/congresos/icon_select.png) 97% 50% no-repeat #fff;
      min-height: 40px;
      display: block;
      overflow: hidden;
      width: 100%;
      margin-bottom: 20px;

      select {
        width: 110%;
        height: auto;
        border: 0;
        background: 0 0;
        display: block;
        min-height: 40px;
        color: #636465;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        padding: 10px;
      }
    }

  }

}

/* result_box_specialty */
/*-----------------------------------------------------*/
.result_box_specialty {

  .box_services_area_extra {
    display: none;
  }

  .box_resultado_area.active {
    .box_services_area_extra {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .btn_plus {
    background: url(../img/blog/icon_plus_blog.png) no-repeat;
    width: 44px;
    display: inline-block;
    vertical-align: top;
    outline: none;
    height: 44px;
    text-indent: -99999px;
    overflow: hidden;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: left;
    cursor: pointer;
    float: right;

    &:hover {
      background-position: 0 100%;
    }
  }

  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance .wpcf7-list-item-label,
  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox .wpcf7-list-item-label {
    color: #bfc2c5;
    font-size: 16px;
    line-height: 18px;
    font-style: normal;
    cursor: pointer;
    margin: 0;
    font-weight: 600;
  }

  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance input,
  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox input {
    margin: 0;
  }

  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item,
  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item {
    display: inline-block;
    background: #f4f7f9;
    padding: 10px 15px 10px 0;
    margin: 0 15px 0 0;
  }

  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-acceptance span.wpcf7-list-item.clicked,
  .box_contact_area .single_left .wpcf7-form .new-checkboxes-format .wpcf7-form-control-wrap .wpcf7-checkbox span.wpcf7-list-item.clicked {
    background: #ffd200;

    .wpcf7-list-item-label {
      color: #484848;
    }
  }

  .box_contact_area {

    .single_left {
      padding: 0 0 25px 0;
      background: #ffffff;
      border-bottom: 1px solid #f4f7f9;

      h1 {
        font-size: 20px;
        line-height: 24px;
        color: #636465;
        font-weight: bold;
        padding: 0 0 10px 0;
        margin: 0;
      }

      h2 {
        font-size: 14px;
        line-height: 18px;
        color: #636465;
        font-weight: normal;
        padding: 0 0 20px 0;
        margin: 0;
      }

      .border_line_separator {
        background: #fff;
        border-bottom: 1px solid #f4f7f9;
        width: 100%;
        height: 1px;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }

      .icon_close_x {
        background: url(../img/blog/icon_close.png) no-repeat;
        width: 20px;
        display: inline-block;
        vertical-align: top;
        outline: none;
        height: 20px;
        text-indent: -99999px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        text-align: left;
        cursor: pointer;
        position: absolute;
        z-index: 9;
        top: 30px;
        right: 30px;
      }

      .c_caja_30 {
        padding: 30px 30px 10px 30px;
      }

      .wpcf7-form {

        .both_check {
          max-width: 100%;
        }

        .both_btn {
          max-width: 100%;
          margin-top: 20px;
        }

        input {
          background: #f4f7f9;
          border: 0;
          height: 40px;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #636465;
          margin-bottom: 0;
          padding: 5px 18px;
          border-radius: 0;
        }

        textarea {
          background: #f4f7f9;
          border: 0;
          height: 240px;
          padding: 15px 18px;
          display: block;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #636465;
          resize: none;
          margin-bottom: 0;
          border-radius: 0;
        }

        .wpcf7-submit {
          background: #ffd200;
          font-weight: bold;
          line-height: 24px;
          font-size: 14px;
          display: inline-block;
          vertical-align: top;
          border-radius: 25px;
          text-transform: uppercase;
          width: auto;

          &:hover {
            text-decoration: none;
            color: #000000;
          }

        }

      }

    }

  }

  .result_box_specialty_text {
    background: #2b2e2f;
    padding: 30px;
    height: 100%;

    h1 {
      color: #ffd200;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 0 0 10px 0;
    }

    h2 {
      color: #dcdcdc;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 0 0 10px 0;
    }

  }

  .result_box_specialty_image {
    background: #2b2e2f;
    padding: 0;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .result_box_specialty_data {
    padding: 30px;
    height: 100%;
    background: #ffffff;
    border-bottom: 1px solid #f4f7f9;

    h1 {
      color: #2b2e2f;
      font-weight: 700;
      padding: 0;
      text-transform: none;
      font-size: 28px;
      line-height: 34px;
      text-decoration: none;
      margin: 0 0 20px 0;
    }

    h2 {
      color: #2b2e2f;
      font-weight: 600;
      padding: 0;
      text-transform: none;
      font-size: 18px;
      line-height: 24px;
      text-decoration: none;
      margin: 0 0 15px 0;

      span {
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
      }

      a {
        color: #f7a400;
        font-weight: 600;
        text-decoration: none;
        font-size: 18px;
        line-height: 24px;

        &:hover {
          color: #f7a400;
          text-decoration: underline;
        }
      }
    }

  }

}

.error_404 {
  font-size: 220px;
  line-height: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  padding: 50px 0;
  letter-spacing: 15px;
  color: #999999;
}

.square_box {
  background: #ffd200;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
  color: #2b2e2f;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
}

/* aviso-title
-------------------------------------------------------------------------------*/
.titulares_barras h1.aviso-title {
  background: url(../img/icons/icon_hand.png) 10px 50% no-repeat;
}

/* bolsa_de_trabajo_page_box
-------------------------------------------------------------------------------*/
.congreso_page_box.bolsa_de_trabajo_page_box {

  background: url(../img/blog/bg.png) 50% 0 repeat-y #fff;

  .tabulador {

    .nav-tabs-title {
      color: #ffffff;
    }

  }

  .trabajo_bolsa_box_image {
    background: 50% 50% no-repeat #2b2e2f;
    background-image: none;
    padding: 0;
    height: auto;
    background-size: cover;
  }

  .bolsa_de_trabajo_page_box_content {

    .bg-white {
      background: #ffffff;
    }

    .border-bot-2 {
      border-bottom: 1px solid #f4f7f9;
    }

    .trabajo_estado_box {
      background: #f7a400;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 5px 10px;
      color: #2b2e2f;
      text-decoration: none;
      display: inline-block;
      vertical-align: top;
    }

    h1 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
      color: #2b2e2f;
    }

    p {
      font-size: 14px;
      color: #2a2d2e;
      line-height: 18px;
      font-weight: 400;
      display: inline-block;
      vertical-align: top;

      strong {
        font-weight: 700;
        text-align: left;
        display: inline-block;
        vertical-align: top;
      }

    }

    .data-desc {
      padding-top: 30px;
      padding-bottom: 0;
      padding-right: 30px;
      padding-left: 0;

      p {
        color: #636465;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-align: left;

        a {
          text-decoration: none;
          word-break: break-all;

          strong {
            text-decoration: none;
            word-break: break-all;
          }

          &:hover {
            text-decoration: underline;

            strong {
              text-decoration: underline;
            }

          }

        }

        strong {
          color: #636465;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          text-align: left;
        }

      }

    }

    .data-phone {
      padding-top: 0;
      padding-bottom: 30px;
      padding-right: 30px;
      padding-left: 0;

      p {
        color: #636465;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        padding: 0;
        margin: 0 0 5px 0;
        width: 100%;

        strong {
          background: url(../img/icons/icons_phone_2.png) 0 0 no-repeat;
          padding: 0 0 0 30px;
          color: #636465;
          font-size: 18px;
          line-height: 24px;
          font-weight: 700;
          text-align: left;
          display: inline-block;
          vertical-align: top;
        }

      }

    }

  }

}

/* ACERCA DE */
/*------------------------------------------------------------*/
.congreso_page_box.acerca_de_page_box .congreso_page_box_hero .the_slide_content h1 p,
.congreso_page_box.acerca_de_page_box .congreso_page_box_hero .the_slide_content h1 p strong {
  color: #2b2e2f;
}

.congreso_page_box.acerca_de_page_box .tabulador_box {
  background: url(../img/eventos/bg_eventos.png) #cc0935;
}

.congreso_page_box.acerca_de_page_box .tabulador .nav-tabs-title {
  color: #fff;
}

.congreso_page_box.acerca_de_page_box .tabulador .solutions_list_item_dinamic .alternative_data_hover {
  background: rgba(255, 210, 47, .85);
}

.acerca_de_page_box {
  background: #ffffff;

  .acercade_titles {
    font-size: 24px;
    line-height: 34px;
    color: #2b2e2f;
    display: block;
    width: 100%;
    overflow: hidden;
    text-align: center;
    padding: 0 30px;
    margin: 40px 0;
  }

  .our_philosophy_box {
    background: #e1e6e9;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .brands_box {

    .jumbotron {
      padding: 0;
      background: none;
    }

    .logo_acercade_box {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      justify-content: center;
    }

    .brands_box_items {
      width: 20%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 20px;
    }

  }

  .our_philosophy {
    background: #e1e6e9;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      list-style: none;
      margin: 0;
      text-align: center;
      padding: 0 35px;
    }

    h1 {
      font-size: 24px;
      line-height: 34px;
      font-weight: bold;
      color: #2b2e2f;
      text-transform: none;
      margin: 0 0 15px 0;
      padding: 0;
    }

    h2 {
      font-size: 18px;
      color: #2b2e2f;
      line-height: 24px;
      font-weight: 300;

      p {
        font-size: 18px;
        color: #2b2e2f;
        line-height: 24px;
        font-weight: 300;
      }
    }

    .img_rounded {
      border-radius: 100%;
      max-width: 330px;
      margin: 0 auto 35px;
      max-height: 330px;
      overflow: hidden;
      display: block;
    }

  }

  .the_desc_extra {
    p {
      color: #636465;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
      padding: 0;

      a {
        color: #95989b;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-decoration: none;
        word-wrap: break-word;

        &:hover {
          color: #95989b;
          text-decoration: underline;
        }

      }

      strong {
        font-size: 16px;
        line-height: 22px;
        color: #95989b;
        font-weight: 700;
        background: url(../img/congresos/icon_phone.png) 0 50% no-repeat;
        padding: 0 20px;
        margin: 0 5px;
      }

    }
  }

  .alternative_data_hover {

    .solutions_list_padding {

      &.acercade_numeros_box {
        text-align: center;
        min-height: 400px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .the_desc {

          h1 {
            color: #2b2e2f;
            font-size: 36px;
            font-weight: 400;
            line-height: 40px;
            margin: 0;
            text-transform: uppercase;
            padding: 0;
            letter-spacing: -0.05em;

            strong {
              font-weight: bold;
              font-size: 70px;
              line-height: 70px;
              color: #2b2e2f;
            }

            span {
              color: #2b2e2f;
              font-size: 30px;
              font-weight: 400;
              line-height: 40px;
            }

          }

          h2 {
            color: #2b2e2f;
            font-size: 28px;
            font-weight: 400;
            line-height: 34px;
            margin: 0;
            text-transform: uppercase;
            padding: 0;
            letter-spacing: -0.05em;

            strong {
              font-weight: bold;
              font-size: 100px;
              line-height: 110px;
              color: #2b2e2f;
            }

          }

          h3 {
            color: #2b2e2f;
            font-size: 26px;
            font-weight: 400;
            line-height: 34px;
            margin: 0;
            text-transform: uppercase;
            padding: 0;

            strong {
              font-weight: bold;
              font-size: 70px;
              line-height: 70px;
              color: #2b2e2f;
            }

            em {
              color: #2b2e2f;
              font-size: 20px;
              font-style: normal;
              line-height: 20px;
              letter-spacing: -0.01em;
              font-weight: 300;
            }

          }

          h4 {
            font-size: 22px;
            line-height: 28px;
            margin: 0;
            text-transform: uppercase;
            padding: 0;
            font-weight: 300;
            color: #2b2e2f;

            strong {
              font-weight: bold;
              color: #2b2e2f;
              font-size: 120px;
              line-height: 100px;
              margin: 0;
              padding: 0;
            }

          }

          h5 {
            color: #2b2e2f;
            font-size: 22px;
            font-weight: 300;
            line-height: 24px;
            margin: 0;
            text-transform: uppercase;
            padding: 0;

            strong {
              font-weight: 600;
              font-size: 80px;
              line-height: 90px;
              color: #2b2e2f;
            }

            em {
              color: #2b2e2f;
              font-size: 22px;
              font-weight: 600;
              line-height: 24px;
              font-style: normal;
            }

          }

          h6 {
            font-size: 19px;
            line-height: 28px;
            margin: 0;
            text-transform: uppercase;
            padding: 0;
            font-weight: 300;
            opacity: 1;
            color: #2b2e2f;
            display: block !important;

            strong {
              font-weight: bold;
              color: #2b2e2f;
              font-size: 70px;
              line-height: 70px;
              margin: 0;
              padding: 0;
            }

          }

        }

        .jumbotron {
          padding: 0;
          background: none;
          margin: 0;
          border-radius: 0;
          text-align: center;

          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          img {
            margin-bottom: 30px;
          }
        }

      }

    }

  }

}

/* nav-links */
.nav-links {

  .nav-next,
  .nav-previous {

    a {
      width: 100%;
      display: block;
      overflow: hidden;
      margin: 25px auto;
      color: #ffd200;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

    }

  }

}

